import * as React from 'react';
import { Box, Chip, Tooltip, IconButton, Paper, Stack } from '@mui/material';
import {
	Edit as EditIcon,
	Email as EmailIcon,
	Delete as DeleteIcon,
	SaveAlt as SaveAltIcon,
	Done as DoneIcon,
	Close as CloseIcon,
	MoreHoriz as DotsIcon,
} from '@mui/icons-material';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { EMailConfigurationVerificationStatus, ReadSMTPMailConfigurationResponse } from '../../api/Api';
import { smtpConfigurationSchema } from './schema';
import { Link } from '../../components/Link/Link';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useTableQuery } from '../../hooks/useTableQuery';
import { useACL } from '../../hooks/useACL';
import { EPermission } from '../../enums/permission/EPermission';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import CheckIcon from '@mui/icons-material/Check';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

export enum EConfirmDialogState {
	DELETE_SINGLE = 'deleteSingle',
	DELETE_MULTIPLE = 'deleteMultiple',
	RESET = 'reset',
	SET_ACTIVE = 'setActive',
}

export const SmtpSettingsPage: React.FC = () => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const formatDate = useFormatDate();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [configIDToDelete, setConfigIDToDelete] = React.useState<number | null>(null);
	const [configIDToActivate, setConfigIDToActivate] = React.useState<number | null>(null);
	const [multiConfigIDToDelete, setMultiConfigIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');
	const [isRefreshNeeded, setIsRefreshNeeded] = React.useState(false);

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		swaggerQuery,
	} = useTableQuery(['name', 'sender', 'address', 'createdAt', 'verificationStatus']);

	const { data, isRefetching, isLoading, error } = useQuery<ReadSMTPMailConfigurationResponse[]>({
		queryKey: [EQueryKey.SMTP_CONFIG_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					filterList: swaggerQuery.filter,
					sortList: swaggerQuery.sort,
					columnsList: swaggerQuery.columns,
					offset: 0,
					limit: 100, // NOTE: as there is no pagination we will fetch 100 records
				};

				const response = await api.settings.readSmtpConfigurations(query);
				response.data.forEach((smtp) => {
					smtpConfigurationSchema.parse(smtp);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return [];
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.smtp.list.confirmation.delete.title'));
				setConfirmationText(t('page.smtp.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.smtp.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.smtp.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;

			case EConfirmDialogState.SET_ACTIVE:
				setConfirmationTitle(t('page.smtp.list.confirmation.activate.title'));
				setConfirmationText(t('page.smtp.list.confirmation.activate.text'));

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenConfigDeleteDialog = React.useCallback(
		(configID: number) =>
			(event: React.MouseEvent): void => {
				event.stopPropagation();
				setOpen(true);
				setConfigIDToDelete(configID);
				handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
			},
		[handleOnChangeConfirmDialog, isAllowed],
	);

	const handleOnOpenActivateDialog = React.useCallback(
		(configID: number) =>
			(event: React.MouseEvent): void => {
				event.stopPropagation();
				setOpen(true);
				setConfigIDToActivate(configID);
				handleOnChangeConfirmDialog(EConfirmDialogState.SET_ACTIVE);
			},
		[handleOnChangeConfirmDialog, isAllowed],
	);

	const handleOnOpenMultipleConfigsDelete = React.useCallback(
		(table: MRTTableInstance<Partial<ReadSMTPMailConfigurationResponse>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.smtp.list.errorMessages.noConfigSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiConfigIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed, enqueueSnackbar, t, handleOnChangeConfirmDialog],
	);

	const handleOnConfirmConfigDelete = React.useCallback(async (): Promise<void> => {
		if (configIDToDelete) {
			try {
				await api.settings.deleteMailConfiguration(configIDToDelete);
				enqueueSnackbar(t('page.smtp.list.actionMessages.SMTPSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				reactQueryClient.invalidateQueries();
				setOpen(false);
				setConfigIDToDelete(null);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiConfigIDToDelete.ids.length > 0) {
			try {
				await api.settings.deleteMailConfigurations({ ids: multiConfigIDToDelete.ids });
				enqueueSnackbar(t('page.smtp.list.actionMessages.multipleSMTPSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiConfigIDToDelete({ ids: [] });
				updateRowSelection(multiConfigIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [isAllowed, configIDToDelete, multiConfigIDToDelete, reactQueryClient, api.settings, enqueueSnackbar, t]);

	const handleOnConfirmConfigActive = React.useCallback(async (): Promise<void> => {
		try {
			if (configIDToActivate) {
				await api.settings.updateSmtpConfiguration(configIDToActivate, { active: true });
				enqueueSnackbar(t('page.smtp.list.actionMessages.SMTPSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				reactQueryClient.invalidateQueries();
				setOpen(false);
				setConfigIDToActivate(null);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			}
		} catch (error) {
			console.error(error);
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [isAllowed, configIDToDelete, multiConfigIDToDelete, reactQueryClient, api.settings, enqueueSnackbar, t]);

	// Set initial column visibility
	React.useEffect(() => {
		setColumnVisibility((prev) => ({
			...prev,
			createdAt: false,
			hostname: false,
			port: false,
		}));
	}, []);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	const renderChip = React.useMemo(() => {
		const renderStatusChip = (value: EMailConfigurationVerificationStatus | undefined): JSX.Element => {
			if (!value) {
				return (
					<Chip label={t('page.smtp.list.table.body.unknown')} sx={{ bgcolor: '#bdbdbd', color: 'white' }} />
				);
			}

			switch (value) {
				case EMailConfigurationVerificationStatus.Verified:
					return (
						<Chip
							icon={<DoneIcon />}
							label={t('page.smtp.list.table.body.verified')}
							color='success'
							sx={{ width: '100%' }}
						/>
					);
				case EMailConfigurationVerificationStatus.Pending:
					return (
						<Chip
							icon={<DotsIcon />}
							label={t('page.smtp.list.table.body.pending')}
							color='info'
							variant='outlined'
							sx={{ width: '100%' }}
						/>
					);
				case EMailConfigurationVerificationStatus.Failed:
					return (
						<Chip
							icon={<CloseIcon />}
							label={t('page.smtp.list.table.body.failed')}
							color='error'
							sx={{ width: '100%' }}
						/>
					);
				default:
					return (
						<Chip
							label={t('page.smtp.list.table.body.unknown')}
							sx={{ bgcolor: '#bdbdbd', color: 'white', width: '100%' }}
						/>
					);
			}
		};

		return renderStatusChip;
	}, [t]);

	const checkRefreshNeeded = React.useCallback((configs: ReadSMTPMailConfigurationResponse[]): boolean => {
		if (configs.length === 0) {
			return false;
		}

		let refreshNeeded = false;

		configs.forEach((config) => {
			if (config.verificationStatus === EMailConfigurationVerificationStatus.Pending) {
				refreshNeeded = true;
			}
		});

		setIsRefreshNeeded(refreshNeeded);

		return refreshNeeded;
	}, []);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	React.useEffect(() => {
		if (!data) {
			return;
		}

		checkRefreshNeeded(data);
	}, [data]);

	React.useEffect(() => {
		if (!data) {
			return;
		}

		const triggerRefresh = checkRefreshNeeded(data);

		if (triggerRefresh) {
			const timeout = setTimeout(() => {
				reactQueryClient.invalidateQueries();
				setIsRefreshNeeded(false);
			}, 5000);

			return () => clearTimeout(timeout);
		}
	}, [isRefreshNeeded, data]);

	const columns = React.useMemo<MRTColumnDef<Partial<ReadSMTPMailConfigurationResponse>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.smtp.list.table.columns.name'),
			},

			{
				accessorFn: (row) => `${row.sender}`,
				accessorKey: 'sender',
				header: t('page.smtp.list.table.columns.sender'),
			},
			{
				accessorFn: (row) => `${row.host}:${row.port}`,
				accessorKey: 'address',
				enableSorting: false,
				enableColumnActions: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: t('page.smtp.list.table.columns.address'),
			},
			{
				accessorFn: (row) => `${row.host}`,
				accessorKey: 'hostname',
				enableSorting: false,
				enableColumnActions: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: t('page.smtp.list.table.columns.host'),
			},
			{
				accessorFn: (row) => `${row.port}`,
				accessorKey: 'port',
				enableSorting: false,
				enableColumnActions: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: t('page.smtp.list.table.columns.port'),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				header: t('page.smtp.list.table.columns.createdAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.verificationStatus}`,
				accessorKey: 'verificationStatus',
				filterVariant: 'select',
				filterSelectOptions: Object.values(EMailConfigurationVerificationStatus).map((status) => ({
					label: t(`page.smtp.list.table.verificationStatus.${status}`),
					value: status,
				})),
				header: t('page.smtp.list.table.columns.status'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Box
							sx={{
								width: 115,
							}}
						>
							{renderChip(row.original.verificationStatus)}
						</Box>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.active}`,
				accessorKey: 'active',
				enableColumnFilter: false,
				enableGlobalFilter: false,
				header: t('page.smtp.list.table.columns.state'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Box
							sx={{
								width: 115,
							}}
						>
							<Chip
								label={
									row.original.active ?
										t('page.smtp.list.table.body.active')
									:	t('page.smtp.list.table.body.inactive')
								}
								color={row.original.active ? 'success' : 'default'}
								sx={{ width: '100%' }}
							/>
						</Box>
					</Box>
				),
			},
		],
		[],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.smtp.list.title')}
						description={t('page.smtp.list.description')}
						icon={EmailIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							columns={columns}
							data={data || []}
							enableRowActions
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								rowSelection,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							// rowCount={total}
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onColumnVisibilityChange={setColumnVisibility}
							enableRowSelection={(row) => !row.original.active && isAllowed([EPermission.SMTP_DELETE])}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							onRowSelectionChange={setRowSelection}
							renderRowActions={({ row }) => (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '1rem',
									}}
								>
									{isAllowed([EPermission.SMTP_UPDATE]) && (
										<Tooltip
											arrow
											placement='left'
											title={t('page.smtp.list.tooltips.activate')}
											enterDelay={500}
										>
											<span>
												<IconButton
													disabled={
														row.original.active ||
														row.original.verificationStatus !== 'verified'
													}
													onClick={handleOnOpenActivateDialog(row.original.id as number)}
												>
													<CheckIcon
														style={{
															color:
																(
																	row.original.active ||
																	row.original.verificationStatus !== 'verified'
																) ?
																	'#bdbdbd'
																:	'green',
														}}
													/>
												</IconButton>
											</span>
										</Tooltip>
									)}

									{isAllowed([EPermission.SMTP_UPDATE]) && (
										<Tooltip
											arrow
											placement='bottom'
											title={t('page.smtp.list.tooltips.edit')}
											enterDelay={500}
										>
											<span>
												<Link to={`/settings/general/email/smtp/edit/${row.original.id}`}>
													<IconButton>
														<EditIcon />
													</IconButton>
												</Link>
											</span>
										</Tooltip>
									)}
									{isAllowed([EPermission.SMTP_DELETE]) && (
										<Tooltip
											arrow
											placement='bottom'
											title={t('page.smtp.list.tooltips.delete')}
											enterDelay={500}
										>
											<span>
												<IconButton
													disabled={row.original.active}
													style={{
														color: row.original.active ? '#bdbdbd' : '#d32f2f',
													}}
													onClick={handleOnOpenConfigDeleteDialog(row.original.id as number)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
								</Box>
							)}
							renderToolbarInternalActions={({ table }) => (
								<Box>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.smtp.list.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									{isAllowed([EPermission.SMTP_DELETE]) && (
										<Tooltip title={t('page.smtp.list.tooltips.removeSelected')} enterDelay={500}>
											<span>
												<IconButton
													color='error'
													disabled={table.getSelectedRowModel().rows.length === 0}
													onClick={handleOnOpenMultipleConfigsDelete(table)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.smtp.list.table.columns.actions'),
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							editDisplayMode='modal'
							positionActionsColumn='last'
							localization={MRTLocalization}
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>

			{isAllowed([EPermission.SMTP_CREATE]) && (
				<Link to='/settings/general/email/smtp/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.smtp.list.ariaLabel.addSMTP')}
						tooltipTitle={t('page.smtp.list.tooltips.addSMTP')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.SMTP_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={configIDToActivate ? handleOnConfirmConfigActive : handleOnConfirmConfigDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.tenants.list.confirmation.delete.cancel')}
					confirmText={t('page.tenants.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
