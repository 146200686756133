import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MaterialReactTable,
} from 'material-react-table';
import { Box, Paper, Typography, Stack, Grid, useMediaQuery, useTheme, Avatar } from '@mui/material';
import { SaveAlt as SaveAltIcon, Workspaces as WorkspacesIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from '../../components/Link/Link';
import { useNavigate } from '../../hooks/useNavigate';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { FloatingButtonEdit } from '../../components/Buttons/FloatingButton/FloatingButtonEdit';
import { userListInGroupSchema } from './schema';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { GroupDto, PagedResultUserInGroupModel, UserInGroupModel } from '../../api/Api';
import { useACL } from '../../hooks/useACL';
import { Preloader } from '../../components/Preloader/Preloader';
import { Heading } from '../../components/Heading/Heading';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { useFormatDate } from '../../hooks/useFormatDate';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useTableQuery } from '../../hooks/useTableQuery';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useFetchUserGroup } from '../../hooks/query/userGroups/useFetchUserGroup';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';

export const UserGroupDetail: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const { id } = useParams();
	const theme = useTheme();
	const navigate = useNavigate();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const formatDate = useFormatDate();
	const { MRTLocalization } = useMRTLocalization();

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'surname', 'email', 'userFullName']);

	const {
		data: userGroup,
		isLoading: isLoadingUserGroup,
		isSuccess: isLoadedUserGroup,
	} = useFetchUserGroup(id ? Number(id) : undefined);

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultUserInGroupModel>({
		queryKey: [EQueryKey.USER_LIST_QUERY, swaggerQuery, id],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.userGroups.getUserDetailsInGroup(Number(id), query);
				response.data.entities.forEach((user) => {
					userListInGroupSchema.parse(user);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const handleOnChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			if (Number(id) === group.id) {
				return;
			}

			navigate(`/users/userGroups/${group?.id}`);
		},
		[isAllowed, id],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<UserInGroupModel>>[]>(
		() => [
			{
				accessorFn: (user) =>
					`${user.title ? `${user.title} ` : ''}${user.name} ${user.surname ? user.surname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.userGroups.detail.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={String(renderedCellValue)} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.email}`,
				accessorKey: 'email',
				header: t('page.userGroups.detail.table.header.email'),
			},
			{
				accessorFn: (row) => `${row.groups}`,
				accessorKey: 'groups',
				header: t('page.userGroups.detail.table.header.groups'),
				enableSorting: false,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={isAllowed([EPermission.USER_GROUPS_READ]) ? row.original.groups : undefined}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
		],
		[id, isAllowed, isLoading],
	);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	return (
		<>
			{isLoadingUserGroup || !isLoadedUserGroup ?
				<Preloader percentageHeight={100} />
			:	<Box sx={{ marginBottom: 10 }}>
					<Paper elevation={3}>
						<Stack
							spacing={2}
							sx={{
								padding: 2,
							}}
						>
							<PageHeader
								title={t('page.userGroups.detail.title')}
								description={t('page.userGroups.detail.description')}
								icon={WorkspacesIcon}
							/>
							{userGroup && (
								<Stack
									sx={{
										width: getStackWidth(),
									}}
								>
									<Heading label={t('page.userGroups.detail.subtitle.general')} />
									<Grid container gap={1} marginTop={1}>
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.userGroups.detail.body.name')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography px={2}>{userGroup.name}</Typography>
												</Grid>
											</Grid>
										</Grid>
										{userGroup.description && (
											<Grid item xs={12}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={4}>
														<Typography px={1} sx={{ fontWeight: 'bold' }}>
															{`${t('page.userGroups.detail.body.description')}: `}
														</Typography>
													</Grid>
													<Grid item xs={12} sm={8}>
														<Typography px={2}>{userGroup?.description}</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.userGroups.detail.body.totalUsers')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography px={2}>{userGroup?.totalUsers || 0}</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.userGroups.detail.body.type.label')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{userGroup?.isBuiltIn ?
															`${t('page.userGroups.detail.body.type.options.builtIn')}`
														:	`${t('page.userGroups.detail.body.type.options.custom')}`}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										{userGroup.createdAt && (
											<Grid item xs={12}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={4}>
														<Typography px={1} sx={{ fontWeight: 'bold' }}>
															{`${t('page.userGroups.detail.body.createdAt')}: `}
														</Typography>
													</Grid>
													<Grid item xs={12} sm={8}>
														<Typography px={2}>
															{formatDate(userGroup.createdAt)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
										{userGroup.updatedAt && (
											<Grid item xs={12}>
												<Grid container spacing={1}>
													<Grid item xs={12} sm={4}>
														<Typography px={1} sx={{ fontWeight: 'bold' }}>
															{`${t('page.userGroups.detail.body.updatedAt')}: `}
														</Typography>
													</Grid>
													<Grid item xs={12} sm={8}>
														<Typography px={2}>
															{formatDate(userGroup.updatedAt)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										)}
									</Grid>
								</Stack>
							)}

							<Stack spacing={2}>
								<SectionHeader
									title={t('page.userGroups.detail.subtitle.userList')}
									description={t('page.userGroups.detail.table.description.userList', {
										userGroup: userGroup?.name,
									})}
								/>
								<MaterialReactTable
									columns={columns}
									data={entities}
									enableStickyHeader={false}
									state={{
										isLoading,
										showAlertBanner: error !== null,
										pagination,
										showProgressBars: isRefetching,
										columnFilters,
										globalFilter,
										sorting,
										columnVisibility,
									}}
									muiToolbarAlertBannerProps={{
										color: 'error',
										children: <>{error}</>,
									}}
									initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
									rowCount={total}
									manualPagination
									manualFiltering
									manualSorting
									onSortingChange={setSorting}
									onGlobalFilterChange={setGlobalFilter}
									onColumnFiltersChange={setColumnFilters}
									onPaginationChange={setPagination}
									onColumnVisibilityChange={setColumnVisibility}
									renderToolbarInternalActions={({ table }) => (
										<Box sx={{ display: 'flex', gap: '1rem' }}>
											<MRTToggleGlobalFilterButton table={table} />
											<MRTToggleFiltersButton table={table} />
											<MRTShowHideColumnsButton table={table} />
											{/* <Tooltip
												title={t('page.userGroups.detail.tooltips.export')}
												enterDelay={500}
											>
												<IconButton>
													<SaveAltIcon />
												</IconButton>
											</Tooltip> */}
											<MRTToggleDensePaddingButton table={table} />
											<MRTFullScreenToggleButton table={table} />
										</Box>
									)}
									displayColumnDefOptions={{
										'mrt-row-select': {
											enableHiding: true,
											visibleInShowHideMenu: false,
										},
									}}
									muiTablePaperProps={({ table }) => ({
										style: {
											zIndex: table.getState().isFullScreen ? 1100 : undefined,
											boxShadow: 'none',
											outline: '1px solid #e0e0e0',
										},
									})}
									muiSelectCheckboxProps={() => ({
										sx: {
											width: '50px',
											height: '50px',
										},
									})}
									muiSelectAllCheckboxProps={() => ({
										sx: {
											width: '50px',
											height: '50px',
										},
									})}
									muiTableHeadCellProps={() => ({
										sx: {
											verticalAlign: 'baseline',
										},
									})}
									editDisplayMode='modal'
									positionActionsColumn='last'
									localization={MRTLocalization}
									layoutMode='grid'
								/>
							</Stack>
						</Stack>
					</Paper>

					{isAllowed([EPermission.USER_GROUPS_UPDATE]) && (
						<Link to={`/users/userGroups/edit/${id}`}>
							<FloatingButtonEdit
								ariaLabel={t('page.userGroups.detail.ariaLabel.editGroup')}
								tooltipTitle={t('page.userGroups.detail.tooltips.editGroup')}
							/>
						</Link>
					)}
				</Box>
			}
		</>
	);
};
