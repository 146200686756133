import * as React from 'react';
import { Button, CircularProgress, Divider, InputBase, ListItem, Paper, Tooltip } from '@mui/material';
import { Apartment as ApartmentIcon, List as ListIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { usePathName } from '../../hooks/usePathName';
import { ETenantSections } from '../../pages/tenants/enums';
import { Link } from '../../components/Link/Link';
import { SubMenu } from '../../components/SubMenu/SubMenu';
import { MenuItem } from '../../components/MenuItem/MenuItem';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useDebounce } from '../../hooks/useDebounce';
import { tenantListSchema } from './schema';
import { FILTER_DEBOUNCE_TIME, QUERY_LIMIT } from './constants';

export const TenantsSubMenu: React.FC = () => {
	const { t } = useTranslation();
	const { isAllowed } = useACL();
	const api = useSwaggerApi();
	const location = useLocation();
	const pathname = usePathName();

	const [searchValue, setSearchValue] = React.useState<string>('');
	const debouncedSearchValue = useDebounce(searchValue, FILTER_DEBOUNCE_TIME);
	const [isAllTenantsLoaded, setIsAllTenantsLoaded] = React.useState<boolean>(false);
	const [showAllTenants, setShowAllTenants] = React.useState<boolean>(true);

	const { data, isFetched, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery({
		queryKey: [EQueryKey.TENANT_LIST_INFINITE_QUERY, debouncedSearchValue, QUERY_LIMIT],
		queryFn: async ({ pageParam = 0 }) => {
			try {
				const limit = QUERY_LIMIT;
				const offset = pageParam * QUERY_LIMIT;
				const filter = debouncedSearchValue.length > 0 ? [`%(name,${debouncedSearchValue})`] : undefined;

				const query = {
					limit,
					offset,
					filter,
				};

				const response = await api.tenants.getTenants(query);
				response.data.entities.forEach((tenant) => {
					tenantListSchema.parse(tenant);
				});

				const { entities, total } = response.data;

				return { entities, total, offset };
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0, offset: 0 };
			}
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages) => {
			const loadedEntities = allPages.flatMap((page) => page.entities).length;
			if (loadedEntities >= lastPage.total) {
				return undefined;
			}

			return lastPage.offset / QUERY_LIMIT + 1;
		},
	});

	const isRedirectedFromTenant = React.useMemo(() => {
		const state = location.state;
		const returnPath = state?.return;

		return typeof returnPath === 'string' && location.state?.return?.startsWith('/tenants');
	}, [location.state]);

	const redirectedFromTenantID = React.useMemo(() => {
		const state = location.state;
		const tenantIdOrSlug = state?.tenantIdOrSlug;

		return typeof tenantIdOrSlug === 'string' && !isNaN(Number(tenantIdOrSlug)) ? Number(tenantIdOrSlug) : null;
	}, [location.state]);

	const allTenants = React.useMemo(() => {
		return data && data?.pages ? data.pages.flatMap((page) => page.entities) : [];
	}, [data?.pages]);

	const displayedTenants = React.useMemo(() => {
		return (isAllTenantsLoaded && showAllTenants) || !isAllTenantsLoaded ?
				allTenants
			:	allTenants.slice(0, QUERY_LIMIT);
	}, [allTenants, isAllTenantsLoaded, showAllTenants]);

	const loadButtonText = React.useMemo(() => {
		if (isFetching) {
			return <CircularProgress size={20} sx={{ color: 'inherit' }} />;
		}
		if (showAllTenants && isAllTenantsLoaded) {
			return t('component.tenantsSubMenu.body.showLess');
		}

		return t('component.tenantsSubMenu.body.showMore');
	}, [isFetching, showAllTenants, isAllTenantsLoaded, hasNextPage]);

	const handleOnSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	}, []);

	const handleOnShowTenantsChange = React.useCallback(() => {
		if (isFetchingNextPage) {
			return;
		}
		if (hasNextPage) {
			fetchNextPage();

			return;
		}
		if (isAllTenantsLoaded) {
			setShowAllTenants((prev) => !prev);
		}
	}, [hasNextPage, isFetchingNextPage, isAllTenantsLoaded, fetchNextPage]);

	React.useEffect(() => {
		if (isAllTenantsLoaded && hasNextPage) {
			setIsAllTenantsLoaded(false);
			setShowAllTenants(true);
		}
		if (!isAllTenantsLoaded && !hasNextPage && isFetched) {
			setIsAllTenantsLoaded(true);
			setShowAllTenants(true);
		}
	}, [searchValue, hasNextPage]);

	return (
		<SubMenu
			icon={<ApartmentIcon />}
			label={t('menu.item.tenants')}
			active={
				location.pathname.startsWith('/tenants') ||
				(isRedirectedFromTenant && pathname.startsWith('/users/invitations')) ||
				(isRedirectedFromTenant && pathname.startsWith('/identityStores'))
			}
			defaultOpen={
				location.pathname.startsWith('/tenants') ||
				(isRedirectedFromTenant && pathname.startsWith('/users/invitations')) ||
				(isRedirectedFromTenant && pathname.startsWith('/identityStores'))
			}
			tooltipTitle={t('menu.tooltip.subMenu.tenants')}
		>
			{isAllowed([EPermission.TENANTS_READ]) && (
				<MenuItem
					icon={<ListIcon />}
					component={<Link to='/tenants' />}
					active={['/tenants', '/tenants/new'].includes(pathname)}
					tooltipTitle={t('menu.tooltip.item.tenantList')}
				>
					{t('menu.item.tenantList')}
				</MenuItem>
			)}

			{isAllowed([EPermission.TENANTS_READ]) && displayedTenants.length > 0 && (
				<Divider sx={{ backgroundColor: 'grey.500', height: 1, margin: '0 20px 0 40px' }} />
			)}

			{isAllowed([EPermission.TENANTS_READ]) && displayedTenants.length > 0 && (
				<Tooltip title={t('menu.tooltip.item.search')} enterNextDelay={1000} placement='right' arrow>
					<ListItem
						sx={{
							padding: '10px 20px 10px 40px',
						}}
					>
						<Paper
							component='div'
							sx={{
								padding: '2px 4px',
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								height: '100%',
							}}
						>
							<InputBase
								sx={{ marginLeft: 1, flex: 1, fontSize: 14 }}
								value={searchValue}
								onChange={handleOnSearchChange}
								placeholder={t('component.tenantsSubMenu.body.search')}
								inputProps={{ 'aria-label': 'search tenant' }}
							/>
						</Paper>
					</ListItem>
				</Tooltip>
			)}

			{isAllowed([EPermission.TENANTS_READ]) &&
				displayedTenants.map((tenant) => (
					<MenuItem
						key={`detail-${tenant.id}`}
						component={<Link to={`/tenants/${tenant.id}`} state={{ tab: ETenantSections.GENERAL }} />}
						active={
							pathname === `/tenants/${tenant.id}` ||
							pathname === `/tenants/edit/${tenant.id}` ||
							(isRedirectedFromTenant &&
								redirectedFromTenantID === tenant.id &&
								pathname.startsWith('/users/invitations')) ||
							(isRedirectedFromTenant &&
								redirectedFromTenantID === tenant.id &&
								pathname.startsWith('/identityStores'))
						}
						tooltipTitle={t('menu.tooltip.item.tenantGeneral')}
						style={{ paddingLeft: '40px' }}
					>
						{tenant.name}
					</MenuItem>
				))}

			{data && (data.pageParams.length > 1 || hasNextPage) && (
				<Tooltip
					arrow
					placement='right'
					title={t('component.tenantsSubMenu.tooltips.showButton')}
					enterDelay={500}
				>
					<ListItem
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
						}}
					>
						<Button
							onClick={handleOnShowTenantsChange}
							disabled={isFetchingNextPage}
							variant='text'
							sx={{
								color: 'inherit',
								fontSize: '14px',
								textTransform: 'none',
								fontWeight: 'bold',
							}}
						>
							{loadButtonText}
						</Button>
					</ListItem>
				</Tooltip>
			)}
		</SubMenu>
	);
};
