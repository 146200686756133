/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserInvitationDetailDto {
	status: string;
	/** @format date-time */
	expiresAt: string;
	username: string;
	email: string;
	title: string | null;
	surname: string | null;
	name: string;
	deploymentName: string | null;
	companyName: string | null;
	/** @format double */
	invitationID: number;
}

export interface RegisterResponseDto {
	qr: string;
}

export interface RegisterRequestDto {
	secret: string;
	password: string;
	username: string;
}

export interface LoginResponseDto {
	tenant?: {
		name: string;
	};
	/** QR code for the user to scan in order to perform login. */
	qr?: string;
}

export interface LoginRequestDto {
	format: 'svg';
	first: boolean;
}

export interface GeofenceModel {
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number | null;
	/**
	 * The user ID of the geofence owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The radius of the geofence.
	 * @format double
	 * @example 100
	 */
	radius: number;
	/**
	 * The longitude of the geofence.
	 * @format double
	 * @example 55.296249
	 */
	longitude: number;
	/**
	 * The latitude of the geofence.
	 * @format double
	 * @example 25.276987
	 */
	latitude: number;
	/** The name of the geofence. */
	name: string;
	/**
	 * Type ID of the geofence.
	 * @format double
	 * @example 2
	 */
	typeID: number;
	/**
	 * The unique identifier of the geofence.
	 * @format double
	 * @example 1
	 */
	id: number;
}

export type GetGeofenceResponseDto = GeofenceModel;

export interface PagedResultGetGeofenceResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceResponseDto[];
}

export type GetGeofencesResponseDto = PagedResultGetGeofenceResponseDto;

export type CreateGeofenceResponseDto = GeofenceModel;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickGeofenceModelExcludeKeysId {
	/**
	 * Type ID of the geofence.
	 * @format double
	 * @example 2
	 */
	typeID: number;
	/** The name of the geofence. */
	name: string;
	/**
	 * The latitude of the geofence.
	 * @format double
	 * @example 25.276987
	 */
	latitude: number;
	/**
	 * The longitude of the geofence.
	 * @format double
	 * @example 55.296249
	 */
	longitude: number;
	/**
	 * The radius of the geofence.
	 * @format double
	 * @example 100
	 */
	radius: number;
	/**
	 * The user ID of the geofence owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitGeofenceModelId = PickGeofenceModelExcludeKeysId;

export type CreateGeofenceRequestDto = OmitGeofenceModelId;

/** Make all properties in T optional */
export interface PartialCreateGeofenceRequestDto {
	/**
	 * Type ID of the geofence.
	 * @format double
	 * @example 2
	 */
	typeID?: number;
	/** The name of the geofence. */
	name?: string;
	/**
	 * The latitude of the geofence.
	 * @format double
	 * @example 25.276987
	 */
	latitude?: number;
	/**
	 * The longitude of the geofence.
	 * @format double
	 * @example 55.296249
	 */
	longitude?: number;
	/**
	 * The radius of the geofence.
	 * @format double
	 * @example 100
	 */
	radius?: number;
	/**
	 * The user ID of the geofence owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number;
}

export type UpdateGeofenceRequestDto = PartialCreateGeofenceRequestDto;

export interface CreateGeofenceGroupResponseDto {
	/**
	 * The id of the created geofence group
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface CreateGeofenceGroupRequestDto {
	/**
	 * The IDs of the geofences that are part of the group
	 * @example [1,2,3]
	 */
	geofences?: number[];
	/** Description of the created geofence group to help identify it */
	description?: string;
	/** The name of the geofence group */
	name: string;
}

export interface GetGeofenceGroupByIDResponseDto {
	/** @format double */
	tenantID?: number | null;
	/**
	 * The geofences IDs that are part of the group
	 * @format double
	 * @example [1,2,3]
	 */
	totalGeofences?: number;
	/** Description of the created geofence group */
	description?: string;
	/** The name of the geofence group */
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetGeofenceGroupByIDResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceGroupByIDResponseDto[];
}

/** Make all properties in T optional */
export interface PartialCreateGeofenceGroupRequestDto {
	/** The name of the geofence group */
	name?: string;
	/** Description of the created geofence group to help identify it */
	description?: string;
	/**
	 * The IDs of the geofences that are part of the group
	 * @example [1,2,3]
	 */
	geofences?: number[];
}

export type UpdateGeofenceGroupRequestDto = PartialCreateGeofenceGroupRequestDto;

export interface GetGeofenceGroupDetailByIDResponseDto {
	/** @format double */
	tenantID?: number | null;
	/**
	 * The geofences that are part of the group
	 * @example [{"id":1,"type":"geofence","name":"Dubai","latitude":25.276987,"longitude":55.296249,"radius":1000},{"id":2,"type":"geofence","name":"Abu Dhabi","latitude":24.4539,"longitude":54.3773,"radius":500}]
	 */
	geofences: GeofenceModel[];
	/** Description of the created geofence group */
	description?: string;
	/** The name of the geofence group */
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetGeofenceGroupDetailByIDResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceGroupDetailByIDResponseDto[];
}

export interface GeofenceGroupModel {
	/**
	 * The user ID of the tenant.
	 * @format double
	 * @example 1
	 */
	tenantID?: number | null;
	/**
	 * The user ID of the geofence group owner.
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The geofences IDs that are part of the group.
	 * @example [1,2,3]
	 */
	geofences?: number[];
	/** Description of the created geofence group. */
	description?: string;
	/** The name of the geofence group. */
	name: string;
	/**
	 * The unique identifier of the geofence group.
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface GetGeofenceDetailsInGroupResponseDto {
	geofenceGroups?: GeofenceGroupModel[];
	/**
	 * The type ID of the geofence
	 * @format double
	 * @example 2
	 */
	typeID: number;
	/**
	 * The user ID of the tenant
	 * @format double
	 * @example 1
	 */
	tenantID?: number | null;
	/**
	 * The user ID of the geofence owner
	 * @format double
	 * @example 1
	 */
	userID?: number;
	/**
	 * The radius of the geofence
	 * @format double
	 * @example 100
	 */
	radius: number;
	/**
	 * The longitude of the geofence
	 * @format double
	 * @example 55.296249
	 */
	longitude: number;
	/**
	 * The latitude of the geofence
	 * @format double
	 * @example 25.276987
	 */
	latitude: number;
	/** The name of the geofence */
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetGeofenceDetailsInGroupResponseDto {
	/** @format double */
	total: number;
	entities: GetGeofenceDetailsInGroupResponseDto[];
}

/** Make all properties in T optional */
export interface PartialGeofenceTypeDto {
	/** @format double */
	id?: number;
	name?: string;
	/** @format double */
	maxCount?: number;
	/** @format double */
	maxRadius?: number;
	validFrom?: string;
	validTo?: string;
}

export interface PagedResultPartialGeofenceTypeDto {
	/** @format double */
	total: number;
	entities: PartialGeofenceTypeDto[];
}

export interface CreateIdentityStoreDto {
	password: string;
	bindDN: string;
	baseDN: string;
	useTLS: boolean;
	caCertificate?: string;
	/** @format double */
	port: number;
	host: string;
	description?: string;
	name: string;
}

export enum EInvitationStatus {
	Pending = 'pending',
	Sent = 'sent',
	Failed = 'failed',
	Dismissed = 'dismissed',
	Accepted = 'accepted',
	Blocked = 'blocked',
}

/** Make all properties in T optional */
export interface PartialDetailedInvitationDto {
	/**
	 * The unique identifier of the invitation.
	 * @format double
	 * @example 1234
	 */
	id?: number;
	/**
	 * Identity store ID.
	 * @format double
	 * @example 1234
	 */
	identityStoreID?: number;
	/**
	 * The unique identifier of the user that the invitation is for.
	 * @format double
	 * @example 5678
	 */
	userID?: number;
	/** The status of the invitation. */
	status?: EInvitationStatus;
	/**
	 * The number of failed attempts to accept the invitation.
	 * @format double
	 * @example 0
	 */
	failedAttempts?: number;
	/** The date and time when the invitation was created. */
	createdAt?: string;
	/** The date and time when the invitation will expire. */
	expiresAt?: string;
	/**
	 * The unique identifier of the user that created the invitation.
	 * @format double
	 * @example 1234
	 */
	createdBy?: number;
	/** The unique identifier of the invitation. */
	uuid?: string;
	/** The email address of the user that the invitation is for. */
	email?: string;
	/**
	 * Invitation tenant ID
	 * @format double
	 * @example 1234
	 */
	tenantID?: number;
	countryCode?: string;
	phoneNumber?: string;
}

export interface PagedResultPartialDetailedInvitationDto {
	/** @format double */
	total: number;
	entities: PartialDetailedInvitationDto[];
}

/** Make all properties in T optional */
export interface PartialIdentityStoreDto {
	/** @format double */
	id?: number;
	name?: string;
	description?: string;
	host?: string;
	/** @format double */
	port?: number;
	caCertificate?: string;
	baseDN?: string;
	bindDN?: string;
	/** @format double */
	tenantId?: number;
	useTLS?: boolean;
}

export interface PagedResultPartialIdentityStoreDto {
	/** @format double */
	total: number;
	entities: PartialIdentityStoreDto[];
}

export interface IdentityStoreDto {
	useTLS: boolean;
	/** @format double */
	tenantId?: number;
	bindDN: string;
	baseDN: string;
	caCertificate?: string;
	/** @format double */
	port?: number;
	host: string;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export type RpcUpdateLDAPIdentityStoreResponseAsObject = object;

export interface UpdateIdentityStoreDto {
	password?: string;
	bindDN?: string;
	baseDN?: string;
	useTLS?: boolean;
	caCertificate?: string;
	/** @format double */
	port?: number;
	host?: string;
	description?: string;
	name?: string;
}

export interface UserFromIdentityStore {
	expired: boolean;
	disabled: boolean;
	surname?: string;
	name: string;
	username: string;
	email?: string;
	uuid: string;
}

export interface PagedResultUserFromIdentityStore {
	/** @format double */
	total: number;
	entities: UserFromIdentityStore[];
}

export type GetUsersFromIdentityStoreResponseDto = PagedResultUserFromIdentityStore;

export interface GetLocalizationsDto {
	skLocalizations: string;
	enLocalizations: string;
}

export enum ELanguageCode {
	En = 'en',
	Sk = 'sk',
}

export interface ChangeLanguageDto {
	language: ELanguageCode;
}

export enum EMailSMTPSecurity {
	None = 'none',
	Starttls = 'starttls',
	Tls = 'tls',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickCreateSMTPMailConfigurationDtoExcludeKeysCodeOrStatus {
	name: string;
	description?: string;
	email: string;
	host: string;
	/** @format double */
	port: number;
	username?: string;
	password?: string;
	sender: string;
	security: EMailSMTPSecurity;
	active?: boolean;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitCreateSMTPMailConfigurationDtoCodeOrStatus = PickCreateSMTPMailConfigurationDtoExcludeKeysCodeOrStatus;

export type CreateSMTPMailConfigurationRequest = OmitCreateSMTPMailConfigurationDtoCodeOrStatus;

export enum EMailConfigurationVerificationStatus {
	Verified = 'verified',
	Pending = 'pending',
	Failed = 'failed',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickMailSMTPConfigurationExcludeKeysPassword {
	/** @format double */
	id: number;
	name: string;
	description?: string;
	/** @format date-time */
	createdAt: string;
	host: string;
	/** @format double */
	port: number;
	username: string;
	sender: string;
	security: EMailSMTPSecurity;
	active: boolean;
	verificationStatus: EMailConfigurationVerificationStatus;
	verificationEmail: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitMailSMTPConfigurationPassword = PickMailSMTPConfigurationExcludeKeysPassword;

export type ReadSMTPMailConfigurationResponse = OmitMailSMTPConfigurationPassword;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickUpdateSMTPMailConfigurationDtoExcludeKeysCode {
	name?: string;
	description?: string;
	email?: string;
	host?: string;
	/** @format double */
	port?: number;
	username?: string;
	password?: string;
	sender?: string;
	security?: EMailSMTPSecurity;
	active?: boolean;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitUpdateSMTPMailConfigurationDtoCode = PickUpdateSMTPMailConfigurationDtoExcludeKeysCode;

export type UpdateSMTPMailConfigurationRequest = OmitUpdateSMTPMailConfigurationDtoCode;

export interface VerifySMTPMailConfigurationDto {
	verificationCode: string;
}

export enum EMailConfigurationType {
	Smtp = 'smtp',
	Gapi = 'gapi',
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickMailConfigurationExcludeKeysVerificationCode {
	/** @format double */
	id: number;
	name: string;
	description?: string;
	/** @format date-time */
	createdAt: string;
	sender: string;
	active: boolean;
	type: EMailConfigurationType;
	verificationStatus: EMailConfigurationVerificationStatus;
	verificationEmail: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitMailConfigurationVerificationCode = PickMailConfigurationExcludeKeysVerificationCode;

export type ReadMailConfigurationResponse = OmitMailConfigurationVerificationCode;

/** Make all properties in T optional */
export interface PartialOAuthClientResponseDto {
	clientID?: string;
	name?: string;
	description?: string;
	createdAt?: string;
	secret?: string;
	/** @format double */
	accessTokenValidity?: number;
	originalSecret?: string;
}

/** Make all properties in T optional */
export interface PartialOAuthClientsCollectionResponseDto {
	clientID?: string;
	name?: string;
	createdAt?: string;
	expiresAt?: string;
}

export interface PagedResultPartialOAuthClientsCollectionResponseDto {
	/** @format double */
	total: number;
	entities: PartialOAuthClientsCollectionResponseDto[];
}

/** Make all properties in T optional */
export interface PartialCreateOAuthClientResponseDto {
	clientID?: string;
	name?: string;
	description?: string;
	/** @format double */
	accessTokenValidity?: number;
}

export interface CreateOAuthClientDto {
	name: string;
	description: string;
	/** @format double */
	accessTokenValidity: number;
}

export interface UpdateOAuthClientDto {
	clientSecret?: string;
	name?: string;
	description?: string;
	/** @format double */
	accessTokenValidity?: number;
}

/** Make all properties in T optional */
export interface PartialRefreshClientSecretResponseDto {
	originalSecret?: string;
}

export interface PermissionModel {
	/** Permission title */
	title: string;
	/** Permission description */
	description?: string;
	/** Permission name */
	name: string;
	/**
	 * Permission ID
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface OAuthTokenResultDto {
	access_token: string;
	token_type: string;
	/** @format double */
	expires_in: number;
}

export interface AuthorizeDto {
	clientID: string;
	clientSecret: string;
}

export interface CreateWebhookResponseDto {
	/** @format double */
	webhookID: number;
}

export enum ESignatureMethod {
	Hmac = 'hmac',
	DeploymentPrivateKey = 'deployment_private_key',
}

export interface CreateWebhookRequestDto {
	caCertificate?: string;
	secret?: string;
	useTLS: boolean;
	signatureMethod: ESignatureMethod;
	eventTypeIDs: number[];
	url: string;
	oAuthClientID: string;
}

export interface UpdateWebhookResponseDto {
	/** @format double */
	webhookID: number;
}

export interface UpdateWebhookRequestDto {
	enabled?: boolean;
	caCertificate?: string;
	secret?: string;
	useTLS?: boolean;
	signatureMethod?: ESignatureMethod;
	eventTypeIDs?: number[];
	url?: string;
}

export interface EventTypeModel {
	name: string;
	/** @format double */
	id: number;
}

export interface WebhookModel {
	createdAt: string;
	deliveryFailure: boolean;
	caCertificate?: string;
	secret?: string;
	useTLS: boolean;
	signatureMethod: ESignatureMethod;
	enabled: boolean;
	eventTypes: EventTypeModel[];
	url: string;
	oAuthClientID: string;
	/** @format double */
	id: number;
}

export type GetWebhookByIdResponseDto = WebhookModel;

export interface GetWebhooksByOauthClientResponseDto {
	webhooks: WebhookModel[];
}

export interface GetEventTypesResponseDto {
	eventTypes: EventTypeModel[];
}

export enum EPAMTargetType {
	RDP = 'RDP',
	SSH = 'SSH',
	VNC = 'VNC',
}

export interface PAMTargetModel {
	/** @format double */
	port: number;
	hostname: string;
	/**
	 * PAM target description
	 * @example "Active Directory Read-Only Domain Controller 1"
	 */
	description?: string;
	/**
	 * PAM target name
	 * @example "AD RODC 1"
	 */
	name: string;
	/**
	 * PAM target type
	 * @example "ssh"
	 */
	type: EPAMTargetType;
	/**
	 * Tenant ID
	 * @format double
	 * @example 1
	 */
	tenantID?: number;
	/**
	 * PAM target ID
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface PagedResultPAMTargetModel {
	/** @format double */
	total: number;
	entities: PAMTargetModel[];
}

export type GetPAMTargetsResponseDto = PagedResultPAMTargetModel;

export interface DuplicatePAMTargetResponseDto {
	/** @format double */
	id: number;
}

export interface PAMTargetGroupModel {
	borderColor?: string;
	bgColor?: string;
	textColor?: string;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PAMTargetDetailModel {
	groups: PAMTargetGroupModel[];
	useExcaliburCredentials: boolean;
	/** @format double */
	port: number;
	hostname: string;
	description?: string;
	name: string;
	type: EPAMTargetType;
	/** @format double */
	id: number;
}

export interface PagedResultPAMTargetDetailModel {
	/** @format double */
	total: number;
	entities: PAMTargetDetailModel[];
}

export type GetPAMTargetDetailsResponseDto = PagedResultPAMTargetDetailModel;

export type GetPAMTargetResponseDto = PAMTargetModel;

export type GetPAMTargetDetailResponseDto = PAMTargetDetailModel;

export interface PamSessionDto {
	recordingPath?: string;
	endAt: string | null;
	startAt: string;
	record: string;
	accountName: string;
	userSurname: string;
	userName: string;
	/** @format double */
	userID: number;
	targetName: string;
	targetType: string;
	/** @format double */
	id: number;
}

export interface PagedResultPamSessionDto {
	/** @format double */
	total: number;
	entities: PamSessionDto[];
}

export type GetPAMTargetSessionsResponseDto = PagedResultPamSessionDto;

/**
 * Represents a value that can be of a specified type or null.
 * @format double
 */
export type NullableNumber = number | null;

export interface GetUserResponseDto {
	countryCode?: string | null;
	/** Represents a value that can be of a specified type or null. */
	tenantID: NullableNumber;
	lastLoggedAt?: string;
	createdAt: string;
	status: string;
	phoneNumber?: string | null;
	email: string;
	surname?: string;
	name: string;
	uuid: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetUserResponseDto {
	/** @format double */
	total: number;
	entities: GetUserResponseDto[];
}

export type GetPAMTargetUsersResponseDto = PagedResultGetUserResponseDto;

export interface VerifyPAMTargetResponseDto {
	inRange: boolean;
	reachable: boolean;
}

export interface VerifyPAMTargetRequestDto {
	/** @format double */
	port: number;
	networkAddress: string;
	/** @format double */
	targetID: number | null;
	/** @format double */
	tenantID: number | null;
}

export interface PAMTargetAuditLogModel {
	remoteApplicationCommandLineArgs?: string | null;
	remoteApplicationWorkingDir?: string | null;
	remoteApplicationName?: string | null;
	ignoreCertificate?: boolean | null;
	securityMode?: string | null;
	domain?: string | null;
	passphrase?: string | null;
	privateKey?: string | null;
	modifiedBy?: string | null;
	modifiedAt: string;
	/** @format double */
	fileUploadSizeLimit?: number | null;
	fileUploadEnabled?: boolean | null;
	fileDownloadEnabled?: boolean | null;
	fileTransferRootDirectory?: string | null;
	clipboardEnablePaste?: boolean | null;
	clipboardEnableCopy?: boolean | null;
	/** @format double */
	recordingRetentionPeriod?: number | null;
	recordingEnabled?: boolean | null;
	password?: string | null;
	username?: string | null;
	/** @format double */
	port?: number | null;
	hostname?: string | null;
	description?: string | null;
	name?: string | null;
	/** @format double */
	tenantID?: number | null;
	type?: string | null;
	/** @format double */
	targetID: number;
	/** @format double */
	revType: number;
	/** @format double */
	revID: number;
}

export interface PagedResultPAMTargetAuditLogModel {
	/** @format double */
	total: number;
	entities: PAMTargetAuditLogModel[];
}

export type GetPAMTargetAuditLogsResponseDto = PagedResultPAMTargetAuditLogModel;

export interface CreatePAMRDPTargetResponseDto {
	/**
	 * ID of the created RDP target
	 * @format double
	 */
	id: number;
}

export interface CreatePAMTargetBase {
	additionalArguments: {
		value: string;
		key: string;
	}[];
	clipboard: {
		enablePaste: boolean;
		enableCopy: boolean;
	};
	fileTransfer: {
		/** @format double */
		uploadFileSizeLimit?: number;
		rootDirectory?: string;
		downloadEnabled: boolean;
		uploadEnabled: boolean;
	};
	/** Recordings settings for the PAM target */
	recording: {
		/** Represents a value that can be of a specified type or null. */
		retentionPeriod: NullableNumber;
		enabled: boolean;
	};
	/**
	 * Use managed credentials for the PAM target
	 * @example "true"
	 */
	useExcaliburCredentials: boolean;
	/**
	 * Username to use for the PAM target
	 * @example "admin"
	 */
	username: string;
	/**
	 * Port of the PAM target
	 * @format double
	 * @example 3389
	 */
	port: number;
	/**
	 * Hostname of the PAM target
	 * @example "ad-rodc-1.example.com"
	 */
	hostname: string;
	/**
	 * PAM target description
	 * @example "Active Directory Read-Only Domain Controller 1"
	 */
	description?: string;
	/**
	 * PAM target name
	 * @example "AD RODC 1"
	 */
	name: string;
}

export enum ERDPSecurityMode {
	Any = 'any',
	Nla = 'nla',
	NlaExt = 'nla-ext',
	Tls = 'tls',
	Wmconnect = 'wmconnect',
	Rdp = 'rdp',
}

export type CreatePAMRDPTargetRequestDto = CreatePAMTargetBase & {
	remoteApplication?: {
		commandLineArguments: string;
		workingDirectory: string;
		name: string;
	};
	ignoreCertificate: boolean;
	security: ERDPSecurityMode;
	password: string;
	username: string;
	domain?: string;
};

/** From T, pick a set of properties whose keys are in the union K */
export interface PickCreatePAMRDPTargetRequestDtoExcludeKeysPassword {
	/**
	 * PAM target name
	 * @example "AD RODC 1"
	 */
	name: string;
	/**
	 * PAM target description
	 * @example "Active Directory Read-Only Domain Controller 1"
	 */
	description?: string;
	/**
	 * Port of the PAM target
	 * @format double
	 * @example 3389
	 */
	port: number;
	/**
	 * Username to use for the PAM target
	 * @example "admin"
	 */
	username: string;
	security: ERDPSecurityMode;
	/**
	 * Hostname of the PAM target
	 * @example "ad-rodc-1.example.com"
	 */
	hostname: string;
	/**
	 * Use managed credentials for the PAM target
	 * @example "true"
	 */
	useExcaliburCredentials: boolean;
	/** Recordings settings for the PAM target */
	recording: {
		/** @format double */
		retentionPeriod: number;
		enabled: boolean;
	};
	fileTransfer: {
		/** @format double */
		uploadFileSizeLimit?: number;
		rootDirectory?: string;
		downloadEnabled: boolean;
		uploadEnabled: boolean;
	};
	clipboard: {
		enablePaste: boolean;
		enableCopy: boolean;
	};
	additionalArguments: {
		value: string;
		key: string;
	}[];
	domain?: string;
	ignoreCertificate: boolean;
	remoteApplication?: {
		commandLineArguments: string;
		workingDirectory: string;
		name: string;
	};
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitCreatePAMRDPTargetRequestDtoPassword = PickCreatePAMRDPTargetRequestDtoExcludeKeysPassword;

export type GetPAMRDPTargetResponseDto = OmitCreatePAMRDPTargetRequestDtoPassword & {
	/** @format double */
	id: number;
};

export type GetPAMRDPTargetsResponseDto = GetPAMRDPTargetResponseDto[];

export interface UpdatePAMRDPTargetRequestDto {
	additionalArguments?: {
		value?: string;
		key?: string;
	}[];
	remoteApplication?: {
		commandLineArguments?: string;
		workingDirectory?: string;
		name?: string;
	};
	clipboard?: {
		enablePaste?: boolean;
		enableCopy?: boolean;
	};
	fileTransfer?: {
		/** @format double */
		uploadFileSizeLimit?: number;
		downloadEnabled?: boolean;
		uploadEnabled?: boolean;
	};
	recording?: {
		/** Represents a value that can be of a specified type or null. */
		retentionPeriod?: NullableNumber;
		enabled?: boolean;
	};
	password?: string;
	username?: string;
	domain?: string;
	useExcaliburCredentials: boolean;
	ignoreCertificate?: boolean;
	security?: ERDPSecurityMode;
	/** @format double */
	port?: number;
	hostname?: string;
	description?: string;
	name: string;
}

export interface CreatePAMSessionResponseDto {
	/** @format double */
	id: number;
}

export interface CreatePAMSessionRequestDto {
	/**
	 * The height of the window.
	 * @format double
	 * @example 768
	 */
	height: number;
	/**
	 * The width of the window.
	 * @format double
	 * @example 1024
	 */
	width: number;
	/**
	 * The ID of the PAM target to start a session for.
	 * @format double
	 * @example 1
	 */
	targetID: number;
}

/** Make all properties in T optional */
export interface PartialPamSessionDto {
	/** @format double */
	id?: number;
	targetType?: string;
	targetName?: string;
	/** @format double */
	userID?: number;
	userName?: string;
	userSurname?: string;
	accountName?: string;
	record?: string;
	startAt?: string;
	endAt?: string;
	recordingPath?: string;
}

export interface PagedResultPartialPamSessionDto {
	/** @format double */
	total: number;
	entities: PartialPamSessionDto[];
}

export interface PAMUserInputDto {
	endAt: string;
	startAt: string;
	line: string;
	/** @format double */
	sessionID: number;
}

export interface PAMUserInputSessionDto {
	recordingPath?: string;
	userInput: PAMUserInputDto[];
	endAt: string | null;
	startAt: string;
	accountName: string;
	userSurname: string;
	userName: string;
	/** @format double */
	userID: number;
	targetName: string;
	targetType: string;
	/** @format double */
	id: number;
}

export interface PagedResultPAMUserInputSessionDto {
	/** @format double */
	total: number;
	entities: PAMUserInputSessionDto[];
}

export enum EFileTransferType {
	Upload = 'upload',
	Download = 'download',
}

export interface FileTransferDto {
	/** @format double */
	fileSize: number;
	createdAt: string;
	type: EFileTransferType;
	fileExtension: string;
	fileName: string;
	/** @format double */
	id: number;
}

export interface PagedResultFileTransferDto {
	/** @format double */
	total: number;
	entities: FileTransferDto[];
}

export interface PAMTargetInfoDto {
	createdAt: string;
	type: EPAMTargetType;
	/** @format double */
	uploadFileSizeLimit?: number;
	fileDownloadEnabled: boolean;
	fileUploadEnabled: boolean;
	clipboardEnablePaste: boolean;
	clipboardEnableCopy: boolean;
	/** Represents a value that can be of a specified type or null. */
	recordingRetentionPeriod: NullableNumber;
	recordingEnabled: boolean;
	username: string;
	/** @format double */
	port: number;
	hostname: string;
	description: string;
	name: string;
	/** @format double */
	sessionID: number;
	/** @format double */
	id: number;
}

export interface CreatePAMSSHTargetResponseDto {
	/**
	 * ID of created SSH target
	 * @format double
	 */
	id: number;
}

export type CreatePAMSSHTargetRequestDto = CreatePAMTargetBase & {
	/**
	 * Passphrase for the private key to successfully authenticate to the SSH target
	 * @example "P4sSW0rD"
	 */
	passphrase?: string;
	/**
	 * Private key to successfully authenticate to the SSH target
	 * @example "-----BEGIN OPENSSH..."
	 */
	privateKey?: string;
	/**
	 * Password to successfully authenticate to the SSH target
	 * @example "P4sSW0rD"
	 */
	password?: string;
};

export type GetPAMSSHTargetResponseDto = CreatePAMTargetBase & {
	/** @format double */
	id: number;
};

export interface UpdatePAMSSHTargetRequestDto {
	additionalArguments?: {
		value?: string;
		key?: string;
	}[];
	clipboard?: {
		enablePaste?: boolean;
		enableCopy?: boolean;
	};
	fileTransfer?: {
		/** @format double */
		uploadFileSizeLimit?: number;
		rootDirectory?: string;
		downloadEnabled?: boolean;
		uploadEnabled?: boolean;
	};
	recording?: {
		/** Represents a value that can be of a specified type or null. */
		retentionPeriod?: NullableNumber;
		enabled?: boolean;
	};
	passphrase?: string;
	privateKey?: string;
	password?: string;
	username?: string;
	/** @format double */
	port?: number;
	hostname?: string;
	useExcaliburCredentials: boolean;
	description?: string;
	name?: string;
}

export interface CreatePAMTargetGroupResponseDto {
	/** @format double */
	id: number;
}

export interface CreatePAMTargetGroupDto {
	borderColor?: string;
	bgColor?: string;
	textColor?: string;
	description?: string;
	name: string;
}

export interface PAMTargetSummaryModel {
	description?: string;
	type: EPAMTargetType;
	name: string;
	/** @format double */
	id: number;
}

export type PAMTargetGroupDetailModel = PAMTargetGroupModel & {
	targets: PAMTargetSummaryModel[];
};

export interface PagedResultPAMTargetGroupDetailModel {
	/** @format double */
	total: number;
	entities: PAMTargetGroupDetailModel[];
}

export type GetPAMTargetGroupDetailsResponseDto = PagedResultPAMTargetGroupDetailModel;

export type GetPAMTargetGroupResponseDto = PAMTargetGroupModel;

export type GetPAMTargetGroupDetailResponseDto = PAMTargetGroupDetailModel;

export interface PagedResultPAMTargetGroupModel {
	/** @format double */
	total: number;
	entities: PAMTargetGroupModel[];
}

export type GetPAMTargetGroupsResponseDto = PagedResultPAMTargetGroupModel;

/** Make all properties in T optional */
export interface PartialCreatePAMTargetGroupDto {
	name?: string;
	description?: string;
	textColor?: string;
	bgColor?: string;
	borderColor?: string;
}

export type UpdatePAMTargetGroupDto = PartialCreatePAMTargetGroupDto;

export interface PermissionGroupModel {
	/** Group permissions */
	permissions: PermissionModel[];
	/** Permission group title */
	title: string;
	/** Permission group description */
	description: string | null;
	/** Permission group name */
	name: string;
	/**
	 * Permission group ID
	 * @format double
	 * @example 1
	 */
	id: number;
}

export interface PagedResultPermissionGroupModel {
	/** @format double */
	total: number;
	entities: PermissionGroupModel[];
}

export type GetPermissionsResponseDto = PagedResultPermissionGroupModel;

export interface CreateRoleResponseDto {
	/**
	 * Role unique identifier
	 * @format double
	 * @example 123
	 */
	id: number;
}

export interface CreateRoleRequestDto {
	permissions: number[];
	description?: string;
	name: string;
}

export interface GetRoleResponseDto {
	permissions: number[];
	isBuiltIn: boolean;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface GetRoleDetailResponseDto {
	permissions: PermissionModel[];
	isBuiltIn: boolean;
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetRoleResponseDto {
	/** @format double */
	total: number;
	entities: GetRoleResponseDto[];
}

export type GetRolesResponseDto = PagedResultGetRoleResponseDto;

export interface PagedResultGetRoleDetailResponseDto {
	/** @format double */
	total: number;
	entities: GetRoleDetailResponseDto[];
}

export type GetRoleDetailsResponseDto = PagedResultGetRoleDetailResponseDto;

/** Make all properties in T optional */
export interface PartialCreateRoleRequestDto {
	name?: string;
	description?: string;
	permissions?: number[];
}

export type UpdateRoleRequestDto = PartialCreateRoleRequestDto;

export interface InvitationModel {
	/**
	 * Invitation tenant ID
	 * @example 1234
	 */
	tenantID: NullableNumber;
	/** The email address of the user that the invitation is for. */
	email: string;
	/** The unique identifier of the invitation. */
	uuid: string;
	/**
	 * The unique identifier of the user that created the invitation.
	 * @format double
	 * @example 1234
	 */
	createdBy: number;
	/** The date and time when the invitation will expire. */
	expiresAt: string;
	/** The date and time when the invitation was created. */
	createdAt: string;
	/**
	 * The number of failed attempts to accept the invitation.
	 * @format double
	 * @example 0
	 */
	failedAttempts: number;
	/** The status of the invitation. */
	status: EInvitationStatus;
	/**
	 * The unique identifier of the user that the invitation is for.
	 * @format double
	 * @example 5678
	 */
	userID: number;
	/**
	 * Identity store ID.
	 * @format double
	 * @example 1234
	 */
	identityStoreID: number;
	/**
	 * The unique identifier of the invitation.
	 * @format double
	 * @example 1234
	 */
	id: number;
}

export type CreateInvitationResponseDto = InvitationModel[];

export interface CreateInvitationRequestDto {
	userGroupsIDs: number[];
	/** @format double */
	identityStoreID: number;
	uuids: string[];
}

/** Make all properties in T optional */
export interface PartialInvitationModel {
	/**
	 * The unique identifier of the invitation.
	 * @format double
	 * @example 1234
	 */
	id?: number;
	/**
	 * Identity store ID.
	 * @format double
	 * @example 1234
	 */
	identityStoreID?: number;
	/**
	 * The unique identifier of the user that the invitation is for.
	 * @format double
	 * @example 5678
	 */
	userID?: number;
	/** The status of the invitation. */
	status?: EInvitationStatus;
	/**
	 * The number of failed attempts to accept the invitation.
	 * @format double
	 * @example 0
	 */
	failedAttempts?: number;
	/** The date and time when the invitation was created. */
	createdAt?: string;
	/** The date and time when the invitation will expire. */
	expiresAt?: string;
	/**
	 * The unique identifier of the user that created the invitation.
	 * @format double
	 * @example 1234
	 */
	createdBy?: number;
	/** The unique identifier of the invitation. */
	uuid?: string;
	/** The email address of the user that the invitation is for. */
	email?: string;
	/**
	 * Invitation tenant ID
	 * @format double
	 * @example 1234
	 */
	tenantID?: number;
}

export interface PagedResultPartialInvitationModel {
	/** @format double */
	total: number;
	entities: PartialInvitationModel[];
}

export interface CreateUsersResponseDto {
	/**
	 * The unique identifiers of the created users.
	 * @example [1234,5678]
	 */
	ids: number[];
}

export interface CreateUserRequestDto {
	countryCode?: string;
	phoneNumber?: string;
	email: string;
	surname?: string;
	name: string;
	uuid?: string;
}

export type CreateUsersRequestDto = CreateUserRequestDto[];

export interface GroupDto {
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface UserDetailModel {
	groups: GroupDto[];
	createdAt: string;
	lastLoggedAt?: string;
	status: string;
	email: string;
	title?: string;
	surname?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultUserDetailModel {
	/** @format double */
	total: number;
	entities: UserDetailModel[];
}

export type GetUserDetailsResponseDto = PagedResultUserDetailModel;

/** Represents a value that can be of a specified type or null. */
export type NullableString = string | null;

export interface GetMeResponseDto {
	countryCode?: string | null;
	/** Represents a value that can be of a specified type or null. */
	tenantSlug: NullableString;
	/** Represents a value that can be of a specified type or null. */
	tenantID: NullableNumber;
	lastLoggedAt?: string;
	createdAt: string;
	status: string;
	phoneNumber?: string | null;
	email: string;
	surname?: string;
	name: string;
	uuid: string;
	/** @format double */
	id: number;
}

export enum ETenantStatus {
	Pending = 'pending',
	InProgress = 'in_progress',
	Completed = 'completed',
	Failed = 'failed',
}

/** Make all properties in T optional */
export interface PartialTenantModel {
	/**
	 * The id of the tenant
	 * @format double
	 * @example 1234
	 */
	id?: number;
	/** The name of the tenant */
	name?: string;
	/** The alias for the tenant name that is used in the URLs */
	slug?: string;
	/** The description of the tenant */
	description?: string;
	/**
	 * Date the tenant was created
	 * @format date-time
	 */
	createdAt?: string;
	/**
	 * Date the tenant was last updated
	 * @format date-time
	 */
	updatedAt?: string;
	/** The status of the tenant creation process */
	status?: ETenantStatus;
	/** Error message if the tenant creation process failed */
	errorLog?: string;
	/** Whether the tenant is enabled */
	enabled?: boolean;
	/** The IP ranges that the tenant is allowed to access */
	networkAddresses?: string[];
}

export interface PagedResultPartialTenantModel {
	/** @format double */
	total: number;
	entities: PartialTenantModel[];
}

export enum EPermission {
	All = 'all',
	UsersReadAll = 'users_read_all',
	UsersReadSubordinates = 'users_read_subordinates',
	UsersDelete = 'users_delete',
	UsersAuditLogsReadAll = 'users_audit_logs_read_all',
	UsersAuditLogsReadOwn = 'users_audit_logs_read_own',
	UsersAuditLogsReadSubordinates = 'users_audit_logs_read_subordinates',
	InvitationsRead = 'invitations_read',
	InvitationsCreate = 'invitations_create',
	InvitationsUpdate = 'invitations_update',
	InvitationsDelete = 'invitations_delete',
	UserGroupsRead = 'user_groups_read',
	UserGroupsReadOwn = 'user_groups_read_own',
	UserGroupsCreate = 'user_groups_create',
	UserGroupsUpdate = 'user_groups_update',
	UserGroupsDelete = 'user_groups_delete',
	PamTargetsCreate = 'pam_targets_create',
	PamTargetsReadAll = 'pam_targets_read_all',
	PamTargetsReadOwn = 'pam_targets_read_own',
	PamTargetsUpdate = 'pam_targets_update',
	PamTargetsDelete = 'pam_targets_delete',
	PamSessionsCreate = 'pam_sessions_create',
	PamSessionsReadAll = 'pam_sessions_read_all',
	PamSessionsReadOwn = 'pam_sessions_read_own',
	PamRecordingsRead = 'pam_recordings_read',
	PamGroupsCreate = 'pam_groups_create',
	PamGroupsRead = 'pam_groups_read',
	PamGroupsUpdate = 'pam_groups_update',
	PamGroupsDelete = 'pam_groups_delete',
	PamTargetAuditLogsReadAll = 'pam_target_audit_logs_read_all',
	PamTargetAuditLogsReadOwn = 'pam_target_audit_logs_read_own',
	PamTextSearchReadAll = 'pam_text_search_read_all',
	PamTextSearchReadOwn = 'pam_text_search_read_own',
	IdentityStoresRead = 'identity_stores_read',
	IdentityStoresCreate = 'identity_stores_create',
	IdentityStoresUpdate = 'identity_stores_update',
	IdentityStoresDelete = 'identity_stores_delete',
	TenantsRead = 'tenants_read',
	TenantsCreate = 'tenants_create',
	TenantsUpdate = 'tenants_update',
	TenantsDelete = 'tenants_delete',
	GeofencesRead = 'geofences_read',
	GeofencesUpdate = 'geofences_update',
	GeofencesCreate = 'geofences_create',
	GeofencesDelete = 'geofences_delete',
	OauthClientsCreate = 'oauth_clients_create',
	OauthClientsRead = 'oauth_clients_read',
	OauthClientsUpdate = 'oauth_clients_update',
	OauthClientsDelete = 'oauth_clients_delete',
	RolesCreate = 'roles_create',
	RolesRead = 'roles_read',
	RolesUpdate = 'roles_update',
	RolesDelete = 'roles_delete',
	SecurityPoliciesRead = 'security_policies_read',
	SecurityPoliciesCreate = 'security_policies_create',
	SecurityPoliciesUpdate = 'security_policies_update',
	SecurityPoliciesDelete = 'security_policies_delete',
	SmtpRead = 'smtp_read',
	SmtpCreate = 'smtp_create',
	SmtpUpdate = 'smtp_update',
	SmtpDelete = 'smtp_delete',
	GeofenceGroupsRead = 'geofence_groups_read',
	GeofenceGroupsUpdate = 'geofence_groups_update',
	GeofenceGroupsCreate = 'geofence_groups_create',
	GeofenceGroupsDelete = 'geofence_groups_delete',
	SettingsMapRead = 'settings_map_read',
	SettingsMapUpdate = 'settings_map_update',
	SettingsGeneralRead = 'settings_general_read',
	SettingsGeneralUpdate = 'settings_general_update',
	TokensReadAll = 'tokens_read_all',
	TokensReadOwn = 'tokens_read_own',
	TokensDeleteAll = 'tokens_delete_all',
	TokensDeleteOwn = 'tokens_delete_own',
	ActionsReadAll = 'actions_read_all',
	ActionsReadOwn = 'actions_read_own',
	NetworkPolicyReadAll = 'network_policy_read_all',
	NetworkPolicyDeleteAll = 'network_policy_delete_all',
	NetworkPolicyCreate = 'network_policy_create',
	NetworkPolicyUpdate = 'network_policy_update',
}

export interface GetMeRoleModelDto {
	tenantPermissions: string[];
	systemPermissions: string[];
}

export interface UserAuditLogModel {
	/** @format double */
	modifiedBy?: number | null;
	modifiedAt: string;
	userGroupName?: string | null;
	/** @format double */
	userGroupID?: number | null;
	lastLoggedAt?: string | null;
	/** @format double */
	certificateID?: number | null;
	status?: string | null;
	email?: string | null;
	surname?: string | null;
	name?: string | null;
	title?: string | null;
	uuid?: string | null;
	identityStoreName?: string | null;
	/** @format double */
	identityStoreID?: number | null;
	tenantName?: string | null;
	/** @format double */
	tenantID?: number | null;
	/** @format double */
	userID?: number | null;
	/** @format double */
	revType: number;
	/** @format double */
	revID: number;
}

export interface PagedResultUserAuditLogModel {
	/** @format double */
	total: number;
	entities: UserAuditLogModel[];
}

export type GetUsersAuditLogsResponseDto = PagedResultUserAuditLogModel;

/** Make all properties in T optional */
export interface PartialGetUserResponseDto {
	/** @format double */
	id?: number;
	uuid?: string;
	name?: string;
	surname?: string;
	email?: string;
	phoneNumber?: string;
	status?: string;
	createdAt?: string;
	lastLoggedAt?: string;
	/** @format double */
	tenantID?: number;
	countryCode?: string;
}

export interface UserGroupDto {
	/** @format date-time */
	updatedAt?: string;
	/** @format date-time */
	createdAt?: string;
	/** @format double */
	totalUsers?: number;
	description?: string;
	name: string;
	isBuiltIn: boolean;
	/** @format double */
	id: number;
}

export interface PagedResultPartialGetUserResponseDto {
	/** @format double */
	total: number;
	entities: PartialGetUserResponseDto[];
}

export type GetUsersResponseDto = PagedResultPartialGetUserResponseDto;

export interface CreateUserGroupResponseDto {
	/** @format double */
	id: number;
}

export interface CreateUserGroupRequestDto {
	description?: string;
	name: string;
}

export interface UpdateUserGroupDto {
	description?: string;
	name?: string;
}

export interface UpdateUserGroupRequestDto {
	description?: string;
	name?: string;
}

export interface PagedResultUserGroupDto {
	/** @format double */
	total: number;
	entities: UserGroupDto[];
}

export type GetUserGroupResponseDto = PagedResultUserGroupDto;

export interface PhoneNumberDto {
	countryCode: string;
	phoneNumber: string;
	/** @format double */
	id: number;
}

export enum EUserStatus {
	Enabled = 'enabled',
	Verified = 'verified',
	Disabled = 'disabled',
}

export interface UserDetailDto {
	groups: GroupDto[];
	/** @format double */
	certificateID?: number;
	/** @format date-time */
	createdAt: string;
	/** @format date-time */
	lastLoggedAt?: string;
	status: EUserStatus;
	phoneNumberList: PhoneNumberDto[];
	email: string;
	surname?: string;
	title?: string;
	name: string;
	/** @format double */
	tenantID?: number;
	uuid: string;
	/** @format double */
	id: number;
}

export interface PagedResultUserDetailDto {
	/** @format double */
	total: number;
	entities: UserDetailDto[];
}

export type GetUsersInGroupResponseDto = PagedResultUserDetailDto;

export interface UserInGroupModel {
	groups: GroupDto[];
	title?: string;
	email: string;
	surname?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultUserInGroupModel {
	/** @format double */
	total: number;
	entities: UserInGroupModel[];
}

export type GetUserDetailsInGroupResponseDto = PagedResultUserInGroupModel;

export interface ClientVersionsDto {
	windows: Record<string, number>;
	linux: Record<string, number>;
	macos: Record<string, number>;
}

export type ClientSystemVersionsDto = ClientVersionsDto;

export interface DeviceCountDto {
	/** @format double */
	total: number;
	/** @format double */
	offline: number;
	/** @format double */
	online: number;
}

export type ClientCountDto = DeviceCountDto;

/** Make all properties in T optional */
export interface PartialClientTableRowDto {
	/**
	 * Client ID
	 * @format double
	 */
	id?: number;
	/** Unique device HW identifier */
	uid?: string;
	/** Token name */
	name?: string;
	/** Platform windows, linux, macos */
	platform?: string;
	/** System */
	system?: string;
	/** Token application version */
	version?: string;
	/** Device certificate for signature verification */
	certificate?: string;
	/**
	 * Date-time when token was last connected.
	 * ISO 8601 format
	 */
	lastConnectedAt?: string;
	/**
	 * Date-time when token entity was created.
	 * ISO 8601 format
	 */
	createdAt?: string;
}

export interface PagedResultPartialClientTableRowDto {
	/** @format double */
	total: number;
	entities: PartialClientTableRowDto[];
}

export interface GetDateResponseDto {
	/**
	 * Timestamp in milliseconds
	 * @format double
	 * @example 1629878400000
	 */
	ts: number;
	/** Date in ISO 8601 format */
	date: string;
}

export interface CertificateInfoDto {
	issuer: string;
	subject: string;
	validity: {
		/** @format date-time */
		notAfter: string;
		/** @format date-time */
		notBefore: string;
	};
	signatureAlgorithm: string;
	fingerprint: string;
	content: string;
}

export enum EAppStatus {
	DEPOYMENT_SETUP = 'DEPOYMENT_SETUP',
	PRODUCTION = 'PRODUCTION',
	DEVELOPMENT = 'DEVELOPMENT',
}

export interface SaveSetupProgressDto {
	step: string;
}

export enum ESetupSteps {
	SERVER_SETUP = 'SERVER_SETUP',
	CSR = 'CSR',
	SMTP = 'SMTP',
	SMTP_VERIFY = 'SMTP_VERIFY',
	IDENTITY_STORE = 'IDENTITY_STORE',
	IDENTITY_STORE_AUTHENTICATION = 'IDENTITY_STORE_AUTHENTICATION',
	DEPLOYMENT_FINALIZATION = 'DEPLOYMENT_FINALIZATION',
	SETUP_COMPLETED = 'SETUP_COMPLETED',
}

export interface CreateCSRDto {
	licenseKey: string;
	licenseFile: string;
}

export interface CreateSMTPMailConfigurationDto {
	active?: boolean;
	security: EMailSMTPSecurity;
	email: string;
	sender: string;
	password?: string;
	username?: string;
	/** @format double */
	port: number;
	host: string;
	description?: string;
	name: string;
}

/** Represents a value that can be of a specified type or null. */
export type NullableReadSMTPMailConfigurationResponse = ReadSMTPMailConfigurationResponse | null;

export interface ServerSetupDto {
	hostname: string;
	tokenUrl?: string;
	timezone: string;
}

export interface AuthenticateIdentityStoreDto {
	username: string;
	password: string;
}

export interface TenantModel {
	/** The IP ranges that the tenant is allowed to access */
	networkAddresses?: string[];
	/** Whether the tenant is enabled */
	enabled?: boolean;
	/** Error message if the tenant creation process failed */
	errorLog?: string;
	/** The status of the tenant creation process */
	status: ETenantStatus;
	/**
	 * Date the tenant was last updated
	 * @format date-time
	 */
	updatedAt: string;
	/**
	 * Date the tenant was created
	 * @format date-time
	 */
	createdAt: string;
	/** The description of the tenant */
	description?: string;
	/** The alias for the tenant name that is used in the URLs */
	slug: string;
	/** The name of the tenant */
	name: string;
	/**
	 * The id of the tenant
	 * @format double
	 * @example 1234
	 */
	id: number;
}

export interface CreateTenantResponseDto {
	/** @format double */
	id: number;
}

export interface CreateTenantRequestDto {
	/** Alias for tenant name that can be used in urls */
	slug: string;
	/** Optional ip ranges */
	networkAddresses?: string[];
	/** Optional description of the created tenant */
	description?: string;
	/** The name of the tenant to create */
	name: string;
}

export interface UpdateTenantRequestDto {
	networkAddresses?: string[];
	description?: string;
	name?: string;
}

export interface RetryCreateTenantDto {
	/** @format double */
	tenantID: number;
}

export interface TokenDetailDto {
	createdAt: string;
	systemVersion: string;
	systemName: string;
	model: string;
	brand: string;
	deviceName: string;
	userSurname?: string;
	userName: string;
	userTitle?: string;
	/** @format double */
	userID: number;
	/** @format double */
	id: number;
}

export interface PagedResultTokenDetailDto {
	/** @format double */
	total: number;
	entities: TokenDetailDto[];
}

export type GetTokenDetailResponseDto = PagedResultTokenDetailDto;

/** Make all properties in T optional */
export interface PartialTokenTableRowDto {
	/**
	 * Token ID
	 * @format double
	 */
	id?: number;
	/**
	 * ID of user which token belongs to
	 * @format double
	 */
	userID?: number;
	/** Unique device HW identifier */
	udid?: string;
	/** Token name */
	name?: string;
	/**
	 * Device platform
	 *
	 * (e.g. "Apple", "Samsung", "Xiaomi")
	 */
	brand?: string;
	/**
	 * Device model
	 *
	 * (e.g. "iPhone 12 Pro", "Galaxy S21")
	 */
	model?: string;
	/**
	 * Device OS name
	 *
	 * (e.g. "iOS", "Android")
	 */
	systemName?: string;
	/**
	 * Device OS version
	 *
	 * (e.g. "14.5", "11.0")
	 */
	systemVersion?: string;
	/** Token application version */
	appVersion?: string;
	/** Token PushID */
	pushID?: string;
	/** Device certificate for signature verification */
	certificate?: string;
	/**
	 * Date-time when token was last connected.
	 * ISO 8601 format
	 */
	lastConnectedAt?: string;
	/**
	 * Date-time when token entity was created.
	 * ISO 8601 format
	 */
	createdAt?: string;
}

/** Make all properties in T optional */
export interface PartialTokenModel {
	/**
	 * Token ID
	 * @format double
	 */
	id?: number;
	/**
	 * ID of user which token belongs to
	 * @format double
	 */
	userID?: number;
	/** Unique device HW identifier */
	uid?: string;
	/** Token name */
	name?: string;
	/** Token platform */
	platform?: string;
	/** System */
	system?: string;
	/** Token application version */
	version?: string;
	/**
	 * Date-time when token was last connected.
	 * ISO 8601 format
	 */
	lastConnectedAt?: string;
	/**
	 * Date-time when token entity was created.
	 * ISO 8601 format
	 */
	createdAt?: string;
}

export interface PagedResultPartialTokenModel {
	/** @format double */
	total: number;
	entities: PartialTokenModel[];
}

export interface TokenVersionsDto {
	android: Record<string, number>;
	ios: Record<string, number>;
	windows: Record<string, number>;
	ipados: Record<string, number>;
}

export type TokenSystemVersionsDto = TokenVersionsDto;

export type TokenCountDto = DeviceCountDto;

export interface CreateSecurityPolicyResponseDto {
	/** @format double */
	id: number;
}

export interface CreateSecurityPolicyRequestDto {
	ruleSets: number[];
	pamTargetGroups: number[];
	userGroups: number[];
	description?: string;
	name: string;
}

export interface SecurityPolicyModel {
	description?: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultSecurityPolicyModel {
	/** @format double */
	total: number;
	entities: SecurityPolicyModel[];
}

export type GetSecurityPoliciesResponseDto = PagedResultSecurityPolicyModel;

export interface PagedResultGroupDto {
	/** @format double */
	total: number;
	entities: GroupDto[];
}

export type GetGroupResponseDto = PagedResultGroupDto;

export interface RuleSetGeoModel {
	/** @format double */
	radius: number;
	/** @format double */
	longitude: number;
	/** @format double */
	latitude: number;
	name: string;
	/** @format double */
	id: number;
}

export interface RuleSetGeoGroupModel {
	geofences: RuleSetGeoModel[];
	name: string;
	/** @format double */
	id: number;
}

export interface GetRuleSetResponseDto {
	/** @format double */
	securityPoliciesAllCount: number;
	securityPolicies: {
		/** @format double */
		id: number;
		name: string;
	}[];
	/** @format double */
	tenantID: number | null;
	timeRange?: {
		end: string;
		start: string;
	};
	timeRanges: {
		to: string;
		from: string;
	}[];
	ips: string[];
	phoneConnectionStatuses: string[];
	phoneConnectionIPs: {
		to: string;
		from: string;
	}[];
	days: string[];
	name: string;
	geofences: RuleSetGeoModel[][];
	geofenceGroups: RuleSetGeoGroupModel[][];
	factors: string[][];
	description: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetRuleSetResponseDto {
	/** @format double */
	total: number;
	entities: GetRuleSetResponseDto[];
}

export type GetDetailedRuleSetsResponseDto = PagedResultGetRuleSetResponseDto;

export interface CreateRuleSetRequestDto {
	timeRange?: {
		end: string;
		start: string;
	};
	timeRanges: {
		to: string;
		from: string;
	}[];
	phoneConnectionStatuses: string[];
	ips: string[];
	phoneConnectionIPs: {
		to: string;
		from: string;
	}[];
	days: string[];
	name: string;
	geofences: number[][];
	geofenceGroups: number[][];
	factors: string[][];
	description: string;
}

export interface UpdateRuleSetRequestDto {
	timeRange?: {
		end: string;
		start: string;
	};
	timeRanges: {
		to: string;
		from: string;
	}[];
	phoneConnectionStatuses: string[];
	ips: string[];
	phoneConnectionIPs: {
		to: string;
		from: string;
	}[];
	days: string[];
	name: string;
	geofences: number[][];
	geofenceGroups: number[][];
	factors: string[][];
	description: string;
}

export interface RuleSetSecurityPolicyModel {
	name: string;
	/** @format double */
	id: number;
}

export interface GetRuleSetCollectionDto {
	/** @format double */
	securityPoliciesAllCount: number;
	securityPolicies: RuleSetSecurityPolicyModel[];
	/** @format double */
	tenantID: number | null;
	description: string;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultGetRuleSetCollectionDto {
	/** @format double */
	total: number;
	entities: GetRuleSetCollectionDto[];
}

export interface UpdateSystemPropertiesDto {
	hostname?: string;
	tokenUrl?: string;
	timezone?: string;
	googleKey?: string;
	enabledPlacesApi?: boolean;
	k8sOAuthUrl?: string;
	k8sOAuthUsername?: string;
	k8sOAuthPassword?: string;
	k8sAPIUrl?: string;
	k8sAPINamespace?: string;
}

/** Represents a value that can be of a specified type or null. */
export type NullableBoolean = boolean | null;

export interface SystemPropertiesResponseDto {
	/** Represents a value that can be of a specified type or null. */
	k8sAPINamespace: NullableString;
	/** Represents a value that can be of a specified type or null. */
	k8sAPIUrl: NullableString;
	/** Represents a value that can be of a specified type or null. */
	k8sOAuthUsername: NullableString;
	/** Represents a value that can be of a specified type or null. */
	k8sOAuthUrl: NullableString;
	/** Represents a value that can be of a specified type or null. */
	enabledPlacesApi: NullableBoolean;
	/** Represents a value that can be of a specified type or null. */
	googleApiKey: NullableString;
	timezone: string;
	/** Represents a value that can be of a specified type or null. */
	tokenUrl: NullableString;
	/** Represents a value that can be of a specified type or null. */
	hostname: NullableString;
}

export interface UpdateMapSettingsPropertiesDto {
	googleKey?: string;
	enabledPlacesApi?: boolean;
}

export interface MapSettingsPropertiesResponseDto {
	googleMapsdefaultApiKey: string;
	/** Represents a value that can be of a specified type or null. */
	enabledPlacesApi: NullableBoolean;
	/** Represents a value that can be of a specified type or null. */
	googleApiKey: NullableString;
}

export type InvitationExpirationValuesResponseDto = {
	/** @format double */
	value: number;
	key: string;
}[];

export type UpdateInvitationExpirationValuesResponseDto = {
	key: string;
}[];

export type UpdateInvitationExpirationValuesDto = {
	/** @format double */
	value: number;
	key: string;
}[];

export interface GetDeploymentCertificateDto {
	content: string;
	fingerprint: string;
	signatureAlgorithm: string;
	validity: {
		/** @format date-time */
		notAfter: string;
		/** @format date-time */
		notBefore: string;
	};
	subject: string;
	issuer: string;
}

export interface GetVersionsResponseDto {
	token: string;
	repository: string;
	pamOrchestrator: string;
	mailer: string;
	identityStore: string;
	hsm: string;
	core: string;
	client: string;
	ca: string;
	api: string;
}

export enum ETenantResourceType {
	Deployment = 'deployment',
	Service = 'service',
	AutoScaler = 'autoScaler',
	NetworkPolicy = 'networkPolicy',
	ConfigMap = 'configMap',
}

export interface TenantResourceDto {
	errorLog: string | null;
	createdAt: string;
	/** @format double */
	tenantID: number | null;
	status: ETenantStatus;
	type: ETenantResourceType;
	name: string;
	/** @format double */
	id: number;
}

export interface PagedResultTenantResourceDto {
	/** @format double */
	total: number;
	entities: TenantResourceDto[];
}

export type GetTenantResourcesResponseDto = PagedResultTenantResourceDto;

export enum EActionType {
	Registration = 'registration',
	Authentication = 'authentication',
}

export interface GetActionCollectionEntityDto {
	/** @format date-time */
	createdAt: string;
	status: string;
	location?: GeofenceModel;
	/** @format double */
	tokenID: number;
	targetName: string;
	/** @format double */
	targetID: number;
	/** @format double */
	targetType: number;
	accountName: string;
	userSurname: string;
	userName: string;
	useTitle?: string;
	/** @format double */
	userID: number;
	action: EActionType;
	/** @format double */
	id: number;
}

export interface PagedResultGetActionCollectionEntityDto {
	/** @format double */
	total: number;
	entities: GetActionCollectionEntityDto[];
}

export interface GetActionDetailDto {
	factors: string[];
	/** Represents a value that can be of a specified type or null. */
	policyID?: NullableNumber;
	phoneIP?: string;
	phoneConnectionStatus: string;
	/** @format date-time */
	createdAt: string;
	status: string;
	/** @format double */
	tokenID: number;
	location?: GeofenceModel;
	targetName: string;
	/** @format double */
	targetID: number;
	/** @format double */
	targetType: number;
	accountName: string;
	userSurname: string;
	userName: string;
	useTitle?: string;
	/** @format double */
	userID: number;
	action: EActionType;
	/** @format double */
	id: number;
}

export interface GetActionResultDto {
	/** @format date-time */
	createdAt: string;
	value: string;
	result: string;
	resultType: string;
	/** @format double */
	actionResultID: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/api/v1' });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title @excalibur-enterprise/excalibur-v4-api
 * @version 4.0.1-alpha.1
 * @license MIT
 * @baseUrl /api/v1
 * @contact Excalibur s.r.o.
 *
 * The Excalibur API service is a dedicated backend service designed to provide RESTful API endpoints for the Excalibur application. It handles tasks such as request serving, data validation, and authentication. While it doesn't contain the core business logic, it serves as a crucial link between the client applications and the underlying data models. The service is built with a focus on performance, security, and scalability, ensuring efficient and secure data exchange. It also includes features for error handling and logging, providing valuable insights for debugging and monitoring.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	auth = {
		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name GetUserInvitationDetail
		 * @request GET:/auth/invitation/{invitation}
		 */
		getUserInvitationDetail: (invitation: string, params: RequestParams = {}) =>
			this.request<UserInvitationDetailDto, void>({
				path: `/auth/invitation/${invitation}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name Register
		 * @request POST:/auth/register
		 */
		register: (data: RegisterRequestDto, params: RequestParams = {}) =>
			this.request<RegisterResponseDto, void>({
				path: `/auth/register`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name Session
		 * @request GET:/auth/session
		 * @secure
		 */
		session: (params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/auth/session`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name Login
		 * @request POST:/auth/login
		 */
		login: (data: LoginRequestDto, params: RequestParams = {}) =>
			this.request<LoginResponseDto, void>({
				path: `/auth/login`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name Registered
		 * @request GET:/auth/registered
		 */
		registered: (params: RequestParams = {}) =>
			this.request<
				{
					registered: boolean;
				},
				void
			>({
				path: `/auth/registered`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name TestLogin
		 * @request POST:/auth/testLogin
		 */
		testLogin: (
			data: {
				/** @format double */
				userID: number;
			},
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/auth/testLogin`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name RefreshToken
		 * @request POST:/auth/refresh
		 */
		refreshToken: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/auth/refresh`,
				method: 'POST',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name Logout
		 * @request POST:/auth/logout
		 * @secure
		 */
		logout: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/auth/logout`,
				method: 'POST',
				secure: true,
				...params,
			}),
	};
	authorize = {
		/**
		 * No description
		 *
		 * @tags Authorization
		 * @name Authorize
		 * @request GET:/authorize
		 * @secure
		 */
		authorize: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/authorize`,
				method: 'GET',
				secure: true,
				...params,
			}),
	};
	geofences = {
		/**
		 * @description Get geofences in system scope
		 *
		 * @tags Geofences
		 * @name GetSystemGeofences
		 * @summary Get system geofences
		 * @request GET:/geofences/system
		 * @secure
		 */
		getSystemGeofences: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetGeofencesResponseDto, void>({
				path: `/geofences/system`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new geofence based on information provided.
		 *
		 * @tags Geofences
		 * @name CreateGeofence
		 * @summary Create a new geofence
		 * @request POST:/geofences
		 * @secure
		 */
		createGeofence: (data: CreateGeofenceRequestDto, params: RequestParams = {}) =>
			this.request<CreateGeofenceResponseDto, any>({
				path: `/geofences`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get geofences
		 *
		 * @tags Geofences
		 * @name GetGeofences
		 * @summary Get geofences
		 * @request GET:/geofences
		 * @secure
		 */
		getGeofences: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetGeofencesResponseDto, void>({
				path: `/geofences`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple geofences by their IDs
		 *
		 * @tags Geofences
		 * @name DeleteGeofences
		 * @summary Delete multiple geofences by their IDs
		 * @request DELETE:/geofences
		 * @secure
		 */
		deleteGeofences: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/geofences`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofence information by ID
		 *
		 * @tags Geofences
		 * @name GetGeofence
		 * @summary Get geofence by ID
		 * @request GET:/geofences/{geofenceID}
		 * @secure
		 */
		getGeofence: (geofenceId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceResponseDto, void>({
				path: `/geofences/${geofenceId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a geofence based on provided ID and information.
		 *
		 * @tags Geofences
		 * @name UpdateGeofence
		 * @summary Update a geofence
		 * @request PATCH:/geofences/{geofenceID}
		 * @secure
		 */
		updateGeofence: (geofenceId: number, data: UpdateGeofenceRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofences/${geofenceId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a geofence by ID
		 *
		 * @tags Geofences
		 * @name DeleteGeofence
		 * @summary Delete a geofence by ID
		 * @request DELETE:/geofences/{geofenceID}
		 * @secure
		 */
		deleteGeofence: (geofenceId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofences/${geofenceId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get geofence groups by geofence ID
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroupsByGeofenceId
		 * @summary Get geofence groups by geofence ID
		 * @request GET:/geofences/{geofenceID}/groups
		 * @secure
		 */
		getGeofenceGroupsByGeofenceId: (geofenceId: number, params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/geofences/${geofenceId}/groups`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get geofence type collection
		 *
		 * @tags Geofence Types
		 * @name ReadGeofenceTypes
		 * @summary Get geofence type collection
		 * @request GET:/geofences/types
		 * @secure
		 */
		readGeofenceTypes: (
			query?: {
				/**
				 * The filter to apply
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sort order to apply
				 * @default []
				 */
				sort?: string[];
				/**
				 * The columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of items to take
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialGeofenceTypeDto, void>({
				path: `/geofences/types`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve geofence type information by ID
		 *
		 * @tags Geofence Types
		 * @name ReadGeofenceType
		 * @summary Get geofence type by ID
		 * @request GET:/geofences/types/{geofenceTypeID}
		 * @secure
		 */
		readGeofenceType: (geofenceTypeId: number, params: RequestParams = {}) =>
			this.request<PartialGeofenceTypeDto, void>({
				path: `/geofences/types/${geofenceTypeId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	tenants = {
		/**
		 * @description Get geofences in tenant scope
		 *
		 * @tags Geofences
		 * @name GetTenantGeofences
		 * @summary Get geofences
		 * @request GET:/tenants/{tenantID}/geofences
		 * @secure
		 */
		getTenantGeofences: (
			tenantId: number,
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				columnsList?: string[];
				/** @default [] */
				sort?: string[];
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGeofencesResponseDto, void>({
				path: `/tenants/${tenantId}/geofences`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new geofence in tenant scope based on information provided.
		 *
		 * @tags Geofences
		 * @name CreateTenantGeofence
		 * @summary Create a new geofence
		 * @request POST:/tenants/{tenantID}/geofences
		 * @secure
		 */
		createTenantGeofence: (tenantId: number, data: CreateGeofenceRequestDto, params: RequestParams = {}) =>
			this.request<GeofenceModel, any>({
				path: `/tenants/${tenantId}/geofences`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple geofences in tenant scope by their IDs
		 *
		 * @tags Geofences
		 * @name DeleteTenantGeofences
		 * @summary Delete multiple geofences by their IDs
		 * @request DELETE:/tenants/{tenantID}/geofences
		 * @secure
		 */
		deleteTenantGeofences: (
			tenantId: number,
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofences`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofence information by ID in tenant scope
		 *
		 * @tags Geofences
		 * @name GetTenantGeofence
		 * @summary Get geofence by ID
		 * @request GET:/tenants/{tenantID}/geofences/{geofenceID}
		 * @secure
		 */
		getTenantGeofence: (tenantId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<GeofenceModel, void>({
				path: `/tenants/${tenantId}/geofences/${geofenceId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a geofence in tenant scope based on provided ID and information.
		 *
		 * @tags Geofences
		 * @name UpdateTenantGeofence
		 * @summary Update a geofence
		 * @request PATCH:/tenants/{tenantID}/geofences/{geofenceID}
		 * @secure
		 */
		updateTenantGeofence: (
			tenantId: number,
			geofenceId: number,
			data: CreateGeofenceRequestDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofences/${geofenceId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a geofence in tenant scope by ID
		 *
		 * @tags Geofences
		 * @name DeleteTenantGeofence
		 * @summary Delete a geofence by ID
		 * @request DELETE:/tenants/{tenantID}/geofences/{geofenceID}
		 * @secure
		 */
		deleteTenantGeofence: (tenantId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofences/${geofenceId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Create a new geofence group in tenant scope based on information provided. The group can be created with or without geofences assigned to it.
		 *
		 * @tags Geofence Groups
		 * @name CreateTenantGeofenceGroup
		 * @summary Create a new geofence group
		 * @request POST:/tenants/{tenantID}/geofenceGroups
		 * @secure
		 */
		createTenantGeofenceGroup: (
			tenantId: number,
			data: CreateGeofenceGroupRequestDto,
			params: RequestParams = {},
		) =>
			this.request<CreateGeofenceGroupResponseDto, void>({
				path: `/tenants/${tenantId}/geofenceGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get geofence groups in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name GetTenantGeofenceGroups
		 * @summary Get geofence groups
		 * @request GET:/tenants/{tenantID}/geofenceGroups
		 * @secure
		 */
		getTenantGeofenceGroups: (
			tenantId: number,
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupByIDResponseDto, void>({
				path: `/tenants/${tenantId}/geofenceGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a geofence group in tenant scope based on provided ID and information.
		 *
		 * @tags Geofence Groups
		 * @name UpdateTenantGeofenceGroup
		 * @summary Update a geofence group
		 * @request PATCH:/tenants/{tenantID}/geofenceGroups/{groupID}
		 * @secure
		 */
		updateTenantGeofenceGroup: (
			tenantId: number,
			groupId: number,
			data: UpdateGeofenceGroupRequestDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a geofence group in tenant scope by ID
		 *
		 * @tags Geofence Groups
		 * @name DeleteTenantGeofenceGroup
		 * @summary Delete a geofence group by ID
		 * @request DELETE:/tenants/{tenantID}/geofenceGroups/{groupID}
		 * @secure
		 */
		deleteTenantGeofenceGroup: (tenantId: number, groupId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofence group information by ID in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name GetTenantGeofenceGroupById
		 * @summary Get geofence group by ID
		 * @request GET:/tenants/{tenantID}/geofenceGroups/{groupID}
		 * @secure
		 */
		getTenantGeofenceGroupById: (tenantId: number, groupId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceGroupByIDResponseDto, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve geofence group detailed information based on provided ID in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name GetTenantGeofenceGroupDetailById
		 * @summary Get geofence group details by ID
		 * @request GET:/tenants/{tenantID}/geofenceGroups/{groupID}/detail
		 * @secure
		 */
		getTenantGeofenceGroupDetailById: (tenantId: number, groupId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceGroupDetailByIDResponseDto, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Add geofences to the provided group in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name AddTenantGeofencesToGroup
		 * @summary Add geofences to group
		 * @request POST:/tenants/{tenantID}/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		addTenantGeofencesToGroup: (tenantId: number, groupId: number, data: number[], params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}/geofences`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve geofences that are part of the provided group in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name GetTenantGeofencesByGroupId
		 * @summary Get geofences by group ID
		 * @request GET:/tenants/{tenantID}/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		getTenantGeofencesByGroupId: (
			tenantId: number,
			groupId: number,
			query?: {
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGeofencesResponseDto, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}/geofences`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Remove geofences from the provided group by provided IDs in tenant scope. The geofences with specified IDs will be removed from the group.
		 *
		 * @tags Geofence Groups
		 * @name RemoveTenantGeofencesFromGroup
		 * @summary Remove geofences from group
		 * @request DELETE:/tenants/{tenantID}/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		removeTenantGeofencesFromGroup: (
			tenantId: number,
			groupId: number,
			query: {
				/**
				 * The IDs of the geofences to remove from the group
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}/geofences`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Add geofence to the provided group in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name AddTenantGeofenceToGroup
		 * @summary Add geofence to group
		 * @request POST:/tenants/{tenantID}/geofenceGroups/{groupID}/geofences/{geofenceID}
		 * @secure
		 */
		addTenantGeofenceToGroup: (tenantId: number, groupId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}/geofences/${geofenceId}`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Remove geofence from the provided group by provided ID in tenant scope. The geofence with specified ID will be removed from the group.
		 *
		 * @tags Geofence Groups
		 * @name RemoveTenantGeofenceFromGroup
		 * @summary Remove geofence from group
		 * @request DELETE:/tenants/{tenantID}/geofenceGroups/{groupID}/geofences/{geofenceID}
		 * @secure
		 */
		removeTenantGeofenceFromGroup: (
			tenantId: number,
			groupId: number,
			geofenceId: number,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/geofenceGroups/${groupId}/geofences/${geofenceId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get detailed information about geofence groups in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name GetTenantGeofenceGroupDetails
		 * @summary Get geofence group details
		 * @request GET:/tenants/{tenantID}/geofenceGroups/detail
		 * @secure
		 */
		getTenantGeofenceGroupDetails: (
			tenantId: number,
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupDetailByIDResponseDto, void>({
				path: `/tenants/${tenantId}/geofenceGroups/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get geofence groups by geofence ID in tenant scope
		 *
		 * @tags Geofence Groups
		 * @name GetTenantGeofenceGroupsByGeofenceId
		 * @summary Get geofence groups by geofence ID
		 * @request GET:/tenants/{tenantID}/geofences/{geofenceID}/groups
		 * @secure
		 */
		getTenantGeofenceGroupsByGeofenceId: (tenantId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/tenants/${tenantId}/geofences/${geofenceId}/groups`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new identity store.
		 *
		 * @tags Tenant identity Stores
		 * @name CreateTenantIdentityStore
		 * @request POST:/tenants/{tenantID}/identityStores
		 * @secure
		 */
		createTenantIdentityStore: (tenantId: number, data: CreateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/tenants/${tenantId}/identityStores`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a list of PAM (Privileged Access Management) targets for a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantTargets
		 * @summary Get a list of PAM targets for a specific tenant
		 * @request GET:/tenants/{tenantID}/pam/targets
		 * @secure
		 */
		getTenantTargets: (
			tenantId: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetsResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a list of detailed PAM (Privileged Access Management) targets for a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantTargetDetails
		 * @summary Get a list of detailed PAM targets for a specific tenant
		 * @request GET:/tenants/{tenantID}/pam/targets/detail
		 * @secure
		 */
		getTenantTargetDetails: (
			tenantId: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * The sorting criteria to apply.
				 * @default []
				 */
				sort?: string[];
				/**
				 * The filter criteria to apply.
				 * @default []
				 */
				filter?: string[];
				/**
				 * The columns to retrieve. Defaults to all columns if not provided.
				 * @default ["*"]
				 */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetDetailsResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a specific PAM (Privileged Access Management) target for a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantTarget
		 * @summary Get a specific PAM target for a specific tenant
		 * @request GET:/tenants/{tenantID}/pam/targets/{targetID}
		 * @secure
		 */
		getTenantTarget: (tenantId: number, targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes a specific PAM (Privileged Access Management) target from a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name DeleteTenantTarget
		 * @summary Delete a specific PAM target for a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pam/targets/{targetID}
		 * @secure
		 */
		deleteTenantTarget: (tenantId: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pam/targets/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) RDP target in a specific tenant.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name CreateTenantRdpTarget
		 * @summary Create a new PAM RDP target in a specific tenant
		 * @request POST:/tenants/{tenantID}/pam/targets/rdp
		 * @secure
		 */
		createTenantRdpTarget: (tenantId: number, data: CreatePAMRDPTargetRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMRDPTargetResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets/rdp`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads PAN (Privileged Access Management) RDP targets in a specific tenant. The result is paginated.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name GetTenantRdpTargets
		 * @summary Get PAM RDP targets in a specific tenant
		 * @request GET:/tenants/{tenantID}/pam/targets/rdp
		 * @secure
		 */
		getTenantRdpTargets: (
			tenantId: number,
			query?: {
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The number of items to skip before starting to collect the result set
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMRDPTargetsResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets/rdp`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint updates a PAM (Privileged Access Management) RDP target in a specific tenant.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name UpdateTenantRdpTarget
		 * @summary Update a PAM RDP target in a specific tenant
		 * @request PATCH:/tenants/{tenantID}/pam/targets/rdp/{targetID}
		 * @secure
		 */
		updateTenantRdpTarget: (
			tenantId: number,
			targetId: number,
			data: UpdatePAMRDPTargetRequestDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pam/targets/rdp/${targetId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint deletes a PAM (Privileged Access Management) RDP target in a specific tenant.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name DeleteTenantRdpTarget
		 * @summary Delete a PAM RDP target in a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pam/targets/rdp/{targetID}
		 * @secure
		 */
		deleteTenantRdpTarget: (tenantId: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pam/targets/rdp/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get all PAM sessions for a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantPamSessions
		 * @request GET:/tenants/{tenantID}/pam/sessions
		 * @secure
		 */
		getTenantPamSessions: (
			tenantId: number,
			query?: {
				/**
				 * - The maximum number of PAM Sessions to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the PAM Sessions (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialPamSessionDto, any>({
				path: `/tenants/${tenantId}/pam/sessions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve information about a specific PAM session for a tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantPamSession
		 * @request GET:/tenants/{tenantID}/pam/sessions/{sessionID}
		 * @secure
		 */
		getTenantPamSession: (tenantId: number, sessionId: number, params: RequestParams = {}) =>
			this.request<PartialPamSessionDto | null, any>({
				path: `/tenants/${tenantId}/pam/sessions/${sessionId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve user inputs for a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantUserInput
		 * @request GET:/tenants/{tenantID}/pam/sessions/search/{searchTerm}
		 * @secure
		 */
		getTenantUserInput: (
			searchTerm: string,
			tenantId: number,
			query?: {
				/**
				 * - The maximum number of user inputs to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the user inputs (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPAMUserInputSessionDto, any>({
				path: `/tenants/${tenantId}/pam/sessions/search/${searchTerm}`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve file transfers for a specific PAM session for specific Tenant.
		 *
		 * @tags Privileged Access Management
		 * @name GetTenantFileTransfers
		 * @request GET:/tenants/{tenantID}/pam/sessions/{sessionID}/file-transfers
		 * @secure
		 */
		getTenantFileTransfers: (
			sessionId: number,
			tenantId: number,
			query?: {
				/**
				 * - The maximum number of files to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the files (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultFileTransferDto, any>({
				path: `/tenants/${tenantId}/pam/sessions/${sessionId}/file-transfers`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) SSH target in a specific tenant.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name CreateTenantSshTarget
		 * @summary Create a new PAM SSH target in a specific tenant
		 * @request POST:/tenants/{tenantID}/pam/targets/ssh
		 * @secure
		 */
		createTenantSshTarget: (tenantId: number, data: CreatePAMSSHTargetRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMSSHTargetResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets/ssh`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads PAM (Privileged Access Management) SSH targets in a specific tenant. The result is paginated.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name GetTenantSshTargets
		 * @summary Get PAM SSH targets in a specific tenant
		 * @request GET:/tenants/{tenantID}/pam/targets/ssh
		 * @secure
		 */
		getTenantSshTargets: (
			tenantId: number,
			query?: {
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The number of items to skip before starting to collect the result set
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMSSHTargetResponseDto[], void>({
				path: `/tenants/${tenantId}/pam/targets/ssh`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes multiple PAM (Privileged Access Management) SSH targets in a specific tenant.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name DeleteTenantSshTargets
		 * @summary Delete multiple PAM SSH targets in a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pam/targets/ssh
		 * @secure
		 */
		deleteTenantSshTargets: (
			tenantId: number,
			query: {
				/**
				 * IDs of the targets
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pam/targets/ssh`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint reads a PAM (Privileged Access Management) SSH target in a specific tenant.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name GetTenantSshTarget
		 * @summary Get a PAM SSH target in a specific tenant
		 * @request GET:/tenants/{tenantID}/pam/targets/ssh/{targetID}
		 * @secure
		 */
		getTenantSshTarget: (tenantId: number, targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMSSHTargetResponseDto, void>({
				path: `/tenants/${tenantId}/pam/targets/ssh/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint updates a PAM (Privileged Access Management) SSH target in a specific tenant.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name UpdateTenantSshTarget
		 * @summary Update a PAM SSH target in a specific tenant
		 * @request PATCH:/tenants/{tenantID}/pam/targets/ssh/{targetID}
		 * @secure
		 */
		updateTenantSshTarget: (
			tenantId: number,
			targetId: number,
			data: UpdatePAMSSHTargetRequestDto,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/tenants/${tenantId}/pam/targets/ssh/${targetId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint deletes a PAM (Privileged Access Management) SSH target in a specific tenant.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name DeleteTenantSshTarget
		 * @summary Delete a PAM SSH target in a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pam/targets/ssh/{targetID}
		 * @secure
		 */
		deleteTenantSshTarget: (tenantId: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pam/targets/ssh/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Creates a new PAM target group for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name CreatePamTargetGroupForTenant
		 * @summary Create a new PAM target group for a specific tenant
		 * @request POST:/tenants/{tenantID}/pamGroups
		 * @secure
		 */
		createPamTargetGroupForTenant: (tenantId: number, data: CreatePAMTargetGroupDto, params: RequestParams = {}) =>
			this.request<CreatePAMTargetGroupResponseDto, void>({
				path: `/tenants/${tenantId}/pamGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a list of PAM target groups for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetTenantPamTargetGroups
		 * @summary Get a list of PAM target groups for a specific tenant
		 * @request GET:/tenants/{tenantID}/pamGroups
		 * @secure
		 */
		getTenantPamTargetGroups: (
			tenantId: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetGroupsResponseDto, void>({
				path: `/tenants/${tenantId}/pamGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes multiple PAM target groups by their IDs for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeleteTenantPamTargetGroups
		 * @summary Delete multiple PAM target groups for a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pamGroups
		 * @secure
		 */
		deleteTenantPamTargetGroups: (
			tenantId: number,
			query: {
				/**
				 * The IDs of the PAM target groups to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pamGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves a PAM target group by its ID for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetTenantPamTargetGroup
		 * @summary Get a PAM target group by ID for a specific tenant
		 * @request GET:/tenants/{tenantID}/pamGroups/{id}
		 * @secure
		 */
		getTenantPamTargetGroup: (tenantId: number, id: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetGroupResponseDto, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes a PAM target group by its ID for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeleteTenantPamTargetGroup
		 * @summary Delete a PAM target group by ID for a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pamGroups/{id}
		 * @secure
		 */
		deleteTenantPamTargetGroup: (tenantId: number, id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves a PAM target group by its ID for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetTenantPamTargetGroupDetail
		 * @summary Get a PAM target group by ID for a specific tenant
		 * @request GET:/tenants/{tenantID}/pamGroups/{id}/detail
		 * @secure
		 */
		getTenantPamTargetGroupDetail: (tenantId: number, id: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetGroupDetailResponseDto, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Adds a PAM target to a target group for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name AddTenantPamTargetToGroup
		 * @summary Add a PAM target to a group for a specific tenant
		 * @request POST:/tenants/{tenantID}/pamGroups/{id}/targets/{targetID}
		 * @secure
		 */
		addTenantPamTargetToGroup: (tenantId: number, id: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}/targets/${targetId}`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * @description Deletes a PAM target from a target group for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeleteTenantPamTargetFromGroup
		 * @summary Delete a PAM target from a group for a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pamGroups/{id}/targets/{targetID}
		 * @secure
		 */
		deleteTenantPamTargetFromGroup: (tenantId: number, id: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}/targets/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Adds multiple PAM targets to a target group for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name AddTenantPamTargetsToGroup
		 * @summary Add multiple PAM targets to a group for a specific tenant
		 * @request POST:/tenants/{tenantID}/pamGroups/{id}/targets
		 * @secure
		 */
		addTenantPamTargetsToGroup: (
			id: number,
			tenantId: number,
			query: {
				/**
				 * The IDs of the PAM targets to add.
				 * @example [1,2,3]
				 */
				targetIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}/targets`,
				method: 'POST',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Deletes multiple PAM targets from a target group for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeleteTenantPamTargetsFromGroup
		 * @summary Delete multiple PAM targets from a group for a specific tenant
		 * @request DELETE:/tenants/{tenantID}/pamGroups/{id}/targets
		 * @secure
		 */
		deleteTenantPamTargetsFromGroup: (
			tenantId: number,
			id: number,
			query: {
				/**
				 * The IDs of the PAM targets to delete.
				 * @example [1,2,3]
				 */
				targetIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}/targets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves the PAM targets in a target group for a specific tenant.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetTenantPamTargetsInGroup
		 * @summary Get PAM targets in a group for a specific tenant
		 * @request GET:/tenants/{tenantID}/pamGroups/{id}/targets
		 * @secure
		 */
		getTenantPamTargetsInGroup: (
			tenantId: number,
			id: number,
			query?: {
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetsResponseDto, void>({
				path: `/tenants/${tenantId}/pamGroups/${id}/targets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint invites users to join a specific tenant. The user IDs to invite are passed in the request body. When user has been invited already, previous invitation is disabled and new one is created. In case user is already invited to the application, the invitation is not created but user is added to the tenant.
		 *
		 * @tags User Invitations
		 * @name CreateTenantInvitations
		 * @summary Invite users to join a specific tenant
		 * @request POST:/tenants/{tenantID}/users/invitations
		 * @secure
		 */
		createTenantInvitations: (tenantId: number, data: CreateInvitationRequestDto, params: RequestParams = {}) =>
			this.request<CreateInvitationResponseDto, void>({
				path: `/tenants/${tenantId}/users/invitations`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves all invitations for a specific tenant.
		 *
		 * @tags User Invitations
		 * @name GetTenantInvitations
		 * @summary Get all invitations for a specific tenant
		 * @request GET:/tenants/{tenantID}/users/invitations
		 * @secure
		 */
		getTenantInvitations: (
			tenantId: number,
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialInvitationModel, void>({
				path: `/tenants/${tenantId}/users/invitations`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint invites a specific user to join a specific tenant.
		 *
		 * @tags User Invitations
		 * @name CreateTenantInvitation
		 * @summary Invite a specific user to join a specific tenant
		 * @request POST:/tenants/{tenantID}/users/invitations/{userID}
		 * @secure
		 */
		createTenantInvitation: (tenantId: number, userId: number, params: RequestParams = {}) =>
			this.request<InvitationModel, void>({
				path: `/tenants/${tenantId}/users/invitations/${userId}`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a list of all detailed invitations for a specific tenant.
		 *
		 * @tags User Invitations
		 * @name GetTenantDetailedInvitations
		 * @summary Retrieve a list of all detailed invitations for a specific tenant
		 * @request GET:/tenants/{tenantID}/users/invitations/detail
		 * @secure
		 */
		getTenantDetailedInvitations: (
			tenantId: number,
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialDetailedInvitationDto, void>({
				path: `/tenants/${tenantId}/users/invitations/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves a specific invitation for a specific tenant by its ID.
		 *
		 * @tags User Invitations
		 * @name GetTenantInvitationById
		 * @summary Get a specific invitation for a specific tenant
		 * @request GET:/tenants/{tenantID}/users/invitations/{invitationID}
		 * @secure
		 */
		getTenantInvitationById: (tenantId: number, invitationId: number, params: RequestParams = {}) =>
			this.request<PartialInvitationModel, void>({
				path: `/tenants/${tenantId}/users/invitations/${invitationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new user group for a tenant
		 *
		 * @tags User Groups
		 * @name CreateTenantUserGroup
		 * @summary Create a new user group for a tenant
		 * @request POST:/tenants/{tenantID}/userGroups
		 * @secure
		 */
		createTenantUserGroup: (tenantId: number, data: CreateUserGroupRequestDto, params: RequestParams = {}) =>
			this.request<CreateUserGroupResponseDto, void>({
				path: `/tenants/${tenantId}/userGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete an multiple user groups by provided IDs for a tenant
		 *
		 * @tags User Groups
		 * @name DeleteTenantUserGroups
		 * @summary Delete multiple user groups by provided IDs for a tenant
		 * @request DELETE:/tenants/{tenantID}/userGroups
		 * @secure
		 */
		deleteTenantUserGroups: (
			tenantId: number,
			query: {
				/** - The IDs of the user groups to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get user groups paginated collection for a tenant
		 *
		 * @tags User Groups
		 * @name GetTenantUserGroups
		 * @summary Get user groups paginated collection for a tenant
		 * @request GET:/tenants/{tenantID}/userGroups
		 * @secure
		 */
		getTenantUserGroups: (
			tenantId: number,
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserGroupResponseDto, void>({
				path: `/tenants/${tenantId}/userGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a user group by ID for a tenant
		 *
		 * @tags User Groups
		 * @name GetTenantUserGroup
		 * @summary Get a user group by ID for a tenant
		 * @request GET:/tenants/{tenantID}/userGroups/{userGroupID}
		 * @secure
		 */
		getTenantUserGroup: (tenantId: number, userGroupId: number, params: RequestParams = {}) =>
			this.request<UserGroupDto, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update an existing user group for a tenant
		 *
		 * @tags User Groups
		 * @name UpdateTenantUserGroup
		 * @summary Update an existing user group entity for a tenant
		 * @request PATCH:/tenants/{tenantID}/userGroups/{userGroupID}
		 * @secure
		 */
		updateTenantUserGroup: (
			tenantId: number,
			userGroupId: number,
			data: UpdateUserGroupRequestDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete an user group by ID for a tenant
		 *
		 * @tags User Groups
		 * @name DeleteTenantUserGroup
		 * @summary Delete an user group by provided ID for a tenant
		 * @request DELETE:/tenants/{tenantID}/userGroups/{userGroupID}
		 * @secure
		 */
		deleteTenantUserGroup: (tenantId: number, userGroupId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Add user to a user group for a tenant
		 *
		 * @tags User Groups
		 * @name AddTenantUserToUserGroup
		 * @summary Add user to a user group for a tenant
		 * @request POST:/tenants/{tenantID}/userGroups/{userGroupID}/users/{userID}
		 * @secure
		 */
		addTenantUserToUserGroup: (tenantId: number, userGroupId: number, userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}/users/${userId}`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * @description Delete an user from a user group for a tenant
		 *
		 * @tags User Groups
		 * @name DeleteTenantUserFromUserGroup
		 * @summary Delete an user from a user group for a tenant
		 * @request DELETE:/tenants/{tenantID}/userGroups/{userGroupID}/users/{userID}
		 * @secure
		 */
		deleteTenantUserFromUserGroup: (
			tenantId: number,
			userGroupId: number,
			userId: number,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}/users/${userId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Add multiple users to a user group for a tenant
		 *
		 * @tags User Groups
		 * @name AddTenantUsersToUserGroup
		 * @summary Add multiple users to a user group for a tenant
		 * @request POST:/tenants/{tenantID}/userGroups/{userGroupID}/users
		 * @secure
		 */
		addTenantUsersToUserGroup: (
			tenantId: number,
			userGroupId: number,
			data: number[],
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}/users`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete multiple users from a user group for a tenant
		 *
		 * @tags User Groups
		 * @name DeleteTenantUsersFromUserGroup
		 * @summary Delete multiple users from a user group for a tenant
		 * @request DELETE:/tenants/{tenantID}/userGroups/{userGroupID}/users
		 * @secure
		 */
		deleteTenantUsersFromUserGroup: (
			tenantId: number,
			userGroupId: number,
			query: {
				/** - IDs of the users */
				userIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}/users`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get paginated collection of users in a user group by group ID for a tenant
		 *
		 * @tags User Groups
		 * @name GetTenantUsersInUserGroup
		 * @summary Get paginated collection of users in a user group by group ID for a tenant
		 * @request GET:/tenants/{tenantID}/userGroups/{userGroupID}/users
		 * @secure
		 */
		getTenantUsersInUserGroup: (
			userGroupId: number,
			tenantId: number,
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columnsList?: string[];
				/**
				 * - An array of strings used for filtering user groups
				 * @default []
				 */
				filterList?: string[];
				/**
				 * - An array of strings used for sorting user groups
				 * @default []
				 */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersInGroupResponseDto, void>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User Groups
		 * @name ReplaceUsersInUserGroup
		 * @request PUT:/tenants/{tenantID}/userGroups/{userGroupID}/users
		 */
		replaceUsersInUserGroup: (tenantId: number, userGroupId: number, data: number[], params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/tenants/${tenantId}/userGroups/${userGroupId}/users`,
				method: 'PUT',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Return active tenant ID
		 *
		 * @tags Tenants
		 * @name Active
		 * @summary Get the active tenant ID of the authenticated user
		 * @request GET:/tenants/active
		 * @secure
		 */
		active: (params: RequestParams = {}) =>
			this.request<NullableNumber, void>({
				path: `/tenants/active`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return tenant information for the given tenant ID or slug
		 *
		 * @tags Tenants
		 * @name GetTenant
		 * @summary Get tenant by ID or slug
		 * @request GET:/tenants/{tenantIdOrSlug}
		 * @secure
		 */
		getTenant: (tenantIdOrSlug: any, params: RequestParams = {}) =>
			this.request<TenantModel, void>({
				path: `/tenants/${tenantIdOrSlug}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get paginated tenants collection
		 *
		 * @tags Tenants
		 * @name GetTenants
		 * @summary Get paginated tenants collection
		 * @request GET:/tenants
		 * @secure
		 */
		getTenants: (
			query?: {
				/**
				 * The filter to apply to the records
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sorting to apply to the records
				 * @default []
				 */
				sort?: string[];
				/**
				 * The columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of records to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset of the first record to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialTenantModel, void>({
				path: `/tenants`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new tenant in asynchronous manner. Tenant creation requires multiple steps on back-end side to be completed which some of them can be time-consuming. For this reason, the tenant creation is asynchronous and the response will contain the id of the tenant that is being created. The client can use the id to check the status of the tenant creation or pass webhook information to be notified when the tenant creation status changes.
		 *
		 * @tags Tenants
		 * @name CreateTenant
		 * @summary Create a new tenant
		 * @request POST:/tenants
		 * @secure
		 */
		createTenant: (data: CreateTenantRequestDto, params: RequestParams = {}) =>
			this.request<CreateTenantResponseDto, void>({
				path: `/tenants`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple tenants by their IDs
		 *
		 * @tags Tenants
		 * @name DeleteTenants
		 * @summary Delete multiple tenants by their IDs
		 * @request DELETE:/tenants
		 * @secure
		 */
		deleteTenants: (
			query: {
				/** The IDs of the tenants to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tenants`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Update a tenant by ID
		 *
		 * @tags Tenants
		 * @name UpdateTenant
		 * @summary Update tenant by ID
		 * @request PATCH:/tenants/{tenantID}
		 * @secure
		 */
		updateTenant: (tenantId: number, data: UpdateTenantRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a tenant by ID
		 *
		 * @tags Tenants
		 * @name DeleteTenant
		 * @summary Delete tenant by ID
		 * @request DELETE:/tenants/{tenantID}
		 * @secure
		 */
		deleteTenant: (tenantId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/${tenantId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retry tenant provisioning
		 *
		 * @tags Tenants
		 * @name RetryCreateTenant
		 * @summary Retry tenant provisioning
		 * @request POST:/tenants/retry
		 * @secure
		 */
		retryCreateTenant: (data: RetryCreateTenantDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tenants/retry`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	geofenceGroups = {
		/**
		 * @description Create a new geofence group based on information provided. The group can be created with or without geofences assigned to it.
		 *
		 * @tags Geofence Groups
		 * @name CreateGeofenceGroup
		 * @summary Create a new geofence group
		 * @request POST:/geofenceGroups
		 * @secure
		 */
		createGeofenceGroup: (data: CreateGeofenceGroupRequestDto, params: RequestParams = {}) =>
			this.request<CreateGeofenceGroupResponseDto, void>({
				path: `/geofenceGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a geofence groups by IDs
		 *
		 * @tags Geofence Groups
		 * @name DeleteGeofenceGroups
		 * @summary Delete a geofence groups by IDs
		 * @request DELETE:/geofenceGroups
		 * @secure
		 */
		deleteGeofenceGroups: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/geofenceGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get geofence groups
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroups
		 * @summary Get geofence groups
		 * @request GET:/geofenceGroups
		 * @secure
		 */
		getGeofenceGroups: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupByIDResponseDto, void>({
				path: `/geofenceGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get system geofence groups
		 *
		 * @tags Geofence Groups
		 * @name GetSystemGeofenceGroups
		 * @summary Get system geofence groups
		 * @request GET:/geofenceGroups/system
		 * @secure
		 */
		getSystemGeofenceGroups: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupByIDResponseDto, void>({
				path: `/geofenceGroups/system`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a geofence group based on provided ID and information.
		 *
		 * @tags Geofence Groups
		 * @name UpdateGeofenceGroup
		 * @summary Update a geofence group
		 * @request PATCH:/geofenceGroups/{groupID}
		 * @secure
		 */
		updateGeofenceGroup: (groupId: number, data: UpdateGeofenceGroupRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a geofence group by ID
		 *
		 * @tags Geofence Groups
		 * @name DeleteGeofenceGroup
		 * @summary Delete a geofence group by ID
		 * @request DELETE:/geofenceGroups/{groupID}
		 * @secure
		 */
		deleteGeofenceGroup: (groupId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofence group information by ID
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroupById
		 * @summary Get geofence group by ID
		 * @request GET:/geofenceGroups/{groupID}
		 * @secure
		 */
		getGeofenceGroupById: (groupId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceGroupByIDResponseDto, void>({
				path: `/geofenceGroups/${groupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get detailed information about geofence groups
		 *
		 * @tags Geofence Groups
		 * @name GetDetailedGeofenceGroupCollection
		 * @summary Get geofence group details
		 * @request GET:/geofenceGroups/detail
		 * @secure
		 */
		getDetailedGeofenceGroupCollection: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceGroupDetailByIDResponseDto, void>({
				path: `/geofenceGroups/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve geofence group detailed information based on provided ID
		 *
		 * @tags Geofence Groups
		 * @name GetGeofenceGroupDetailById
		 * @summary Get geofence group details by ID
		 * @request GET:/geofenceGroups/{groupID}/detail
		 * @secure
		 */
		getGeofenceGroupDetailById: (groupId: number, params: RequestParams = {}) =>
			this.request<GetGeofenceGroupDetailByIDResponseDto, void>({
				path: `/geofenceGroups/${groupId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Add geofence to the provided group
		 *
		 * @tags Geofence Groups
		 * @name AddGeofenceToGroup
		 * @summary Add geofence to group
		 * @request POST:/geofenceGroups/{groupID}/geofences/{geofenceID}
		 * @secure
		 */
		addGeofenceToGroup: (groupId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/geofenceGroups/${groupId}/geofences/${geofenceId}`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Remove geofence from the provided group by provided ID. The geofence with specified ID will be removed from the group.
		 *
		 * @tags Geofence Groups
		 * @name RemoveGeofenceFromGroup
		 * @summary Remove geofence from group
		 * @request DELETE:/geofenceGroups/{groupID}/geofences/{geofenceID}
		 * @secure
		 */
		removeGeofenceFromGroup: (groupId: number, geofenceId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}/geofences/${geofenceId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve geofences that are part of the provided group
		 *
		 * @tags Geofence Groups
		 * @name GetGeofencesByGroupId
		 * @summary Get geofences by group ID
		 * @request GET:/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		getGeofencesByGroupId: (
			groupId: number,
			query?: {
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetGeofenceDetailsInGroupResponseDto, void>({
				path: `/geofenceGroups/${groupId}/geofences`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Set geofences for the provided group
		 *
		 * @tags Geofence Groups
		 * @name SetGeofencesForGroup
		 * @summary Set geofences for group
		 * @request POST:/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		setGeofencesForGroup: (groupId: number, data: number[], params: RequestParams = {}) =>
			this.request<number[], void>({
				path: `/geofenceGroups/${groupId}/geofences`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Remove geofences from the provided group by provided IDs. The geofences with specified IDs will be removed from the group.
		 *
		 * @tags Geofence Groups
		 * @name RemoveGeofencesFromGroup
		 * @summary Remove geofences from group
		 * @request DELETE:/geofenceGroups/{groupID}/geofences
		 * @secure
		 */
		removeGeofencesFromGroup: (
			groupId: number,
			query: {
				/**
				 * The IDs of the geofences to remove from the group
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/geofenceGroups/${groupId}/geofences`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),
	};
	identityStores = {
		/**
		 * @description Create a new identity store.
		 *
		 * @tags Identity Stores
		 * @name CreateIdentityStore
		 * @summary Create a identity store
		 * @request POST:/identityStores
		 * @secure
		 */
		createIdentityStore: (data: CreateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/identityStores`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get identity store paginated collection
		 *
		 * @tags Identity Stores
		 * @name GetAllIdentityStores
		 * @summary Get identity store paginated collection
		 * @request GET:/identityStores
		 * @secure
		 */
		getAllIdentityStores: (
			query?: {
				/**
				 * - An array of strings used for filtering Identity Stores.
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of strings used for sorting Identity Stores.
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of strings representing the columns to retrieve.
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the Identity Stores (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialIdentityStoreDto, void>({
				path: `/identityStores`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple identity stores.
		 *
		 * @tags Identity Stores
		 * @name DeleteIdentityStores
		 * @summary Delete multiple identity stores by provided IDs
		 * @request DELETE:/identityStores
		 * @secure
		 */
		deleteIdentityStores: (
			query: {
				/** - An array of IDs of the identity stores to delete. */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/identityStores`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve a list of paginated invitations with detailed information for identity store.
		 *
		 * @tags Identity Stores
		 * @name GetDetailedInvitations
		 * @summary Retrieve a list of paginated detailed invitations for identity store
		 * @request GET:/identityStores/{id}/invitations/detail
		 * @secure
		 */
		getDetailedInvitations: (
			id: number,
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialDetailedInvitationDto, void>({
				path: `/identityStores/${id}/invitations/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve information about a specific identity store.
		 *
		 * @tags Identity Stores
		 * @name GetIdentityStore
		 * @summary Get identity store by ID
		 * @request GET:/identityStores/{id}
		 * @secure
		 */
		getIdentityStore: (id: number, params: RequestParams = {}) =>
			this.request<IdentityStoreDto | null, void>({
				path: `/identityStores/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update an existing identity store.
		 *
		 * @tags Identity Stores
		 * @name UpdateIdentityStore
		 * @summary Update an existing identity store
		 * @request PATCH:/identityStores/{id}
		 * @secure
		 */
		updateIdentityStore: (id: number, data: UpdateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<RpcUpdateLDAPIdentityStoreResponseAsObject, void>({
				path: `/identityStores/${id}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete an identity store by ID.
		 *
		 * @tags Identity Stores
		 * @name DeleteIdentityStore
		 * @summary Delete an identity store by ID
		 * @request DELETE:/identityStores/{id}
		 * @secure
		 */
		deleteIdentityStore: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/identityStores/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve users from a specific identity store.
		 *
		 * @tags Identity Stores
		 * @name GetUsers
		 * @summary Get users by identity store ID
		 * @request GET:/identityStores/{id}/users
		 * @secure
		 */
		getUsers: (
			id: number,
			query?: {
				/** @default [] */
				filter?: string[];
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersFromIdentityStoreResponseDto, void>({
				path: `/identityStores/${id}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a single user from a specific identity store.
		 *
		 * @tags Identity Stores
		 * @name GetUser
		 * @summary Get a user by identity store by identity store ID and username
		 * @request GET:/identityStores/{id}/users/{username}
		 * @secure
		 */
		getUser: (id: number, username: string, params: RequestParams = {}) =>
			this.request<UserFromIdentityStore, void>({
				path: `/identityStores/${id}/users/${username}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	localizations = {
		/**
		 * No description
		 *
		 * @tags Localizations
		 * @name GetLocalizations
		 * @request GET:/localizations
		 */
		getLocalizations: (params: RequestParams = {}) =>
			this.request<GetLocalizationsDto, void>({
				path: `/localizations`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Localizations
		 * @name ChangeLanguage
		 * @request POST:/localizations
		 * @secure
		 */
		changeLanguage: (data: ChangeLanguageDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/localizations`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Localizations
		 * @name GetLanguage
		 * @request GET:/localizations/language
		 * @secure
		 */
		getLanguage: (params: RequestParams = {}) =>
			this.request<ELanguageCode, void>({
				path: `/localizations/language`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	settings = {
		/**
		 * @description Create a SMTP configuration.
		 *
		 * @tags settings
		 * @name CreateSmtpConfiguration
		 * @summary Create a new smtp configuration.
		 * @request POST:/settings/mail/smtp
		 * @secure
		 */
		createSmtpConfiguration: (data: CreateSMTPMailConfigurationRequest, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/settings/mail/smtp`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get SMTP configuration collection.
		 *
		 * @tags settings
		 * @name ReadSmtpConfigurations
		 * @summary Retrieve SMTP configuration collection
		 * @request GET:/settings/mail/smtp
		 * @secure
		 */
		readSmtpConfigurations: (
			query?: {
				/**
				 * - The offset for paginating through the SMTP configurations (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of SMTP configurations to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<ReadSMTPMailConfigurationResponse[], any>({
				path: `/settings/mail/smtp`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve SMTP configuration by ID
		 *
		 * @tags settings
		 * @name ReadSmtpConfiguration
		 * @summary SMTP configuration by ID.
		 * @request GET:/settings/mail/smtp/{configurationID}
		 * @secure
		 */
		readSmtpConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<ReadSMTPMailConfigurationResponse, void>({
				path: `/settings/mail/smtp/${configurationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update SMTP configuration by ID
		 *
		 * @tags settings
		 * @name UpdateSmtpConfiguration
		 * @summary Update SMTP configuration by ID.
		 * @request PATCH:/settings/mail/smtp/{configurationID}
		 * @secure
		 */
		updateSmtpConfiguration: (
			configurationId: number,
			data: UpdateSMTPMailConfigurationRequest,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/settings/mail/smtp/${configurationId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Verify SMTP configuration by ID
		 *
		 * @tags settings
		 * @name VerifySmtpConfiguration
		 * @summary Verify SMTP configuration by ID.
		 * @request POST:/settings/mail/smtp/{configurationID}/verify
		 * @secure
		 */
		verifySmtpConfiguration: (
			configurationId: number,
			data: VerifySMTPMailConfigurationDto,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/settings/mail/smtp/${configurationId}/verify`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get mail configuration collection.
		 *
		 * @tags settings
		 * @name ReadConfigurations
		 * @summary Create a new geofence group
		 * @request GET:/settings/mail
		 * @secure
		 */
		readConfigurations: (
			query?: {
				/**
				 * - The maximum number to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ReadMailConfigurationResponse[], void>({
				path: `/settings/mail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve single mail configuration by ID.
		 *
		 * @tags settings
		 * @name ReadConfiguration
		 * @request GET:/settings/mail/{configurationID}
		 * @secure
		 */
		readConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<ReadMailConfigurationResponse, void>({
				path: `/settings/mail/${configurationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete mail configuration by ID.
		 *
		 * @tags settings
		 * @name DeleteMailConfiguration
		 * @summary Delete mail configuration by provided ID
		 * @request DELETE:/settings/mail/{configurationID}
		 * @secure
		 */
		deleteMailConfiguration: (configurationId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/mail/${configurationId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Delete mail configurations by IDs.
		 *
		 * @tags settings
		 * @name DeleteMailConfigurations
		 * @summary Delete mail configurations by provided IDs
		 * @request DELETE:/settings/mail/smtpIDs
		 * @secure
		 */
		deleteMailConfigurations: (
			query: {
				/**
				 * - The IDs of the mail configurations to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/settings/mail/smtpIDs`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Update system properties.
		 *
		 * @tags Settings
		 * @name UpdateSystemProperties
		 * @summary Update system properties.
		 * @request PUT:/settings/general
		 * @secure
		 */
		updateSystemProperties: (data: UpdateSystemPropertiesDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/general`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get system properties
		 *
		 * @tags Settings
		 * @name GetSystemProperties
		 * @summary Get system properties
		 * @request GET:/settings/general
		 * @secure
		 */
		getSystemProperties: (params: RequestParams = {}) =>
			this.request<SystemPropertiesResponseDto, void>({
				path: `/settings/general`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update map settings properties.
		 *
		 * @tags Settings
		 * @name UpdateMapSettingsProperties
		 * @summary Update map settings properties.
		 * @request PUT:/settings/general/map
		 * @secure
		 */
		updateMapSettingsProperties: (data: UpdateMapSettingsPropertiesDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/settings/general/map`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get Map settings properties
		 *
		 * @tags Settings
		 * @name GetMapSettingsProperties
		 * @summary Get Map settings properties
		 * @request GET:/settings/general/map
		 * @secure
		 */
		getMapSettingsProperties: (params: RequestParams = {}) =>
			this.request<MapSettingsPropertiesResponseDto, void>({
				path: `/settings/general/map`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves the setting for the expiration time of specified invitations by given user
		 *
		 * @tags Settings
		 * @name GetExpirationProperties
		 * @request GET:/settings/general/mail/expirationProperties
		 * @secure
		 */
		getExpirationProperties: (params: RequestParams = {}) =>
			this.request<InvitationExpirationValuesResponseDto, void>({
				path: `/settings/general/mail/expirationProperties`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Settings
		 * @name UpdateExpirationProperties
		 * @request PUT:/settings/general/mail/expirationProperties
		 * @secure
		 */
		updateExpirationProperties: (data: UpdateInvitationExpirationValuesDto, params: RequestParams = {}) =>
			this.request<UpdateInvitationExpirationValuesResponseDto, void>({
				path: `/settings/general/mail/expirationProperties`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Settings
		 * @name GetDeploymentCertificate
		 * @request GET:/settings/general/deployment/certificate
		 * @secure
		 */
		getDeploymentCertificate: (params: RequestParams = {}) =>
			this.request<GetDeploymentCertificateDto, void>({
				path: `/settings/general/deployment/certificate`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Settings
		 * @name DownloadDeploymentCertificate
		 * @request GET:/settings/general/deployment/certificate/download
		 * @secure
		 */
		downloadDeploymentCertificate: (params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/settings/general/deployment/certificate/download`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Settings
		 * @name GetVersions
		 * @request GET:/settings/general/versions
		 * @secure
		 */
		getVersions: (params: RequestParams = {}) =>
			this.request<GetVersionsResponseDto, void>({
				path: `/settings/general/versions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	oauthClient = {
		/**
		 * @description Get a single client by ClientID
		 *
		 * @tags oAuth Client
		 * @name GetOneByClientId
		 * @summary Get a single client by ClientID
		 * @request GET:/oauthClient/{clientID}
		 * @secure
		 */
		getOneByClientId: (clientId: string, params: RequestParams = {}) =>
			this.request<PartialOAuthClientResponseDto, void>({
				path: `/oauthClient/${clientId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a single OAuth Client by clientID
		 *
		 * @tags oAuth Client
		 * @name UpdateOauthClient
		 * @summary Update a single OAuth Client by clientID
		 * @request PATCH:/oauthClient/{clientID}
		 * @secure
		 */
		updateOauthClient: (clientId: string, data: UpdateOAuthClientDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete a single OAuth Client by clientID
		 *
		 * @tags oAuth Client
		 * @name DeleteOauthClient
		 * @summary Delete a single OAuth Client by clientID
		 * @request DELETE:/oauthClient/{clientID}
		 * @secure
		 */
		deleteOauthClient: (clientId: string, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get paginated OAuth clients collection
		 *
		 * @tags oAuth Client
		 * @name GetOAuthClientsCollection
		 * @summary Get paginated OAuth clients collection
		 * @request GET:/oauthClient
		 * @secure
		 */
		getOAuthClientsCollection: (
			query?: {
				/**
				 * - An array of strings used for filtering OAuth clients
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of strings used for sorting OAuth clients
				 * @default []
				 */
				sort?: string[];
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - The maximum number of OAuth clients to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the OAuth clients (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialOAuthClientsCollectionResponseDto, void>({
				path: `/oauthClient`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create new OAuth Client
		 *
		 * @tags oAuth Client
		 * @name CreateOauthClient
		 * @summary Create new OAuth Client
		 * @request POST:/oauthClient
		 * @secure
		 */
		createOauthClient: (data: CreateOAuthClientDto, params: RequestParams = {}) =>
			this.request<PartialCreateOAuthClientResponseDto, void>({
				path: `/oauthClient`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple OAuth Clients by clientIDs
		 *
		 * @tags oAuth Client
		 * @name DeleteOauthClients
		 * @summary Delete multiple OAuth Clients by clientIDs
		 * @request DELETE:/oauthClient
		 * @secure
		 */
		deleteOauthClients: (
			query: {
				ids: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/oauthClient`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Refresh OAuth Client secret
		 *
		 * @tags oAuth Client
		 * @name RefreshClientSecret
		 * @summary Refresh OAuth Client secret
		 * @request PATCH:/oauthClient/refreshSectet/{clientID}
		 * @secure
		 */
		refreshClientSecret: (clientId: string, params: RequestParams = {}) =>
			this.request<PartialRefreshClientSecretResponseDto, void>({
				path: `/oauthClient/refreshSectet/${clientId}`,
				method: 'PATCH',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete all permisssions from a single OAuth Client
		 *
		 * @tags oAuth Client
		 * @name DeletePermissionsFromOAuthClient
		 * @summary Delete all OAuth Clients
		 * @request DELETE:/oauthClient/{clientID}/permissions
		 * @secure
		 */
		deletePermissionsFromOAuthClient: (
			clientId: string,
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}/permissions`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Add permissions to a single OAuth Client
		 *
		 * @tags oAuth Client
		 * @name AddPermissionsToOAuthClient
		 * @summary Add permissions to a single OAuth Client
		 * @request POST:/oauthClient/{clientID}/permissions
		 * @secure
		 */
		addPermissionsToOAuthClient: (
			clientId: string,
			query: {
				/** - An array of permission IDs to add */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/oauthClient/${clientId}/permissions`,
				method: 'POST',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get permissions for a single OAuth Client
		 *
		 * @tags oAuth Client
		 * @name GetPermissionsForOAuthClient
		 * @summary Get permissions for a single OAuth Client
		 * @request GET:/oauthClient/{clientID}/permissions
		 * @secure
		 */
		getPermissionsForOAuthClient: (clientId: string, params: RequestParams = {}) =>
			this.request<PermissionModel[], void>({
				path: `/oauthClient/${clientId}/permissions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create new webhook
		 *
		 * @tags oAuth, webhook
		 * @name CreateWebhook
		 * @summary Create new webhook
		 * @request POST:/oauthClient/webhook
		 * @secure
		 */
		createWebhook: (data: CreateWebhookRequestDto, params: RequestParams = {}) =>
			this.request<CreateWebhookResponseDto, void>({
				path: `/oauthClient/webhook`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update a webhook
		 *
		 * @tags oAuth, webhook
		 * @name UpdateWebhook
		 * @summary Update a webhook
		 * @request PATCH:/oauthClient/webhook/{webhookID}
		 * @secure
		 */
		updateWebhook: (webhookId: number, data: UpdateWebhookRequestDto, params: RequestParams = {}) =>
			this.request<UpdateWebhookResponseDto, void>({
				path: `/oauthClient/webhook/${webhookId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a webhook
		 *
		 * @tags oAuth, webhook
		 * @name DeleteWebhook
		 * @summary Delete a webhook
		 * @request DELETE:/oauthClient/webhook/{webhookID}
		 * @secure
		 */
		deleteWebhook: (webhookId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/webhook/${webhookId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Get webhook by ID
		 *
		 * @tags oAuth, webhook
		 * @name GetWebhookById
		 * @summary Get webhook by ID
		 * @request GET:/oauthClient/webhook/{webhookID}
		 * @secure
		 */
		getWebhookById: (webhookId: number, params: RequestParams = {}) =>
			this.request<GetWebhookByIdResponseDto, void>({
				path: `/oauthClient/webhook/${webhookId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get webhooks belonging to an OAuth client optionally filtering by event type
		 *
		 * @tags oAuth, webhook
		 * @name GetWebhooksByOauthClient
		 * @summary Get webhooks belonging to an OAuth client optionally filtering by event type
		 * @request GET:/oauthClient/{oAuthClientID}/webhooks
		 * @secure
		 */
		getWebhooksByOauthClient: (
			oAuthClientId: string,
			query?: {
				/** - Optional name of the event type to filter by */
				eventType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<GetWebhooksByOauthClientResponseDto, void>({
				path: `/oauthClient/${oAuthClientId}/webhooks`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Sends testing event to the webhook
		 *
		 * @tags oAuth, webhook
		 * @name SendTestWebhookEvent
		 * @summary Sends testing event to the webhook
		 * @request POST:/oauthClient/webhook/{webhookID}/test
		 * @secure
		 */
		sendTestWebhookEvent: (webhookId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/oauthClient/webhook/${webhookId}/test`,
				method: 'POST',
				secure: true,
				...params,
			}),
	};
	oauth = {
		/**
		 * @description Authorize a client and get an access and refresh token
		 *
		 * @tags oAuth
		 * @name AuthorizeClient
		 * @summary Authorize a client and get an access and refresh token
		 * @request POST:/oauth/authorize
		 */
		authorizeClient: (data: AuthorizeDto, params: RequestParams = {}) =>
			this.request<OAuthTokenResultDto | null, any>({
				path: `/oauth/authorize`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	webhookEventTypes = {
		/**
		 * @description Get event types
		 *
		 * @tags webhook
		 * @name GetEventTypes
		 * @summary Get event types
		 * @request GET:/webhookEventTypes
		 * @secure
		 */
		getEventTypes: (params: RequestParams = {}) =>
			this.request<GetEventTypesResponseDto, void>({
				path: `/webhookEventTypes`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	pam = {
		/**
		 * @description This endpoint retrieves a list of PAM (Privileged Access Management) targets.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargets
		 * @summary Get a list of PAM targets
		 * @request GET:/pam/targets
		 * @secure
		 */
		getTargets: (
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetsResponseDto, void>({
				path: `/pam/targets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes a PAM Targets by IDS (Privileged Access Management) target.
		 *
		 * @tags Privileged Access Management
		 * @name DeleteTargets
		 * @summary Delete a PAM targets by IDS
		 * @request DELETE:/pam/targets
		 * @secure
		 */
		deleteTargets: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pam/targets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint duplicates a new PAM target for a specific tenant.
		 *
		 * @tags Privileged Access Management
		 * @name DuplicatePamTarget
		 * @summary Duplicate a new PAM target in a specific tenant
		 * @request POST:/pam/targets/{targetID}/duplicate
		 * @secure
		 */
		duplicatePamTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<DuplicatePAMTargetResponseDto, void>({
				path: `/pam/targets/${targetId}/duplicate`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a list of detailed PAM targets.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargetDetails
		 * @summary Get a list of detailed PAM targets
		 * @request GET:/pam/targets/detail
		 * @secure
		 */
		getTargetDetails: (
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * The sorting criteria to apply.
				 * @default []
				 */
				sort?: string[];
				/**
				 * The filter criteria to apply.
				 * @default []
				 */
				filter?: string[];
				/**
				 * The columns to retrieve. Defaults to all columns if not provided.
				 * @default ["*"]
				 */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetDetailsResponseDto, void>({
				path: `/pam/targets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a specific PAM (Privileged Access Management) target.
		 *
		 * @tags Privileged Access Management
		 * @name GetTarget
		 * @summary Get a specific PAM target
		 * @request GET:/pam/targets/{targetID}
		 * @secure
		 */
		getTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetResponseDto, void>({
				path: `/pam/targets/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes a specific PAM (Privileged Access Management) target.
		 *
		 * @tags Privileged Access Management
		 * @name DeleteTarget
		 * @summary Delete a specific PAM target
		 * @request DELETE:/pam/targets/{targetID}
		 * @secure
		 */
		deleteTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint retrieves a specific PAM (Privileged Access Management) target detail.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargetDetail
		 * @summary Get a specific PAM target detail
		 * @request GET:/pam/targets/{targetID}/detail
		 * @secure
		 */
		getTargetDetail: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetDetailResponseDto, void>({
				path: `/pam/targets/${targetId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoints retrieves PAM sessions of given PAM target.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamTargetSessions
		 * @summary Get PAM sessions of given PAM target based on permissions of request.
		 * @request GET:/pam/targets/{targetID}/sessions
		 * @secure
		 */
		getPamTargetSessions: (
			targetId: number,
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetSessionsResponseDto, void>({
				path: `/pam/targets/${targetId}/sessions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoints retrieves all users with access to given PAM target.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamTargetUsers
		 * @summary Get all users with access to to given PAM target.
		 * @request GET:/pam/targets/{targetID}/users
		 * @secure
		 */
		getPamTargetUsers: (
			targetId: number,
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetUsersResponseDto, void>({
				path: `/pam/targets/${targetId}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Privileged Access Management
		 * @name VerifyPamTarget
		 * @request POST:/pam/verify
		 * @secure
		 */
		verifyPamTarget: (data: VerifyPAMTargetRequestDto, params: RequestParams = {}) =>
			this.request<VerifyPAMTargetResponseDto, any>({
				path: `/pam/verify`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves audit logs for specific PAM (Privileged Access Management) target.
		 *
		 * @tags Privileged Access Management
		 * @name GetTargetAuditLogs
		 * @summary Get audit logs for PAM target
		 * @request GET:/pam/{targetID}/audit
		 * @secure
		 */
		getTargetAuditLogs: (
			targetId: number,
			query?: {
				/**
				 * The IDs of the users to retrieve audit logs for. Defaults to all users if not provided.
				 * @default []
				 */
				userIDs?: number[];
				/**
				 * The filter criteria to apply.
				 * @default []
				 */
				filter?: string[];
				/**
				 * The columns to retrieve. Defaults to all columns if not provided.
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The sorting criteria to apply.
				 * @default []
				 */
				sort?: string[];
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetAuditLogsResponseDto, void>({
				path: `/pam/${targetId}/audit`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name CreateRdpTarget
		 * @summary Create a new PAM RDP target
		 * @request POST:/pam/targets/rdp
		 * @secure
		 */
		createRdpTarget: (data: CreatePAMRDPTargetRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMRDPTargetResponseDto, void>({
				path: `/pam/targets/rdp`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads PAM (Privileged Access Management) RDP targets. The result is paginated.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name GetRdpTargets
		 * @summary Get PAM RDP targets
		 * @request GET:/pam/targets/rdp
		 * @secure
		 */
		getRdpTargets: (
			query?: {
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The number of items to skip before starting to collect the result set
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMRDPTargetsResponseDto, void>({
				path: `/pam/targets/rdp`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads a PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name GetRdpTarget
		 * @summary Get a PAM RDP target
		 * @request GET:/pam/targets/rdp/{targetID}
		 * @secure
		 */
		getRdpTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMRDPTargetResponseDto, void>({
				path: `/pam/targets/rdp/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint updates a PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name UpdateRdpTarget
		 * @summary Update a PAM RDP target
		 * @request PATCH:/pam/targets/rdp/{targetID}
		 * @secure
		 */
		updateRdpTarget: (targetId: number, data: UpdatePAMRDPTargetRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/rdp/${targetId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint deletes a PAM (Privileged Access Management) RDP target.
		 *
		 * @tags Privileged Access Management (RDP)
		 * @name DeleteRdpTarget
		 * @summary Delete a PAM RDP target
		 * @request DELETE:/pam/targets/rdp/{targetID}
		 * @secure
		 */
		deleteRdpTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/rdp/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) session to a target specified by the target ID.
		 *
		 * @tags Privileged Access Management
		 * @name CreatePamSession
		 * @summary Create a new PAM session
		 * @request POST:/pam/sessions
		 * @secure
		 */
		createPamSession: (data: CreatePAMSessionRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMSessionResponseDto, void>({
				path: `/pam/sessions`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get all PAM sessions.
		 *
		 * @tags Privileged Access Management
		 * @name GetAllPamSessions
		 * @request GET:/pam/sessions
		 * @secure
		 */
		getAllPamSessions: (
			query?: {
				/**
				 * - The maximum number of PAM Sessions to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the PAM Sessions (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialPamSessionDto, any>({
				path: `/pam/sessions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve information about a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamSessionById
		 * @request GET:/pam/sessions/{sessionID}
		 * @secure
		 */
		getPamSessionById: (sessionId: number, params: RequestParams = {}) =>
			this.request<PartialPamSessionDto | null, any>({
				path: `/pam/sessions/${sessionId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve user inputs for a all PAM sessions.
		 *
		 * @tags Privileged Access Management
		 * @name GetUserInput
		 * @request GET:/pam/sessions/search/{searchTerm}
		 * @secure
		 */
		getUserInput: (
			searchTerm: string,
			query?: {
				/**
				 * - The maximum number of user inputs to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the user inputs (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPAMUserInputSessionDto, any>({
				path: `/pam/sessions/search/${searchTerm}`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve file transfers for a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetFileTransfers
		 * @request GET:/pam/sessions/{sessionID}/file-transfers
		 * @secure
		 */
		getFileTransfers: (
			sessionId: number,
			query?: {
				/**
				 * - The maximum number of files to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - The offset for paginating through the files (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultFileTransferDto, any>({
				path: `/pam/sessions/${sessionId}/file-transfers`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves session recording for a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetRecording
		 * @summary Get session recording
		 * @request GET:/pam/sessions/{sessionID}/recording
		 * @secure
		 */
		getRecording: (sessionId: number, params: RequestParams = {}) =>
			this.request<
				string,
				{
					message: string;
				} | void
			>({
				path: `/pam/sessions/${sessionId}/recording`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve target info for a specific PAM session.
		 *
		 * @tags Privileged Access Management
		 * @name GetPamTargetInfo
		 * @request GET:/pam/sessions/{sessionID}/target
		 * @secure
		 */
		getPamTargetInfo: (sessionId: number, params: RequestParams = {}) =>
			this.request<PAMTargetInfoDto, any>({
				path: `/pam/sessions/${sessionId}/target`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves SSH session typescript.
		 *
		 * @tags Privileged Access Management
		 * @name GetTypescript
		 * @summary Get session recording
		 * @request GET:/pam/sessions/{sessionID}/typescript
		 * @secure
		 */
		getTypescript: (sessionId: number, params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/pam/sessions/${sessionId}/typescript`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new PAM (Privileged Access Management) SSH target.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name CreateSshTarget
		 * @summary Create a new PAM SSH target
		 * @request POST:/pam/targets/ssh
		 * @secure
		 */
		createSshTarget: (data: CreatePAMSSHTargetRequestDto, params: RequestParams = {}) =>
			this.request<CreatePAMSSHTargetResponseDto, void>({
				path: `/pam/targets/ssh`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint reads PAM (Privileged Access Management) SSH targets. The result is paginated.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name GetSshTargets
		 * @summary Get PAM SSH targets
		 * @request GET:/pam/targets/ssh
		 * @secure
		 */
		getSshTargets: (
			query?: {
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The number of items to skip before starting to collect the result set
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMSSHTargetResponseDto[], void>({
				path: `/pam/targets/ssh`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes multiple PAM (Privileged Access Management) SSH targets.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name DeleteSshTargets
		 * @summary Delete multiple PAM SSH targets
		 * @request DELETE:/pam/targets/ssh
		 * @secure
		 */
		deleteSshTargets: (
			query: {
				/**
				 * IDs of the targets
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pam/targets/ssh`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint reads a PAM (Privileged Access Management) SSH target.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name GetSshTarget
		 * @summary Get a PAM SSH target
		 * @request GET:/pam/targets/ssh/{targetID}
		 * @secure
		 */
		getSshTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<GetPAMSSHTargetResponseDto, void>({
				path: `/pam/targets/ssh/${targetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint updates a PAM (Privileged Access Management) SSH target.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name UpdateSshTarget
		 * @summary Update a PAM SSH target
		 * @request PATCH:/pam/targets/ssh/{targetID}
		 * @secure
		 */
		updateSshTarget: (targetId: number, data: UpdatePAMSSHTargetRequestDto, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/pam/targets/ssh/${targetId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint deletes a PAM (Privileged Access Management) SSH target.
		 *
		 * @tags Privileged Access Management (SSH)
		 * @name DeleteSshTarget
		 * @summary Delete a PAM SSH target
		 * @request DELETE:/pam/targets/ssh/{targetID}
		 * @secure
		 */
		deleteSshTarget: (targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pam/targets/ssh/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),
	};
	pamGroups = {
		/**
		 * @description Creates a new PAM target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name CreatePamTargetGroup
		 * @summary Create a new PAM target group
		 * @request POST:/pamGroups
		 * @secure
		 */
		createPamTargetGroup: (data: CreatePAMTargetGroupDto, params: RequestParams = {}) =>
			this.request<CreatePAMTargetGroupResponseDto, void>({
				path: `/pamGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a list of PAM target groups.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroups
		 * @summary Get a list of PAM target groups
		 * @request GET:/pamGroups
		 * @secure
		 */
		getPamTargetGroups: (
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetGroupsResponseDto, void>({
				path: `/pamGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes multiple PAM target groups by their IDs.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetGroups
		 * @summary Delete multiple PAM target groups
		 * @request DELETE:/pamGroups
		 * @secure
		 */
		deletePamTargetGroups: (
			query: {
				/**
				 * The IDs of the PAM target groups to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pamGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves a PAM target group detail by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroupDetails
		 * @summary Get a PAM target group detail by ID
		 * @request GET:/pamGroups/detail
		 * @secure
		 */
		getPamTargetGroupDetails: (
			query?: {
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetGroupDetailsResponseDto, void>({
				path: `/pamGroups/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a PAM target group by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroup
		 * @summary Get a PAM target group by ID
		 * @request GET:/pamGroups/{id}
		 * @secure
		 */
		getPamTargetGroup: (id: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetGroupResponseDto, void>({
				path: `/pamGroups/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes a PAM target group by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetGroup
		 * @summary Delete a PAM target group by ID
		 * @request DELETE:/pamGroups/{id}
		 * @secure
		 */
		deletePamTargetGroup: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Updates a PAM target group by its ID.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name UpdatePamTargetGroup
		 * @summary Update a PAM target group by ID
		 * @request PATCH:/pamGroups/{id}
		 * @secure
		 */
		updatePamTargetGroup: (id: number, data: UpdatePAMTargetGroupDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetGroupDetail
		 * @request GET:/pamGroups/{id}/detail
		 * @secure
		 */
		getPamTargetGroupDetail: (id: number, params: RequestParams = {}) =>
			this.request<GetPAMTargetGroupDetailResponseDto, void>({
				path: `/pamGroups/${id}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Adds a PAM target to a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name AddPamTargetToGroup
		 * @summary Add a PAM target to a group
		 * @request POST:/pamGroups/{id}/targets/{targetID}
		 * @secure
		 */
		addPamTargetToGroup: (id: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets/${targetId}`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * @description Deletes a PAM target from a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetFromGroup
		 * @summary Delete a PAM target from a group
		 * @request DELETE:/pamGroups/{id}/targets/{targetID}
		 * @secure
		 */
		deletePamTargetFromGroup: (id: number, targetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets/${targetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Adds multiple PAM targets to a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name AddPamTargetsToGroup
		 * @summary Add multiple PAM targets to a group
		 * @request POST:/pamGroups/{id}/targets
		 * @secure
		 */
		addPamTargetsToGroup: (
			id: number,
			query: {
				/**
				 * The IDs of the PAM targets to add.
				 * @example [1,2,3]
				 */
				targetIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets`,
				method: 'POST',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Deletes multiple PAM targets from a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name DeletePamTargetsFromGroup
		 * @summary Delete multiple PAM targets from a group
		 * @request DELETE:/pamGroups/{id}/targets
		 * @secure
		 */
		deletePamTargetsFromGroup: (
			id: number,
			query: {
				/**
				 * The IDs of the PAM targets to delete.
				 * @example [1,2,3]
				 */
				targetIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/pamGroups/${id}/targets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves the PAM targets in a target group.
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetsInGroup
		 * @summary Get PAM targets in a group
		 * @request GET:/pamGroups/{id}/targets
		 * @secure
		 */
		getPamTargetsInGroup: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetsResponseDto, void>({
				path: `/pamGroups/${id}/targets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Privileged Access Management (PAM) Groups
		 * @name GetPamTargetDetailsInGroup
		 * @request GET:/pamGroups/{id}/targets/detail
		 * @secure
		 */
		getPamTargetDetailsInGroup: (
			id: number,
			query?: {
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetPAMTargetDetailsResponseDto, void>({
				path: `/pamGroups/${id}/targets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	permissions = {
		/**
		 * @description Get all available permissions in groups
		 *
		 * @tags Permissions
		 * @name GetPermissions
		 * @summary Get all available permissions in groups
		 * @request GET:/permissions
		 * @secure
		 */
		getPermissions: (params: RequestParams = {}) =>
			this.request<GetPermissionsResponseDto, void>({
				path: `/permissions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	roles = {
		/**
		 * @description Create a new user role.
		 *
		 * @tags Roles
		 * @name CreateRole
		 * @summary Create a user role
		 * @request POST:/roles
		 * @secure
		 */
		createRole: (data: CreateRoleRequestDto, params: RequestParams = {}) =>
			this.request<CreateRoleResponseDto, void>({
				path: `/roles`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get user roles collection.
		 *
		 * @tags Roles
		 * @name GetRoles
		 * @summary Get user roles collection
		 * @request GET:/roles
		 * @secure
		 */
		getRoles: (
			query?: {
				/**
				 * - The offset for paginating through the user roles (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of user roles to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetRolesResponseDto, void>({
				path: `/roles`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple user roles by provided IDs
		 *
		 * @tags Roles
		 * @name DeleteRoles
		 * @summary Delete multiple user roles by provided IDs
		 * @request DELETE:/roles
		 * @secure
		 */
		deleteRoles: (
			query: {
				/** - An array of IDs of the user roles to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/roles`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve single role
		 *
		 * @tags Roles
		 * @name GetRole
		 * @summary Retrieve single role by ID
		 * @request GET:/roles/{roleID}
		 * @secure
		 */
		getRole: (roleId: number, params: RequestParams = {}) =>
			this.request<GetRoleResponseDto, void>({
				path: `/roles/${roleId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update an existing user role
		 *
		 * @tags Roles
		 * @name UpdateRole
		 * @summary Update an existing user role
		 * @request PUT:/roles/{roleID}
		 * @secure
		 */
		updateRole: (roleId: number, data: UpdateRoleRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/roles/${roleId}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete an user role by ID
		 *
		 * @tags Roles
		 * @name DeleteRole
		 * @summary Delete an user role by ID
		 * @request DELETE:/roles/{roleID}
		 * @secure
		 */
		deleteRole: (roleId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/roles/${roleId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieve single detailed role with permissions
		 *
		 * @tags Roles
		 * @name GetRoleDetail
		 * @summary Retrieve single detailed role by ID with permissions
		 * @request GET:/roles/{roleID}/detail
		 * @secure
		 */
		getRoleDetail: (roleId: number, params: RequestParams = {}) =>
			this.request<GetRoleDetailResponseDto, void>({
				path: `/roles/${roleId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get detailed user roles collection.
		 *
		 * @tags Roles
		 * @name GetRoleDetails
		 * @summary Get detailed user roles collection
		 * @request GET:/roles/detail
		 * @secure
		 */
		getRoleDetails: (
			query?: {
				/**
				 * - The offset for paginating through the user roles (default is 0).
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of user roles to retrieve (default is 20).
				 * @format double
				 * @default 20
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetRoleDetailsResponseDto, void>({
				path: `/roles/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	users = {
		/**
		 * @description This endpoint invites users to join the application. The user IDs to invite are passed in the request body. When user has been invited already, previous invitation is disabled and new one is created.
		 *
		 * @tags User Invitations
		 * @name CreateInvitations
		 * @summary Invite users to join the application
		 * @request POST:/users/invitations
		 * @secure
		 */
		createInvitations: (data: CreateInvitationRequestDto, params: RequestParams = {}) =>
			this.request<CreateInvitationResponseDto, void>({
				path: `/users/invitations`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves all invitations.
		 *
		 * @tags User Invitations
		 * @name GetInvitations
		 * @summary Get all invitations
		 * @request GET:/users/invitations
		 * @secure
		 */
		getInvitations: (
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialInvitationModel, void>({
				path: `/users/invitations`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint deletes multiple invitations by their IDs
		 *
		 * @tags User Invitations
		 * @name DeleteInvitations
		 * @summary Delete multiple invitations
		 * @request DELETE:/users/invitations
		 * @secure
		 */
		deleteInvitations: (
			query: {
				/**
				 * An array of invitation IDs to delete
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/users/invitations`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint invites a specific user to join the application.
		 *
		 * @tags User Invitations
		 * @name CreateInvitation
		 * @summary Invite a specific user to join the application
		 * @request POST:/users/invitations/{userID}
		 * @secure
		 */
		createInvitation: (userId: number, params: RequestParams = {}) =>
			this.request<InvitationModel, void>({
				path: `/users/invitations/${userId}`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a list of paginated invitations with detailed information.
		 *
		 * @tags User Invitations
		 * @name GetDetailedInvitations
		 * @summary Retrieve a list of paginated detailed invitations
		 * @request GET:/users/invitations/detail
		 * @secure
		 */
		getDetailedInvitations: (
			query?: {
				/**
				 * An array of strings used for filtering invitations
				 * @default []
				 */
				filter?: string[];
				/**
				 * An array of strings used for sorting invitations
				 * @default []
				 */
				sort?: string[];
				/**
				 * An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of invitations to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset for paginating through the invitations (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialDetailedInvitationDto, void>({
				path: `/users/invitations/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint retrieves a specific invitation by its ID.
		 *
		 * @tags User Invitations
		 * @name GetInvitationById
		 * @summary Get a specific invitation
		 * @request GET:/users/invitations/{invitationID}
		 * @secure
		 */
		getInvitationById: (invitationId: number, params: RequestParams = {}) =>
			this.request<PartialInvitationModel, void>({
				path: `/users/invitations/${invitationId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description The endpoint deletes a specific invitation by its ID
		 *
		 * @tags User Invitations
		 * @name DeleteInvitation
		 * @summary Delete a specific invitation
		 * @request DELETE:/users/invitations/{invitationID}
		 * @secure
		 */
		deleteInvitation: (invitationId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/users/invitations/${invitationId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description The endpoint retrieves all invitations for a specific user
		 *
		 * @tags User Invitations
		 * @name GetInvitationsByUserId
		 * @summary Get all invitations for a specific user
		 * @request GET:/users/{userID}/invitations
		 * @secure
		 */
		getInvitationsByUserId: (userId: number, params: RequestParams = {}) =>
			this.request<PartialInvitationModel[], void>({
				path: `/users/${userId}/invitations`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint creates a new users.
		 *
		 * @tags Users
		 * @name CreateUsers
		 * @summary Create a new user
		 * @request POST:/users
		 * @secure
		 */
		createUsers: (data: CreateUsersRequestDto, params: RequestParams = {}) =>
			this.request<CreateUsersResponseDto, void>({
				path: `/users`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes multiple users by their unique identifiers.
		 *
		 * @tags Users
		 * @name DeleteUsers
		 * @summary Delete multiple users
		 * @request DELETE:/users
		 * @secure
		 */
		deleteUsers: (
			query: {
				/**
				 * The unique identifiers of the users to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/users`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description This endpoint retrieves multiple users.
		 *
		 * @tags Users
		 * @name GetUsers
		 * @summary Get multiple users
		 * @request GET:/users
		 * @secure
		 */
		getUsers: (
			query?: {
				/**
				 * The filter to apply to the results.
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sort order to apply to the results.
				 * @default []
				 */
				sort?: string[];
				/**
				 * The columns to retrieve for each result.
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The maximum number of results to retrieve.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersResponseDto, void>({
				path: `/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a list of detailed Users.
		 *
		 * @tags Users
		 * @name GetUserDetails
		 * @summary Get a list of detailed Users
		 * @request GET:/users/detail
		 * @secure
		 */
		getUserDetails: (
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				sort?: string[];
				/** @default [] */
				filter?: string[];
				/** @default ["*"] */
				columns?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserDetailsResponseDto, void>({
				path: `/users/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get authenticated user
		 *
		 * @tags Users
		 * @name Me
		 * @summary Get authenticated user data
		 * @request GET:/users/me
		 * @secure
		 */
		me: (params: RequestParams = {}) =>
			this.request<GetMeResponseDto, any>({
				path: `/users/me`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve all tenants related to authenticated user
		 *
		 * @tags Users
		 * @name MeTenants
		 * @summary Get all tenants for authenticated user
		 * @request GET:/users/me/tenants
		 * @secure
		 */
		meTenants: (
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * The maximum number of records to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The offset of the first record to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialTenantModel, void>({
				path: `/users/me/tenants`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return all permissions for authenticated user
		 *
		 * @tags Users
		 * @name MePermissions
		 * @summary Get all permissions for authenticated user
		 * @request GET:/users/me/permissions
		 * @secure
		 */
		mePermissions: (params: RequestParams = {}) =>
			this.request<EPermission[], void>({
				path: `/users/me/permissions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get role related to authenticated user
		 *
		 * @tags Users
		 * @name MeRole
		 * @summary Get role for authenticated user
		 * @request GET:/users/me/role
		 * @secure
		 */
		meRole: (params: RequestParams = {}) =>
			this.request<GetMeRoleModelDto, void>({
				path: `/users/me/role`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUsersAuditLogs
		 * @request GET:/users/audit
		 * @secure
		 */
		getUsersAuditLogs: (
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/** @default [] */
				usersIDs?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersAuditLogsResponseDto, void>({
				path: `/users/audit`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint retrieves a specific user by their unique identifier
		 *
		 * @tags Users
		 * @name GetUser
		 * @summary Get a specific user
		 * @request GET:/users/{userID}
		 * @secure
		 */
		getUser: (
			userId: number,
			query: {
				columns: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PartialGetUserResponseDto, void>({
				path: `/users/${userId}`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description This endpoint deletes a specific user by their unique identifier
		 *
		 * @tags Users
		 * @name DeleteUser
		 * @summary Delete a specific user
		 * @request DELETE:/users/{userID}
		 * @secure
		 */
		deleteUser: (userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/users/${userId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUserDetail
		 * @request GET:/users/{userID}/detail
		 * @secure
		 */
		getUserDetail: (userId: number, params: RequestParams = {}) =>
			this.request<GetUserResponseDto, void>({
				path: `/users/${userId}/detail`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUserGroupsByUserId
		 * @request GET:/users/{userID}/userGroups
		 * @secure
		 */
		getUserGroupsByUserId: (userId: number, params: RequestParams = {}) =>
			this.request<UserGroupDto[], void>({
				path: `/users/${userId}/userGroups`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	userGroups = {
		/**
		 * @description Create a new user group
		 *
		 * @tags User Groups
		 * @name CreateUserGroup
		 * @summary Create a new user group
		 * @request POST:/userGroups
		 * @secure
		 */
		createUserGroup: (data: CreateUserGroupRequestDto, params: RequestParams = {}) =>
			this.request<CreateUserGroupResponseDto, void>({
				path: `/userGroups`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete an multiple user groups by provided IDs
		 *
		 * @tags User Groups
		 * @name DeleteUserGroups
		 * @summary Delete multiple user groups by provided IDs
		 * @request DELETE:/userGroups
		 * @secure
		 */
		deleteUserGroups: (
			query: {
				/** - The IDs of the user groups to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/userGroups`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get user groups paginated collection
		 *
		 * @tags User Groups
		 * @name GetUserGroups
		 * @summary Get user groups paginated collection
		 * @request GET:/userGroups
		 * @secure
		 */
		getUserGroups: (
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columnsList?: string[];
				/**
				 * - An array of strings used for filtering user groups
				 * @default []
				 */
				filterList?: string[];
				/**
				 * - An array of strings used for sorting user groups
				 * @default []
				 */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserGroupResponseDto, void>({
				path: `/userGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve a user group by ID
		 *
		 * @tags User Groups
		 * @name GetUserGroup
		 * @summary Get a user group by ID
		 * @request GET:/userGroups/{userGroupID}
		 * @secure
		 */
		getUserGroup: (userGroupId: number, params: RequestParams = {}) =>
			this.request<UserGroupDto, void>({
				path: `/userGroups/${userGroupId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update an existing user group
		 *
		 * @tags User Groups
		 * @name UpdateUserGroup
		 * @summary Update an existing user group entity
		 * @request PATCH:/userGroups/{userGroupID}
		 * @secure
		 */
		updateUserGroup: (userGroupId: number, data: UpdateUserGroupDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete an user group by ID
		 *
		 * @tags User Groups
		 * @name DeleteUserGroup
		 * @summary Delete an user group by provided ID
		 * @request DELETE:/userGroups/{userGroupID}
		 * @secure
		 */
		deleteUserGroup: (userGroupId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Add user to a user group
		 *
		 * @tags User Groups
		 * @name AddUserToUserGroup
		 * @summary Add user to a user group
		 * @request POST:/userGroups/{userGroupID}/users/{userID}
		 * @secure
		 */
		addUserToUserGroup: (userGroupId: number, userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users/${userId}`,
				method: 'POST',
				secure: true,
				...params,
			}),

		/**
		 * @description Delete an user from a user group
		 *
		 * @tags User Groups
		 * @name DeleteUserFromUserGroup
		 * @summary Delete an user from a user group
		 * @request DELETE:/userGroups/{userGroupID}/users/{userID}
		 * @secure
		 */
		deleteUserFromUserGroup: (userGroupId: number, userId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users/${userId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Add multiple users to a user group
		 *
		 * @tags User Groups
		 * @name AddUsersToUserGroup
		 * @summary Add multiple users to a user group
		 * @request POST:/userGroups/{userGroupID}/users
		 * @secure
		 */
		addUsersToUserGroup: (userGroupId: number, data: number[], params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Delete multiple users from a user group
		 *
		 * @tags User Groups
		 * @name DeleteUsersFromUserGroup
		 * @summary Delete multiple users from a user group
		 * @request DELETE:/userGroups/{userGroupID}/users
		 * @secure
		 */
		deleteUsersFromUserGroup: (
			userGroupId: number,
			query: {
				/** - IDs of the users */
				userIDs: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/userGroups/${userGroupId}/users`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Get paginated collection of users in a user group by group ID
		 *
		 * @tags User Groups
		 * @name GetUsersInUserGroup
		 * @summary Get paginated collection of users in a user group by group ID
		 * @request GET:/userGroups/{userGroupID}/users
		 * @secure
		 */
		getUsersInUserGroup: (
			userGroupId: number,
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columnsList?: string[];
				/**
				 * - An array of strings used for filtering user groups
				 * @default []
				 */
				filterList?: string[];
				/**
				 * - An array of strings used for sorting user groups
				 * @default []
				 */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUsersInGroupResponseDto, void>({
				path: `/userGroups/${userGroupId}/users`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get paged user details collection from a user group
		 *
		 * @tags User Groups
		 * @name GetUserDetailsInGroup
		 * @summary Get paged user details collection from a user group
		 * @request GET:/userGroups/{userGroupID}/users/detail
		 * @secure
		 */
		getUserDetailsInGroup: (
			userGroupId: number,
			query?: {
				/**
				 * - The offset for paginating through the Identity Stores (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number of Identity Stores to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The columns to retrieve. Defaults to all columns if not provided.
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * The filter criteria to apply.
				 * @default []
				 */
				filter?: string[];
				/**
				 * The sorting criteria to apply.
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetUserDetailsInGroupResponseDto, void>({
				path: `/userGroups/${userGroupId}/users/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	clients = {
		/**
		 * @description Get client application versions
		 *
		 * @tags client
		 * @name GetClientApplicationVersions
		 * @summary Get all client application versions
		 * @request GET:/clients/versions
		 * @secure
		 */
		getClientApplicationVersions: (params: RequestParams = {}) =>
			this.request<ClientVersionsDto, void>({
				path: `/clients/versions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get system versions
		 *
		 * @tags client
		 * @name GetSystemVersions
		 * @summary Get all system versions
		 * @request GET:/clients/systemVersions
		 * @secure
		 */
		getSystemVersions: (params: RequestParams = {}) =>
			this.request<ClientSystemVersionsDto, void>({
				path: `/clients/systemVersions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get clients counts
		 *
		 * @tags client
		 * @name GetCount
		 * @summary Get all clients counts
		 * @request GET:/clients/count
		 * @secure
		 */
		getCount: (params: RequestParams = {}) =>
			this.request<ClientCountDto, void>({
				path: `/clients/count`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get a single client by ID
		 *
		 * @tags client
		 * @name GetOneById
		 * @summary Get a single client by ID
		 * @request GET:/clients/{clientID}
		 * @secure
		 */
		getOneById: (clientId: number, params: RequestParams = {}) =>
			this.request<PartialClientTableRowDto, void>({
				path: `/clients/${clientId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get paginated client collection
		 *
		 * @tags client
		 * @name GetAll
		 * @summary Get paginated client collection
		 * @request GET:/clients
		 * @secure
		 */
		getAll: (
			query?: {
				/** @default [] */
				filter?: string[];
				/** @default [] */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialClientTableRowDto, void>({
				path: `/clients`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	date = {
		/**
		 * @description This endpoint retrieves the current server date and time.
		 *
		 * @tags General
		 * @name GetDate
		 * @summary Get current date and time
		 * @request GET:/date
		 */
		getDate: (params: RequestParams = {}) =>
			this.request<GetDateResponseDto, any>({
				path: `/date`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	saml = {
		/**
		 * No description
		 *
		 * @tags saml
		 * @name GetSigningCertificate
		 * @request GET:/saml/idp/signingCertificate
		 */
		getSigningCertificate: (params: RequestParams = {}) =>
			this.request<CertificateInfoDto, any>({
				path: `/saml/idp/signingCertificate`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags saml
		 * @name DownloadSigningCertificate
		 * @request GET:/saml/idp/signingCertificate/download
		 */
		downloadSigningCertificate: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/saml/idp/signingCertificate/download`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags saml
		 * @name GetEncryptionCertificate
		 * @request GET:/saml/idp/encryptionCertificate
		 */
		getEncryptionCertificate: (params: RequestParams = {}) =>
			this.request<CertificateInfoDto, any>({
				path: `/saml/idp/encryptionCertificate`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags saml
		 * @name DownloadEncryptionCertificate
		 * @request GET:/saml/idp/encryptionCertificate/download
		 */
		downloadEncryptionCertificate: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/saml/idp/encryptionCertificate/download`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	status = {
		/**
		 * @description Get current setup progress step
		 *
		 * @tags Status
		 * @name GetSetupProgress
		 * @summary Get current setup progress step
		 * @request GET:/status/progress
		 */
		getSetupProgress: (params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/status/progress`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Save current setup progress step
		 *
		 * @tags Status
		 * @name SaveCurrentSetupProgressStep
		 * @summary Save current setup progress step
		 * @request POST:/status/progress
		 */
		saveCurrentSetupProgressStep: (data: SaveSetupProgressDto, params: RequestParams = {}) =>
			this.request<boolean, any>({
				path: `/status/progress`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Check APP status
		 *
		 * @tags Status
		 * @name GetAppStatus
		 * @summary Check APP status
		 * @request GET:/status/appStatus
		 */
		getAppStatus: (params: RequestParams = {}) =>
			this.request<EAppStatus, void>({
				path: `/status/appStatus`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Status
		 * @name Registered
		 * @request GET:/status/registered
		 */
		registered: (params: RequestParams = {}) =>
			this.request<
				{
					registered: boolean;
				},
				void
			>({
				path: `/status/registered`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	setup = {
		/**
		 * @description Get current setup progress step
		 *
		 * @tags Setup
		 * @name GetSetupProgress
		 * @summary Get current setup progress step
		 * @request GET:/setup/progress
		 */
		getSetupProgress: (params: RequestParams = {}) =>
			this.request<ESetupSteps, void>({
				path: `/setup/progress`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Setup
		 * @name SetBackSetupProgress
		 * @request POST:/setup/progress/back
		 */
		setBackSetupProgress: (params: RequestParams = {}) =>
			this.request<ESetupSteps, void>({
				path: `/setup/progress/back`,
				method: 'POST',
				format: 'json',
				...params,
			}),

		/**
		 * @description Change the current language of the system
		 *
		 * @tags Setup
		 * @name ChangeSystemLanguage
		 * @request POST:/setup/systemLanguageChange
		 */
		changeSystemLanguage: (data: ChangeLanguageDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/setup/systemLanguageChange`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Resets current progress in deployment setup
		 *
		 * @tags Setup
		 * @name CreateCsr
		 * @request POST:/setup/csr
		 */
		createCsr: (data: CreateCSRDto, params: RequestParams = {}) =>
			this.request<boolean, void>({
				path: `/setup/csr`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Create a new SMTP configuration
		 *
		 * @tags Setup
		 * @name CreateSmtpConfiguration
		 * @summary Create a new SMTP configuration
		 * @request POST:/setup/smtp
		 */
		createSmtpConfiguration: (data: CreateSMTPMailConfigurationDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/setup/smtp`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get SMTP configuration
		 *
		 * @tags Setup
		 * @name GetSmtpConfiguration
		 * @summary Gets only existing SMTP configuration created during the setup process.
		 * @request GET:/setup/smtp
		 */
		getSmtpConfiguration: (params: RequestParams = {}) =>
			this.request<NullableReadSMTPMailConfigurationResponse, void>({
				path: `/setup/smtp`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Verify SMTP configuration
		 *
		 * @tags Setup
		 * @name Verify
		 * @summary Verify SMTP configuration
		 * @request POST:/setup/verify
		 */
		verify: (data: VerifySMTPMailConfigurationDto, params: RequestParams = {}) =>
			this.request<boolean, void>({
				path: `/setup/verify`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Save server setup
		 *
		 * @tags Setup
		 * @name CreateServerSetup
		 * @summary Save server setup
		 * @request POST:/setup/serverSetup
		 */
		createServerSetup: (data: ServerSetupDto, params: RequestParams = {}) =>
			this.request<boolean, void>({
				path: `/setup/serverSetup`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Getserver setup
		 *
		 * @tags Setup
		 * @name GetServerSetup
		 * @summary Save server setup
		 * @request GET:/setup/serverSetup
		 */
		getServerSetup: (params: RequestParams = {}) =>
			this.request<ServerSetupDto, void>({
				path: `/setup/serverSetup`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Restend SMTP verification code
		 *
		 * @tags Setup
		 * @name ResendSmtpVerificationCode
		 * @summary Resend SMTP verification code
		 * @request POST:/setup/resendSMTPVerify
		 */
		resendSmtpVerificationCode: (params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/setup/resendSMTPVerify`,
				method: 'POST',
				...params,
			}),

		/**
		 * @description Create a new identity store
		 *
		 * @tags Setup
		 * @name CreateIdentityStore
		 * @summary Create a new identity store
		 * @request POST:/setup/identityStore
		 */
		createIdentityStore: (data: CreateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<number, void>({
				path: `/setup/identityStore`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get identity store
		 *
		 * @tags Setup
		 * @name GetIdentityStore
		 * @summary Get identity store
		 * @request GET:/setup/identityStore
		 */
		getIdentityStore: (params: RequestParams = {}) =>
			this.request<IdentityStoreDto | null, void>({
				path: `/setup/identityStore`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Authenticate identity store
		 *
		 * @tags Setup
		 * @name AuthenticateIdentityStore
		 * @summary Authenticate to identity store
		 * @request POST:/setup/authenticate
		 */
		authenticateIdentityStore: (data: AuthenticateIdentityStoreDto, params: RequestParams = {}) =>
			this.request<string, void>({
				path: `/setup/authenticate`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	tokens = {
		/**
		 * @description Return list of detailed tokens with optional filtering, sorting and pagination
		 *
		 * @tags Tokens
		 * @name GetTokensDetail
		 * @summary Get tokens
		 * @request GET:/tokens/detail
		 * @secure
		 */
		getTokensDetail: (
			query?: {
				/**
				 * Offset of first row to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * Maximum number of rows to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * Columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/** @default [] */
				filters?: string[];
				/**
				 * Sort expression to sort tokens
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetTokenDetailResponseDto, void>({
				path: `/tokens/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return token information for token with given ID
		 *
		 * @tags Tokens
		 * @name GetToken
		 * @summary Get token by ID
		 * @request GET:/tokens/{tokenID}
		 * @secure
		 */
		getToken: (tokenId: number, params: RequestParams = {}) =>
			this.request<PartialTokenTableRowDto, void>({
				path: `/tokens/${tokenId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a token by ID
		 *
		 * @tags Tokens
		 * @name DeleteToken
		 * @summary Delete token by ID
		 * @request DELETE:/tokens/{tokenID}
		 * @secure
		 */
		deleteToken: (tokenId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/tokens/${tokenId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),

		/**
		 * @description Return list of tokens with optional filtering, sorting and pagination
		 *
		 * @tags Tokens
		 * @name GetTokens
		 * @summary Get tokens
		 * @request GET:/tokens
		 * @secure
		 */
		getTokens: (
			query?: {
				/**
				 * Offset of first row to return
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * Maximum number of rows to return
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * Columns to return
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * Filter expression to filter tokens
				 * @default []
				 */
				filter?: string[];
				/**
				 * Sort expression to sort tokens
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultPartialTokenModel, void>({
				path: `/tokens`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete multiple tokens by their IDs
		 *
		 * @tags Tokens
		 * @name DeleteTokens
		 * @summary Delete multiple tokens by their IDs
		 * @request DELETE:/tokens
		 * @secure
		 */
		deleteTokens: (
			query: {
				/** The IDs of the tokens to delete */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/tokens`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Return list of count of tokens for each application version for each platform
		 *
		 * @tags Tokens
		 * @name GetTokenApplicationVersions
		 * @summary Get application versions
		 * @request GET:/tokens/versions
		 * @secure
		 */
		getTokenApplicationVersions: (params: RequestParams = {}) =>
			this.request<TokenVersionsDto, void>({
				path: `/tokens/versions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return list of count of tokens for each system version for each platform
		 *
		 * @tags Tokens
		 * @name GetTokenSystemVersions
		 * @summary Get system versions
		 * @request GET:/tokens/systemVersions
		 * @secure
		 */
		getTokenSystemVersions: (params: RequestParams = {}) =>
			this.request<TokenSystemVersionsDto, void>({
				path: `/tokens/systemVersions`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return count of online, offline and total tokens
		 *
		 * @tags Tokens
		 * @name GetTokenCount
		 * @summary Get token count
		 * @request GET:/tokens/count
		 * @secure
		 */
		getTokenCount: (params: RequestParams = {}) =>
			this.request<TokenCountDto, void>({
				path: `/tokens/count`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
	securityPolicies = {
		/**
		 * @description Creates a new security policy
		 *
		 * @tags Security Policy
		 * @name CreateSecurityPolicy
		 * @summary Create a new security policy
		 * @request POST:/securityPolicies
		 * @secure
		 */
		createSecurityPolicy: (data: CreateSecurityPolicyRequestDto, params: RequestParams = {}) =>
			this.request<CreateSecurityPolicyResponseDto, void>({
				path: `/securityPolicies`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves a list of security policies
		 *
		 * @tags Security Policy
		 * @name GetSecurityPolicies
		 * @summary Get a list of security policies
		 * @request GET:/securityPolicies
		 * @secure
		 */
		getSecurityPolicies: (
			query?: {
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetSecurityPoliciesResponseDto, void>({
				path: `/securityPolicies`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Deletes multiple security policies by their IDs.
		 *
		 * @tags Security Policy
		 * @name DeleteSecurityPolicies
		 * @summary Delete multiple security policies by ID
		 * @request DELETE:/securityPolicies
		 * @secure
		 */
		deleteSecurityPolicies: (
			query: {
				/**
				 * The IDs of the security policies to delete.
				 * @example [1,2,3]
				 */
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/securityPolicies`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Retrieves a security policy by its ID.
		 *
		 * @tags Security Policy
		 * @name GetSecurityPolicy
		 * @summary Get a security policy by ID
		 * @request GET:/securityPolicies/{id}
		 * @secure
		 */
		getSecurityPolicy: (id: number, params: RequestParams = {}) =>
			this.request<SecurityPolicyModel, void>({
				path: `/securityPolicies/${id}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Updates a security policy by its ID.
		 *
		 * @tags Security Policy
		 * @name UpdateSecurityPolicy
		 * @summary Update a security policy by ID
		 * @request PUT:/securityPolicies/{id}
		 * @secure
		 */
		updateSecurityPolicy: (id: number, data: CreateSecurityPolicyRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/securityPolicies/${id}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Deletes a security policy by its ID.
		 *
		 * @tags Security Policy
		 * @name DeleteSecurityPolicy
		 * @summary Delete a security policy by ID
		 * @request DELETE:/securityPolicies/{id}
		 * @secure
		 */
		deleteSecurityPolicy: (id: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/securityPolicies/${id}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),
	};
	securityPolicy = {
		/**
		 * @description Retrieves user groups for a specific security policy.
		 *
		 * @tags Security Policy
		 * @name GetUserGroupsForSecurityPolicy
		 * @summary Get user groups for a specific security policy
		 * @request GET:/securityPolicy/{id}/userGroups
		 * @secure
		 */
		getUserGroupsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGroupResponseDto, void>({
				path: `/securityPolicy/${id}/userGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves geofences for a specific security policy.
		 *
		 * @tags Security Policy
		 * @name GetGeofencesForSecurityPolicy
		 * @summary Get geofences for a specific security policy
		 * @request GET:/securityPolicy/{id}/geofences
		 * @secure
		 */
		getGeofencesForSecurityPolicy: (id: number, params: RequestParams = {}) =>
			this.request<GeofenceModel[], void>({
				path: `/securityPolicy/${id}/geofences`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves PAM target groups for a specific security policy.
		 *
		 * @tags Security Policy
		 * @name GetPamTargetGroupsForSecurityPolicy
		 * @summary Get PAM target groups for a specific security policy
		 * @request GET:/securityPolicy/{id}/pamTargetGroups
		 * @secure
		 */
		getPamTargetGroupsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGroupResponseDto, void>({
				path: `/securityPolicy/${id}/pamTargetGroups`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves rule sets for a specific security policy
		 *
		 * @tags Security Policy
		 * @name GetRuleSetsForSecurityPolicy
		 * @summary Get rule sets for a specific security policy
		 * @request GET:/securityPolicy/{id}/ruleSets
		 * @secure
		 */
		getRuleSetsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetGroupResponseDto, void>({
				path: `/securityPolicy/${id}/ruleSets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieves detailed rule sets for a specific security policy
		 *
		 * @tags Security Policy
		 * @name GetDetailedRuleSetsForSecurityPolicy
		 * @summary Get detailed rule sets for a specific security policy
		 * @request GET:/securityPolicy/{id}/ruleSets/detail
		 * @secure
		 */
		getDetailedRuleSetsForSecurityPolicy: (
			id: number,
			query?: {
				/**
				 * The maximum number of results to return. Defaults to 20 if not provided.
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * The number of results to skip before starting to return results. Defaults to 0 if not provided.
				 * @format double
				 * @default 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetDetailedRuleSetsResponseDto, void>({
				path: `/securityPolicy/${id}/ruleSets/detail`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	ruleSets = {
		/**
		 * @description Creates a new rule set
		 *
		 * @tags Rule Set
		 * @name CreateRuleSet
		 * @summary Create a new rule set
		 * @request POST:/ruleSets
		 * @secure
		 */
		createRuleSet: (data: CreateRuleSetRequestDto, params: RequestParams = {}) =>
			this.request<CreateSecurityPolicyResponseDto, void>({
				path: `/ruleSets`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get rule set collection
		 *
		 * @tags Rule Set
		 * @name GetRuleSetCollection
		 * @summary Get rule set collection
		 * @request GET:/ruleSets
		 * @secure
		 */
		getRuleSetCollection: (
			query?: {
				/** @default [] */
				filters?: string[];
				/**
				 * The sort order to apply to search results
				 * @default []
				 */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetRuleSetCollectionDto, void>({
				path: `/ruleSets`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a rule sets by IDs
		 *
		 * @tags Rule Set
		 * @name DeleteRuleSets
		 * @summary Delete a rule sets by IDs
		 * @request DELETE:/ruleSets
		 * @secure
		 */
		deleteRuleSets: (
			query: {
				ids: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/ruleSets`,
				method: 'DELETE',
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * @description Updates a rule set by ID
		 *
		 * @tags Rule Set
		 * @name UpdateRuleSet
		 * @summary Update a rule set by ID
		 * @request PUT:/ruleSets/{id}
		 * @secure
		 */
		updateRuleSet: (id: number, data: UpdateRuleSetRequestDto, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/ruleSets/${id}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description Get system rule set collection
		 *
		 * @tags Rule Set
		 * @name GetSystemRuleSetCollection
		 * @summary Get system rule set collection
		 * @request GET:/ruleSets/system
		 * @secure
		 */
		getSystemRuleSetCollection: (
			query?: {
				/** @default [] */
				filters?: string[];
				/**
				 * The sort order to apply to search results
				 * @default []
				 */
				sort?: string[];
				/** @default ["*"] */
				columns?: string[];
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetRuleSetCollectionDto, void>({
				path: `/ruleSets/system`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Retrieve rule set information by ID
		 *
		 * @tags Rule Set
		 * @name GetRuleSet
		 * @summary Get rule set by ID
		 * @request GET:/ruleSets/{ruleSetID}
		 * @secure
		 */
		getRuleSet: (ruleSetId: number, params: RequestParams = {}) =>
			this.request<GetRuleSetResponseDto, void>({
				path: `/ruleSets/${ruleSetId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete a rule set by ID
		 *
		 * @tags Rule Set
		 * @name DeleteRuleSet
		 * @summary Delete a rule set by ID
		 * @request DELETE:/ruleSets/{ruleSetID}
		 * @secure
		 */
		deleteRuleSet: (ruleSetId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/ruleSets/${ruleSetId}`,
				method: 'DELETE',
				secure: true,
				...params,
			}),
	};
	tenantResources = {
		/**
		 * @description Return resources information for the given tenant ID
		 *
		 * @tags TenantResources
		 * @name GetTenantResources
		 * @summary Get tenant resources by tenant ID
		 * @request GET:/tenant-resources
		 * @secure
		 */
		getTenantResources: (
			query?: {
				/**
				 * - The offset for paginating (default is 0)
				 * @format double
				 * @default 0
				 */
				offset?: number;
				/**
				 * - The maximum number items to retrieve (default is 20)
				 * @format double
				 * @default 20
				 */
				limit?: number;
				/**
				 * - An array of strings representing the columns to retrieve
				 * @default ["*"]
				 */
				columns?: string[];
				/**
				 * - An array of strings used for filtering
				 * @default []
				 */
				filter?: string[];
				/**
				 * - An array of strings used for sorting
				 * @default []
				 */
				sort?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<GetTenantResourcesResponseDto, void>({
				path: `/tenant-resources`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),
	};
	actions = {
		/**
		 * @description Get action list
		 *
		 * @tags Actions
		 * @name GetActions
		 * @summary Get action list
		 * @request GET:/actions
		 * @secure
		 */
		getActions: (
			query?: {
				/**
				 * The offset of the first item to return
				 * @format double
				 * @default 0
				 * @example 0
				 */
				offset?: number;
				/**
				 * The maximum number of items to return
				 * @format double
				 * @default 20
				 * @example 20
				 */
				limit?: number;
				/** @default ["*"] */
				columnsList?: string[];
				/** @default [] */
				filterList?: string[];
				/** @default [] */
				sortList?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<PagedResultGetActionCollectionEntityDto, void>({
				path: `/actions`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get action by ID
		 *
		 * @tags Actions
		 * @name GetActionById
		 * @summary Get action by ID
		 * @request GET:/actions/{actionID}
		 * @secure
		 */
		getActionById: (actionId: number, params: RequestParams = {}) =>
			this.request<GetActionDetailDto, void>({
				path: `/actions/${actionId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get action results by action ID
		 *
		 * @tags Actions
		 * @name GetActionResultCollection
		 * @summary Get action results paginated collection by action ID
		 * @request GET:/actions/{actionID}/results
		 * @secure
		 */
		getActionResultCollection: (actionId: number, params: RequestParams = {}) =>
			this.request<GetActionResultDto[], void>({
				path: `/actions/${actionId}/results`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),
	};
}
