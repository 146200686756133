import * as React from 'react';
import { Grid, Box, Button, Typography, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { getRegistrationSchema } from './schema';
import { RegistrationFormState, RegistrationState, UserInvitationDetail } from './types';
import { useDeviceDetect } from '../../hooks/useDeviceDetect';
import AppStoreIcon from '../../assets/icons/AppStoreIcon';
import GooglePlayIcon from '../../assets/icons/GooglePlayIcon';
import { useRegistrationStatus } from '../../hooks/useRegistrationStatus';
import loginBackground from '../../assets/images/login-background.png';
import { PasswordField } from '../../components/FormFields/PasswordField/PasswordField';
import { SEO } from '../../components/SEO/SEO';
import { Preloader } from '../../components/Preloader/Preloader';
import { EInvitationStatus, ELanguageCode } from '../../api/Api';
import humanizeDuration from 'humanize-duration';
import { slovakTimeRelativeViewHumanizationConfig } from '../../utils/WordInflection';

const Background = styled('div')({
	width: '100%',
	height: '100%',
	backgroundImage: `url(${loginBackground})`,
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 2,
	top: 0,
	left: 0,
	color: 'rgba( 0, 0, 0, 0.5 )',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	margin: 'auto',
	maxWidth: 500,
	color: theme.palette.text.primary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#3f464d' : '#00172e',
	'&:hover': {
		backgroundColor: theme.palette.mode === 'dark' ? '#0c1014' : '#052c54',
	},
}));

const MobileStyles = {
	StyledPaper: styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		width: '100vw',
		height: '100vh',
		margin: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		color: theme.palette.text.primary,
	})),
};

export const Registration: React.FC = (): JSX.Element => {
	const device = useDeviceDetect();
	const [loading, setLoading] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const authContext = useAuthContext();
	const api = useSwaggerApi();
	const { i18n } = useTranslation();
	const { language } = i18n;
	const { t } = useTranslation();
	const location = useLocation();
	const [startPolling, setStartPolling] = React.useState(false);
	const isRegistered = useRegistrationStatus('/api/v1/auth/registered', startPolling);
	const PaperComponent = device === 'mobile' ? MobileStyles.StyledPaper : StyledPaper;
	const queryParams = new URLSearchParams(location.search);
	const secret = queryParams.get('invitation') as string;
	const [loginQrCode, setLoginQrCode] = React.useState<string | null>(null);
	const [userDetails, setUserDetails] = React.useState<UserInvitationDetail>({
		username: '',
		email: null,
		companyName: null,
		deploymentName: null,
		name: null,
		surname: null,
		title: null,
		expirationDate: null,
		loaded: false,
		status: null,
	});
	const [registrationState, setRegistrationState] = React.useState<RegistrationState>({
		registering: false,
		registered: false,
		error: null,
	});

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<RegistrationFormState>({
		mode: 'onChange',
		resolver: zodResolver(getRegistrationSchema(t)),
	});

	React.useEffect(() => {
		if (isRegistered) {
			// Redirect to the base URL
			authContext.handleOnLoadUser();
		}
	}, [isRegistered]);

	const handleOnSubmit = React.useCallback(
		async (formValues: RegistrationFormState) => {
			if (registrationState.registering) {
				return;
			}
			try {
				setRegistrationState({
					registering: true,
					registered: false,
					error: null,
				});
				if (!userDetails.username) {
					enqueueSnackbar(t('page.registration.errorMessages.missingUsername'), {
						variant: 'error',
						persist: false,
					});

					return;
				}
				const registrationData = {
					username: userDetails.username,
					password: formValues.password,
					secret,
				};
				const registrationQRResponse = await api.auth.register(registrationData);
				setLoginQrCode(registrationQRResponse.data.qr);
				setStartPolling(true);
				setRegistrationState({
					registering: false,
					registered: true,
					error: null,
				});
				enqueueSnackbar(t('page.registration.actionMessages.successfullyRegistered'), {
					variant: 'success',
					persist: false,
				});
			} catch (error) {
				setRegistrationState({
					registering: false,
					registered: false,
					error: error as AxiosError,
				});
			}
		},
		[secret, userDetails.username],
	);

	const loadUserInvitationDetails = React.useCallback(
		async (secret: string) => {
			try {
				setLoading(true);
				const response = await api.auth.getUserInvitationDetail(secret);
				setUserDetails({
					username: response.data.username,
					email: response.data.email,
					companyName: response.data.companyName,
					deploymentName: response.data.deploymentName,
					name: response.data.name,
					surname: response.data.surname,
					title: response.data.title,
					expirationDate: response.data.expiresAt,
					loaded: true,
					status: response.data.status,
				});
				setLoading(false);
			} catch (error) {
				setLoading(false);
				setIsError(true);
				console.error(error);
			}
		},
		[location.search, loading],
	);

	React.useEffect(() => {
		if (secret && !loading && !userDetails.loaded && !isError) {
			loadUserInvitationDetails(secret);
		}
	}, [location.search, loading, userDetails]);

	const getStatusMessage = React.useCallback(() => {
		switch (userDetails.status) {
			case EInvitationStatus.Blocked:
				return (
					<Typography align='center'>
						<Trans
							t={t}
							i18nKey='page.registration.errorMessages.blocked'
							values={{
								username: userDetails.username,
								name: userDetails.name,
								surname: userDetails.surname,
								title: userDetails.title,
							}}
						/>
					</Typography>
				);
			case EInvitationStatus.Dismissed:
			case EInvitationStatus.Accepted:
				return <Typography align='center'>{t('page.registration.errorMessages.dismissed')}</Typography>;
			default:
				return null;
		}
	}, [userDetails, t]);

	const getHumanizedExpiredTime = React.useCallback(() => {
		const now = new Date();
		if (userDetails.expirationDate === null) {
			return '';
		}
		const expirationDate = new Date(userDetails.expirationDate);
		const diff = expirationDate.getTime() - now.getTime();
		if (diff >= 0) {
			return '';
		}
		if (language === ELanguageCode.Sk) {
			humanizeDuration.languages.sk = slovakTimeRelativeViewHumanizationConfig;
		}
		const humanized = humanizeDuration(diff, {
			language: language,
			fallbacks: ['en'],
			round: true,
			largest: 3,
			units: ['y', 'mo', 'd', 'h', 'm', 's'],
			conjunction: t('page.registration.expiration.conjuction'),
			serialComma: false,
		});

		return `${expirationDate.toLocaleString(language)} (${t('page.registration.expiration.totally')}: ${humanized} )`;
	}, [userDetails, t, language]);

	const renderContent = () => {
		if (
			userDetails.status === EInvitationStatus.Blocked ||
			userDetails.status === EInvitationStatus.Dismissed ||
			userDetails.status === EInvitationStatus.Accepted
		) {
			return getStatusMessage();
		}

		if (userDetails.username && userDetails.email && userDetails.expirationDate) {
			return new Date(userDetails.expirationDate) > new Date() ?
					<>
						<Typography align='center'>
							<Trans
								t={t}
								i18nKey='page.registration.text'
								values={{
									username: userDetails.username,
									email: userDetails.email,
									companyName: userDetails.companyName,
									deploymentName: userDetails.deploymentName,
									name: userDetails.name,
									surname: userDetails.surname,
									title: userDetails.title,
								}}
							/>
						</Typography>
						<Grid item xs={12}>
							<PasswordField
								name={'password'}
								register={register}
								label={t('page.registration.form.password.label')}
								error={errors.password}
								disabled={registrationState.registering}
								helperText={t('page.registration.form.password.helperText')}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<StyledButton
								fullWidth={device !== 'desktop'}
								variant='contained'
								type='submit'
								onClick={handleSubmit(handleOnSubmit)}
							>
								{t('page.registration.form.submitButton')}
							</StyledButton>
						</Grid>
					</>
				:	<Typography align='center'>
						<Trans
							t={t}
							i18nKey='page.registration.errorMessages.expired'
							values={{
								name: userDetails.name,
								surname: userDetails.surname,
								title: userDetails.title,
								date: getHumanizedExpiredTime(),
							}}
							components={{ redText: <span style={{ color: 'red' }} /> }}
						/>
					</Typography>;
		}

		return <Typography align='center'>{t('page.registration.errorMessages.notFound')}</Typography>;
	};

	return (
		<Background>
			<SEO title={t('page.registration.title')} description={t('page.registration.description')} />
			{loading ?
				<PaperComponent
					sx={{
						mx: 'auto',
						width: '30%',
					}}
				>
					<Preloader percentageHeight={30} />
				</PaperComponent>
			:	<>
					{loginQrCode && registrationState.registered ?
						<PaperComponent
							sx={{
								mx: 'auto',
								p: 4,
							}}
						>
							<Grid container wrap='wrap' spacing={2}>
								<Grid item xs={12}>
									<Typography variant='h5' align='center'>
										{t('page.registration.title')}
									</Typography>
								</Grid>
								<Grid item>
									<Typography align='center' variant='h6'>
										{t('page.login.text.qr')}
									</Typography>
								</Grid>
								<Grid item xs zeroMinWidth>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'none',
										}}
										height={350}
									>
										<Box
											loading='lazy'
											component='img'
											width={350}
											src={`data:image/svg+xml;utf8,${encodeURIComponent(loginQrCode)}`}
											draggable={false}
										/>
									</Box>
								</Grid>
							</Grid>
							<Typography align='center' pb={2} mt={5}>
								{t('page.login.text.mobileApp')}
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs>
									<Link
										href='https://play.google.com/store/apps/details?id=com.getexcalibur.enterprise'
										target='_blank'
										rel='noreferrer'
									>
										<GooglePlayIcon />
									</Link>
								</Grid>
								<Grid item xs>
									<Link
										href='https://itunes.apple.com/us/app/excalibur/id1377064612'
										target='_blank'
										rel='noreferrer'
									>
										<AppStoreIcon />
									</Link>
								</Grid>
							</Grid>
						</PaperComponent>
					:	<PaperComponent
							sx={{
								mx: 'auto',
								p: 4,
							}}
						>
							<Grid
								container
								spacing={2}
								justifyContent='center'
								sx={{
									padding: 2,
								}}
							>
								<Grid item xs={12}>
									<Typography variant='h5' align='center'>
										{t('page.registration.title')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{renderContent()}
								</Grid>
							</Grid>
						</PaperComponent>
					}
				</>
			}
		</Background>
	);
};
