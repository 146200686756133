import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { Tooltip, Box, IconButton, Paper, Stack, Avatar, Chip } from '@mui/material';
import {
	Autorenew as AutorenewIcon,
	Close as CloseIcon,
	DoDisturbAlt as DoDisturbAltIcon,
	SaveAlt as SaveAltIcon,
	LibraryBooks as LibraryBooksIcon,
	Done as DoneIcon,
	Schedule as ScheduleIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

import SSHIcon from '../../assets/images/ssh-icon.png';
import RDPIcon from '../../assets/images/rdp-icon.png';
import VNCIcon from '../../assets/images/vnc-icon.png';
import PersonIcon from '../../assets/images/person-logo.png';
import ExcaliburIcon from '../../assets/images/excalibur-logo.png';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { actionListSchema } from './schema';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { ActionDetail } from './Components/ActionDetail/ActionDetail';
import { EActionType, GetActionCollectionEntityDto, PagedResultGetActionCollectionEntityDto } from '../../api/Api';
import { Target } from './Components/Target/Target';
import { EActionStatus } from './enums';

export const ActionList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const formatDate = useFormatDate();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [selectedActionID, setSelectedActionID] = React.useState<number | null>(null);

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['']);
	// } = useTableQuery([
	// 	'userFullName',
	// 	'accountName',
	// 	'targetName',
	// 	'targetType',
	// 	'locationName',
	// 	'createdAt',
	// 	'action'
	// ]);

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultGetActionCollectionEntityDto>({
		queryKey: [EQueryKey.ACTION_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filters: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.actions.getActions(query);

				response.data.entities.forEach((action) => {
					actionListSchema.parse(action);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	// const getTargetLogo = React.useCallback((type: string | undefined): string => {
	// 	if (!type) {
	// 		return '';
	// 	}
	// 	const targetTypeLogos = new Map<string, string>([
	// 		['user', PersonIcon],
	// 		['dashboard', ExcaliburIcon],
	// 		['ssh', SSHIcon],
	// 		['rdp', RDPIcon],
	// 		['vnc', VNCIcon],
	// 	]);

	// 	const logo = targetTypeLogos.get(type.trim().toLocaleLowerCase());

	// 	return logo ? logo : '';
	// }, []);

	const renderChip = React.useMemo(() => {
		const renderStatusChip = (value: string | undefined): JSX.Element => {
			if (!value) {
				return (
					<Chip
						label={t('page.action.list.table.body.unknown')}
						sx={{ bgcolor: '#bdbdbd', color: 'white' }}
					/>
				);
			}

			switch (value) {
				case EActionStatus.COMPLETED:
					return (
						<Chip
							icon={<DoneIcon />}
							label={t('page.action.list.table.body.completed')}
							color='success'
							sx={{ width: '100%' }}
						/>
					);
				case EActionStatus.FAILED:
					return (
						<Chip
							icon={<CloseIcon />}
							label={t('page.action.list.table.body.failed')}
							color='error'
							sx={{ width: '100%' }}
						/>
					);
				case EActionStatus.ONGOING:
					return (
						<Chip
							icon={<AutorenewIcon />}
							label={t('page.action.list.table.body.ongoing')}
							color='primary'
							sx={{ width: '100%' }}
						/>
					);
				case EActionStatus.TIMEOUTED:
					return (
						<Chip
							icon={<ScheduleIcon />}
							label={t('page.action.list.table.body.timeouted')}
							color='warning'
							sx={{ width: '100%' }}
						/>
					);
				case EActionStatus.CANCELLED:
					return (
						<Chip
							icon={<DoDisturbAltIcon />}
							label={t('page.action.list.table.body.cancelled')}
							color='error'
							sx={{ width: '100%' }}
						/>
					);
				default:
					return (
						<Chip
							label={t('page.action.list.table.body.unknown')}
							sx={{ bgcolor: '#bdbdbd', color: 'white', width: '100%' }}
						/>
					);
			}
		};

		return renderStatusChip;
	}, [t]);

	const handleOnShowDetail = React.useCallback(
		(actionID: number | undefined) => (event: React.MouseEvent) => {
			if (!actionID) {
				return;
			}
			setSelectedActionID(actionID ? actionID : null);
			setOpen(true);
		},
		[],
	);

	const handleOnCloseDetail = React.useCallback(() => {
		setOpen(false);
	}, []);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<GetActionCollectionEntityDto>>[]>(
		() => [
			{
				accessorKey: 'action',
				header: t('page.action.list.table.header.action'),
				filterVariant: 'select',
				filterSelectOptions: Object.values(EActionType),
			},
			{
				accessorFn: (user) =>
					`${user.useTitle ? `${user.useTitle} ` : ''}${user.userName} ${user.userSurname ? user.userSurname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.action.list.table.header.user'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={String(renderedCellValue)} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'accountName',
				header: t('page.action.list.table.header.account'),
			},
			{
				accessorKey: 'targetName',
				header: t('page.action.list.table.header.target'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Target
							targetID={row.original.targetID as number}
							targetType={row.original.targetType as number}
							targetName={renderedCellValue as string}
						/>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.location?.name ? row.location.name : ''}`,
				accessorKey: 'locationName',
				enableColumnFilter: false,
				enableGlobalFilter: false,
				enableSorting: false,
				header: t('page.action.list.table.header.location'),
			},
			{
				accessorFn: (row) => `${row.status}`,
				accessorKey: 'status',
				filterVariant: 'select',
				filterSelectOptions: Object.values(EActionStatus).map((status) => ({
					label: t(`page.action.list.table.body.${status}`),
					value: status,
				})),
				header: t('page.action.list.table.header.status'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Box
							sx={{
								width: 115,
							}}
						>
							{renderChip(row.original.status)}
						</Box>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				header: t('page.action.list.table.header.time'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
		],
		[isAllowed, entities],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.action.list.title')}
						description={t('page.action.list.description')}
						icon={LibraryBooksIcon}
					/>
					<Stack>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
							<MaterialReactTable
								layoutMode='grid'
								columns={columns}
								data={entities}
								enableStickyHeader={false}
								state={{
									isLoading: isLoading,
									showAlertBanner: error !== null,
									pagination,
									showProgressBars: isRefetching,
									columnFilters,
									globalFilter,
									sorting,
									columnVisibility,
								}}
								muiToolbarAlertBannerProps={{
									color: 'error',
									children: <>{error}</>,
								}}
								initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
								rowCount={total}
								manualPagination
								manualFiltering
								manualSorting
								onSortingChange={setSorting}
								onGlobalFilterChange={setGlobalFilter}
								onColumnFiltersChange={setColumnFilters}
								onPaginationChange={setPagination}
								onColumnVisibilityChange={setColumnVisibility}
								getRowId={(originalRow) => originalRow.id?.toString() || ''}
								renderToolbarInternalActions={({ table }) => (
									<Box sx={{ display: 'flex', gap: '1rem' }}>
										<MRTToggleGlobalFilterButton table={table} />
										<MRTToggleFiltersButton table={table} />
										<MRTShowHideColumnsButton table={table} />
										<Tooltip title={t('page.action.list.tooltips.export')} enterDelay={500}>
											<IconButton>
												<SaveAltIcon />
											</IconButton>
										</Tooltip>
										<MRTToggleDensePaddingButton table={table} />
										<MRTFullScreenToggleButton table={table} />
									</Box>
								)}
								displayColumnDefOptions={{
									'mrt-row-actions': {
										muiTableHeadCellProps: {
											align: 'center',
										},
										size: 120,
										enableHiding: true,
									},
									'mrt-row-select': {
										enableHiding: true,
										visibleInShowHideMenu: false,
									},
								}}
								muiTablePaperProps={({ table }) => ({
									style: {
										zIndex: table.getState().isFullScreen ? 1100 : undefined,
										boxShadow: 'none',
										outline: '1px solid #e0e0e0',
									},
								})}
								muiSelectCheckboxProps={() => ({
									sx: {
										width: '50px',
										height: '50px',
									},
								})}
								muiSelectAllCheckboxProps={() => ({
									sx: {
										width: '50px',
										height: '50px',
									},
								})}
								muiTableHeadCellProps={() => ({
									sx: {
										verticalAlign: 'baseline',
									},
								})}
								editDisplayMode='modal'
								localization={MRTLocalization}
								positionActionsColumn='last'
								muiTableBodyRowProps={({ row }) => ({
									onClick:
										isAllowed([EPermission.ACTIONS_READ_ALL, EPermission.ACTIONS_READ_OWN], false) ?
											handleOnShowDetail(row.original.id)
										:	undefined,
									sx: { cursor: 'pointer' },
								})}
							/>
						</LocalizationProvider>
					</Stack>
				</Stack>
			</Paper>
			{isAllowed([EPermission.ACTIONS_READ_ALL, EPermission.ACTIONS_READ_OWN], false) && (
				<ActionDetail actionID={selectedActionID} open={open} onClose={handleOnCloseDetail} />
			)}
		</Box>
	);
};
