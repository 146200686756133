import * as React from 'react';
import {
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_PaginationState as MRTPaginationState,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_SortingState as MRTSortingState,
	MaterialReactTable,
} from 'material-react-table';
import { Box, IconButton, InputBase, Paper, Tooltip, Typography, Avatar, Stack } from '@mui/material';
import {
	SaveAlt as SaveAltIcon,
	ManageSearch as ManageSearchIcon,
	PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { formatElapsedTime } from '../../utils/SessionDateHelpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PAMUserInputDto, PAMUserInputSessionDto, PagedResultPAMUserInputSessionDto } from '../../api/Api';
import { userInputListSchema } from './schema';
import { getSessionTargetLogo } from '../sessions/utils';
import { SessionPlayerDialog } from '../../components/Dialog/SessionPlayerDialog/SessionPlayerDialog';

import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useDebounce } from '../../hooks/useDebounce';
import { useFormatDate } from '../../hooks/useFormatDate';
import { SearchedUserInput } from '../../components/PAMSessionPlayer/types';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';

export const PAMFullTextSearch: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	const { isAllowed } = useACL();
	const formatDate = useFormatDate();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [open, setOpen] = React.useState(false);
	const [recordingUrl, setRecordingUrl] = React.useState('');
	const [startPosition, setStartPosition] = React.useState<number>(0);
	const [selectedUserInput, setSelectedUserInput] = React.useState<SearchedUserInput[]>([]);
	const debouncedSearchTerm = useDebounce(searchTerm, 300);

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['target', 'userFullName', 'name', 'surname', 'account', 'startAt', 'endAt']);

	const handleOnSetSearchTerm = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setSearchTerm(event.target.value);
	}, []);

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultPAMUserInputSessionDto>({
		queryKey: [EQueryKey.SESSION_SEARCH_TERM_QUERY, debouncedSearchTerm, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.pam.getUserInput(debouncedSearchTerm, query);
				response.data.entities.forEach((session) => {
					userInputListSchema.parse(session);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
		enabled: !!debouncedSearchTerm,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const elapsedSeconds = React.useCallback((startAt: string, endAt: string) => {
		const sessionStartTime = new Date(startAt);
		const userInputTime = new Date(endAt);
		const elapsedSeconds = Math.floor((userInputTime.getTime() - sessionStartTime.getTime()) / 1000);

		return elapsedSeconds;
	}, []);

	const handlePlaySession = React.useCallback(
		(recordingUrl: string, startPosition: number, userInput: PAMUserInputDto[], startRecording: string) => () => {
			if (!recordingUrl) {
				return;
			}
			const selectedUserInputList = userInput.map((input, index) => {
				return {
					id: index,
					line: input.line,
					position: elapsedSeconds(startRecording, input.startAt),
					searchTerm,
				};
			});
			setSelectedUserInput(selectedUserInputList);
			setStartPosition(startPosition * 1000);
			setRecordingUrl(recordingUrl);
			setOpen(true);
		},
		[searchTerm],
	);

	const handleOnClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const columns: MRTColumnDef<PAMUserInputSessionDto>[] = React.useMemo(
		() => [
			{
				accessorFn: (row) => `${row.targetName}`,
				accessorKey: 'target',
				header: t('page.sessions.table.header.target'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Tooltip arrow placement='left' title={row.original.targetType}>
							<img
								alt='Logo'
								height={42}
								src={getSessionTargetLogo(row.original.targetType)}
								loading='lazy'
								style={{ borderRadius: 'none' }}
							/>
						</Tooltip>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.userName} ${row.userSurname ? row.userSurname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.sessions.table.header.userName'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${row.original.userName}'s avatar.`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.accountName}`,
				accessorKey: 'account',
				header: t('page.sessions.table.header.account'),
			},
			{
				accessorFn: (row) => `${formatDate(row.startAt, true)}`,
				accessorKey: 'startAt',
				filterVariant: 'datetime-range',
				header: t('page.sessions.table.header.start'),
				minSize: 340,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
			{
				accessorKey: 'occurrences',
				header: t('page.sessions.table.header.occurrences'),
				enableSorting: false,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				minSize: 140,
				size: 140,
				Cell: ({ row }) => <Typography>{row.original.userInput.length}x</Typography>,
			},
		],
		[t, language, searchTerm, entities],
	);

	const highlightSearchTerm = React.useCallback(
		(text: string): JSX.Element => {
			if (!searchTerm) {
				return <>{text}</>;
			}

			const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));

			return (
				<>
					{parts.map((part, index) => {
						if (part.toLowerCase() === searchTerm.toLowerCase()) {
							return (
								<span key={`serch-term-${index}`} style={{ color: 'red' }}>
									{part}
								</span>
							);
						}

						return <span key={`user-input-${index}`}>{part}</span>;
					})}
				</>
			);
		},
		[searchTerm],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	return (
		<Box sx={{ marginBottom: 10 }}>
			{isAllowed([EPermission.PAM_RECORDINGS_READ]) && recordingUrl && (
				<SessionPlayerDialog
					open={open}
					src={recordingUrl}
					startPosition={startPosition}
					userInput={selectedUserInput}
					onClose={handleOnClose}
				/>
			)}

			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.sessions.fullTextSearch.title')}
						description={t('page.sessions.fullTextSearch.description')}
						icon={ManageSearchIcon}
					/>
					<Paper
						component='div'
						variant='outlined'
						sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}
					>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder='Search'
							inputProps={{ 'aria-label': 'search full text' }}
							value={searchTerm}
							onChange={handleOnSetSearchTerm}
						/>
					</Paper>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							layoutMode='grid'
							columns={columns}
							data={entities}
							enableRowActions
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							renderRowActions={({ row }) => (
								<Box>
									{isAllowed([EPermission.PAM_RECORDINGS_READ]) && row.original.recordingPath && (
										<Tooltip
											title={t('page.sessions.tooltips.playRecording')}
											placement='left'
											enterDelay={500}
											arrow
										>
											<IconButton
												onClick={handlePlaySession(
													row.original.recordingPath,
													0,
													row.original.userInput,
													row.original.startAt,
												)}
												color='success'
											>
												<PlayArrowIcon />
											</IconButton>
										</Tooltip>
									)}
								</Box>
							)}
							renderToolbarInternalActions={({ table }) => (
								<Box>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.sessions.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}

									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							renderDetailPanel={({ row }) => (
								<Box>
									{row.original.userInput.map((userInput, index) => {
										const formatedSeconds = elapsedSeconds(row.original.startAt, userInput.startAt);

										return (
											<Box
												key={index}
												sx={{
													display: 'flex',
													alignItems: 'center',
													margin: '12px',
												}}
											>
												<Typography>{formatElapsedTime(formatedSeconds)}</Typography>
												<Typography ml='2%'>{highlightSearchTerm(userInput.line)}</Typography>
												{isAllowed([EPermission.PAM_RECORDINGS_READ]) &&
													row.original.recordingPath && (
														<Tooltip
															sx={{ marginLeft: 'auto' }}
															title={t('page.sessions.tooltips.playRecording')}
															placement='left'
															enterDelay={500}
															arrow
														>
															<IconButton
																onClick={handlePlaySession(
																	row.original.recordingPath,
																	formatedSeconds,
																	row.original.userInput,
																	row.original.startAt,
																)}
																color='success'
															>
																<PlayArrowIcon />
															</IconButton>
														</Tooltip>
													)}
											</Box>
										);
									})}
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.sessions.table.header.actions'),
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
								'mrt-row-expand': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							localization={MRTLocalization}
							positionActionsColumn='last'
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>
		</Box>
	);
};
