import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { Tooltip, Box, IconButton, Paper, Stack } from '@mui/material';
import {
	ContentCopy as ContentCopyIcon,
	SaveAlt as SaveAltIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Info as InfoIcon,
	Workspaces as WorkspacesIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Link } from '../../components/Link/Link';
import { useNavigate } from '../../hooks/useNavigate';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { userGroupListSchema } from './schema';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { PagedResultUserGroupDto, UserGroupDto } from '../../api/Api';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

export const UserGroupList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [userIDToDelete, setUserIDToDelete] = React.useState<number | null>(null);
	const [multiUserIDToDelete, setMultiUserIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'createdAt', 'updatedAt']);

	const { data, isError, isRefetching, isLoading, error } = useQuery<PagedResultUserGroupDto>({
		queryKey: [EQueryKey.USER_GROUP_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columnsList: swaggerQuery.columns,
					filterList: swaggerQuery.filter,
					sortList: swaggerQuery.sort,
				};
				const response = await api.userGroups.getUserGroups(query);
				response.data.entities.forEach((group) => {
					userGroupListSchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.userGroups.list.confirmation.delete.title'));
				setConfirmationText(t('page.userGroups.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.userGroups.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.userGroups.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenGroupDeleteDialog = React.useCallback(
		(userID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setUserIDToDelete(userID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[handleOnChangeConfirmDialog, isAllowed],
	);

	const handleOnOpenMultipleGroupsDelete = React.useCallback(
		(table: MRTTableInstance<Partial<UserGroupDto>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.userGroups.list.errorMessages.noUserGroupSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiUserIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed, enqueueSnackbar, t, handleOnChangeConfirmDialog],
	);

	const handleOnConfirmGroupDelete = React.useCallback(async () => {
		if (userIDToDelete) {
			try {
				await api.userGroups.deleteUserGroup(userIDToDelete);
				enqueueSnackbar(t('page.userGroups.list.actionMessages.userGroupSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setUserIDToDelete(null);
				updateRowSelection([userIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiUserIDToDelete.ids.length > 0) {
			try {
				await api.userGroups.deleteUserGroups(multiUserIDToDelete);
				enqueueSnackbar(t('page.userGroups.list.actionMessages.userGroupsSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiUserIDToDelete({ ids: [] });
				updateRowSelection(multiUserIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [
		isAllowed,
		enqueueSnackbar,
		t,
		userIDToDelete,
		multiUserIDToDelete,
		api,
		reactQueryClient,
		rowSelection,
		handleOnChangeConfirmDialog,
		isAllowed,
	]);

	const handleClose = React.useCallback(() => setOpen(false), []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	React.useEffect(() => {
		if (!isError) {
			return;
		}

		if (error instanceof AxiosError) {
			const errorResponse = error.response?.data.message ? error.response?.data.message : error.message;
			enqueueSnackbar(errorResponse, {
				variant: 'error',
				persist: false,
			});
		} else {
			enqueueSnackbar((error as any).toString(), {
				variant: 'error',
				persist: false,
			});
		}
	}, [isError]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<UserGroupDto>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.userGroups.list.table.header.name'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
						{row.original.description && (
							<Tooltip arrow placement='right' title={row.original.description} enterDelay={500}>
								<InfoIcon color='info' />
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.totalUsers}`,
				header: t('page.userGroups.list.table.header.totalUsers'),
				accessorKey: 'totalUsers',
				enableGlobalFilter: false,
				enableColumnFilter: false,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				header: t('page.userGroups.list.table.header.createdAt'),
				size: 300,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.updatedAt, true)}`,
				accessorKey: 'updatedAt',
				filterVariant: 'datetime-range',
				header: t('page.userGroups.list.table.header.updatedAt'),
				size: 300,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
		],
		[isAllowed],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.userGroups.list.title')}
						description={t('page.userGroups.list.description')}
						icon={WorkspacesIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							columns={columns}
							data={entities}
							enableRowActions
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								rowSelection,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							enableRowSelection={(row) =>
								!row.original.isBuiltIn && isAllowed([EPermission.USER_GROUPS_DELETE])
							}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							onRowSelectionChange={setRowSelection}
							renderRowActions={({ row }) => {
								if (row.original.isBuiltIn) {
									return (
										<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
											<IconButton disabled={true}>
												<EditIcon />
											</IconButton>

											<IconButton disabled={true} color='error'>
												<DeleteIcon />
											</IconButton>
										</Box>
									);
								}

								return (
									<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
										{isAllowed([EPermission.USER_GROUPS_CREATE]) && (
											<Tooltip
												arrow
												placement='left'
												title={t('page.userGroups.list.tooltips.duplicate')}
												enterDelay={500}
											>
												<IconButton
													onClick={(
														event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
													) => {
														event.stopPropagation();
														navigate('/users/userGroups/new', {
															state: { copyFromId: row.original.id },
														});
													}}
												>
													<ContentCopyIcon />
												</IconButton>
											</Tooltip>
										)}
										{isAllowed([EPermission.USER_GROUPS_UPDATE]) && (
											<Tooltip
												arrow
												placement='left'
												title={t('page.userGroups.list.tooltips.edit')}
												enterDelay={500}
											>
												<IconButton
													onClick={handleRedirect(
														`/users/userGroups/edit/${row.original.id}`,
													)}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
										)}
										{isAllowed([EPermission.USER_GROUPS_DELETE]) && (
											<Tooltip
												arrow
												placement='right'
												title={t('page.userGroups.list.tooltips.delete')}
												enterDelay={500}
											>
												<IconButton
													color='error'
													onClick={handleOnOpenGroupDeleteDialog(row.original.id as number)}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										)}
									</Box>
								);
							}}
							renderToolbarInternalActions={({ table }) => (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip arrow title={t('page.userGroups.list.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									{isAllowed([EPermission.USER_GROUPS_DELETE]) && (
										<Tooltip
											arrow
											title={t('page.userGroups.list.tooltips.removeSelected')}
											enterDelay={500}
										>
											<span>
												<IconButton
													color='error'
													disabled={table.getSelectedRowModel().rows.length === 0}
													onClick={handleOnOpenMultipleGroupsDelete(table)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.userGroups.list.table.header.actions'),
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							editDisplayMode='modal'
							positionActionsColumn='last'
							muiTableBodyRowProps={({ row }) => ({
								onClick:
									isAllowed([EPermission.USER_GROUPS_READ]) ?
										handleRedirect(`/users/userGroups/${row.original.id}`)
									:	undefined,
								sx: { cursor: 'pointer' },
							})}
							localization={MRTLocalization}
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>

			{isAllowed([EPermission.USER_GROUPS_CREATE]) && (
				<Link to='/users/userGroups/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.userGroups.list.ariaLabel.createGroup')}
						tooltipTitle={t('page.userGroups.list.tooltips.addGroup')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.USER_GROUPS_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmGroupDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.userGroups.list.confirmation.delete.cancel')}
					confirmText={t('page.userGroups.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
