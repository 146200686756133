import { MainLayout } from '../../layouts/MainLayout';
import { AuthLayout } from '../../layouts/AuthLayout';
import { LoginPage } from '../../pages/login/LoginPage';
import { DeviceStatistics } from '../../pages/overview/DeviceStatistics';
import { UsersPage } from '../../pages/users/UsersPage';
import { PAMPage } from '../../pages/pam/PAMPage';
import { PAMAddSshTarget } from '../../pages/pam/ssh/PAMAddSshTarget';
import { UserSettingsPage } from '../../pages/settings/UserSettingsPage';
import { SAMLAddServiceProvider } from '../../pages/saml/SAMLAddServiceProvider';
import { SAMLIdentityProviderConfiguration } from '../../pages/saml/SAMLIdentityProviderConfiguration';
import { SAMLServiceProvidersLists } from '../../pages/saml/providers/SAMLServiceProvidersLists';
import { SAMLEditServiceProvider } from '../../pages/saml/providers/SAMLEditServiceProvider';
import { StatisticsPage } from '../../pages/overview/StatisticsPage';
import { PAMAddRDPTarget } from '../../pages/pam/rdp/PAMAddRDPTarget';
import { SessionsPage } from '../../pages/overview/SessionsPage';
import { IncidentsPage } from '../../pages/overview/IncidentsPage';
import { ActionsPage } from '../../pages/overview/ActionsPage';
import { ServerPage } from '../../pages/overview/ServerPage';
import { SetupWizardPage } from '../../pages/setup/SetupWizardPage';
import { ClientsPage } from '../../pages/clients/ClientsPage';
import { GeofencesPage } from '../../pages/geofences/GeofencesPage';
import { NotFoundPage } from '../../pages/404/NotFound';
import { SmtpSettingsPage } from '../../pages/settings/SmtpSettingsPage';
import { SmtpAddPage } from '../../pages/smtp/SmtpAddPage';
import { RolesPage } from '../../pages/roles/RolesPage';
import { AddEditRole } from '../../pages/roles/AddEditRole';
import { InvitationsPage } from '../../pages/invitations/InvitationsPage';
import { AddInvitationsPage } from '../../pages/invitations/AddInvitationsPage';
import { PAMFullTextSearch } from '../../pages/pam/PAMFullTextSearch';
import { SessionListPage } from '../../pages/sessions/SessionListPage';
import { SessionDetailPage } from '../../pages/sessions/SessionDetailPage';
import { OAuthClientList } from '../../pages/oAuthClients/OAuthClientList';
import { OAuthClientDetail } from '../../pages/oAuthClients/OAuthClientDetail';
import { OAuthClientAddEdit } from '../../pages/oAuthClients/OAuthClientAddEdit';
import { AddWebhook } from '../../pages/oAuthClients/webhooks/AddWebhook';
import { EditWebhook } from '../../pages/oAuthClients/webhooks/EditWebhook';
import { TenantList } from '../../pages/tenants/TenantList';
import { TenantAddEdit } from '../../pages/tenants/TenantAddEdit';
import { LDAPIdentityStore } from '../../pages/identityStore/ldap/LDAPAddIdentityStore';
import { IdentityStoreList } from '../../pages/identityStore/ldap/IdentityStoreList';
import { UserGroupList } from '../../pages/userGroups/UserGroupList';
import { UserGroupDetail } from '../../pages/userGroups/UserGroupDetail';
import { UserGroupAddEdit } from '../../pages/userGroups/UserGroupAddEdit';
import { TenantDetail } from '../../pages/tenants/TenantDetail';
import { PAMAddTargetGroup } from '../../pages/pam/groups/PAMAddTargetGroup';
import { PAMTargetGroupList } from '../../pages/pam/groups/PAMTargetGroupList';
import { PAMTargetGroupDetail } from '../../pages/pam/groups/PAMTargetGroupDetail';
import { Registration } from '../../pages/registration/Registration';
import { SecurityPolicyList } from '../../pages/securityPolicy/SecurityPolicyList';
import { AddSecurityPolicy } from '../../pages/securityPolicy/AddSecurityPolicy';
import { SecurityPolicyDetail } from '../../pages/securityPolicy/SecurityPolicyDetail';
import { UnauthorizedPage } from '../../pages/401/Unauthorized';
import { ServerSettingPage } from '../../pages/settings/general/ServerSettingPage';
import { GeofenceGroupList } from '../../pages/geofences/groups/GeofenceGroupsList';
import { MapSettingPage } from '../../pages/settings/map/MapSettingPage';
import { AddEditGeofenceGroup } from '../../pages/geofences/groups/AddEditGeofenceGroup';
import { PAMDetail } from '../../pages/pam/PAMDetail';
import { AddEditRuleSet } from '../../pages/ruleSet/AddEditRuleSet';
import { About } from '../../pages/about/About';

import { GroupRoute, PageRoute } from './types';
import { EPermission } from '../../enums/permission/EPermission';
import { ExpirationTimePage } from '../../pages/settings/ExpirationTimesPage';
import { RuleSetList } from '../../pages/ruleSet/RuleSetList';
import { TokenList } from '../../pages/tokens/TokenList';
import { UserDetailPage } from '../../pages/users/UserDetailPage';
import { ActionList } from '../../pages/actions/ActionList';

const commonRoutes: PageRoute[] = [
	{
		path: 'overview/statistics',
		element: StatisticsPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'overview/devices',
		element: DeviceStatistics,
		permissions: [EPermission.ALL],
	},
	{
		path: 'overview/sessions',
		element: SessionsPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'overview/incidents',
		element: IncidentsPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'overview/actions',
		element: ActionsPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'overview/server',
		element: ServerPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'overview/server',
		element: ServerPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'me',
		element: UserDetailPage,
		permissions: [EPermission.ALL],
	},
	{
		path: 'me/settings',
		element: UserSettingsPage,
	},
	{
		path: 'users',
		element: UsersPage,
		permissions: [EPermission.USERS_READ_ALL, EPermission.USERS_READ_SUBORDINATES],
	},
	{
		path: 'users/:userID/detail',
		element: UserDetailPage,
		permissions: [EPermission.USERS_READ_ALL, EPermission.USERS_READ_SUBORDINATES],
	},
	{
		path: 'users/invitations',
		element: InvitationsPage,
		permissions: [EPermission.INVITATIONS_READ],
	},
	{
		path: 'users/invitations/new',
		element: AddInvitationsPage,
		permissions: [EPermission.INVITATIONS_CREATE],
	},
	{
		path: 'clients',
		element: ClientsPage,
		permissions: [EPermission.USERS_READ_ALL],
	},
	{
		path: 'tokens',
		element: TokenList,
		permissions: [EPermission.TOKENS_READ_ALL, EPermission.TOKENS_READ_OWN],
	},
	{
		path: 'users/userGroups',
		element: UserGroupList,
		permissions: [EPermission.USER_GROUPS_READ],
	},
	{
		path: 'users/userGroups/:id',
		element: UserGroupDetail,
		permissions: [EPermission.USER_GROUPS_READ],
	},
	{
		path: 'users/userGroups/new',
		element: UserGroupAddEdit,
		permissions: [EPermission.USER_GROUPS_CREATE, EPermission.USER_GROUPS_UPDATE],
	},
	{
		path: 'users/userGroups/edit/:id',
		element: UserGroupAddEdit,
		permissions: [EPermission.USER_GROUPS_UPDATE],
	},
	{
		path: 'actions',
		element: ActionList,
		permissions: [EPermission.ACTIONS_READ_ALL, EPermission.ACTIONS_READ_OWN],
	},
	{
		path: 'pam',
		element: PAMPage,
		permissions: [EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
	},
	{
		path: 'pam/:id',
		element: PAMDetail,
		permissions: [EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
	},
	{
		path: 'pam/rdp/new',
		element: PAMAddRDPTarget,
		permissions: [EPermission.PAM_TARGETS_CREATE, EPermission.PAM_TARGETS_UPDATE],
	},
	{
		path: 'pam/rdp/edit/:id',
		element: PAMAddRDPTarget,
		permissions: [EPermission.PAM_TARGETS_UPDATE],
	},
	{
		path: 'pam/ssh/new',
		element: PAMAddSshTarget,
		permissions: [EPermission.PAM_TARGETS_CREATE, EPermission.PAM_TARGETS_UPDATE],
	},
	{
		path: 'pam/ssh/edit/:id',
		element: PAMAddSshTarget,
		permissions: [EPermission.PAM_TARGETS_UPDATE],
	},
	{
		path: 'pam/sessions/search',
		element: PAMFullTextSearch,
		permissions: [EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
	},
	{
		path: 'pam/sessions',
		element: SessionListPage,
		permissions: [EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
	},
	{
		path: 'pam/sessions/:id',
		element: SessionDetailPage,
		permissions: [EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
	},
	{
		path: 'pam/groups',
		element: PAMTargetGroupList,
		permissions: [EPermission.PAM_GROUPS_READ],
	},
	{
		path: 'pam/groups/:id',
		element: PAMTargetGroupDetail,
		permissions: [EPermission.PAM_GROUPS_READ],
	},
	{
		path: 'pam/groups/new',
		element: PAMAddTargetGroup,
		permissions: [EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE],
	},
	{
		path: 'pam/groups/edit/:id',
		element: PAMAddTargetGroup,
		permissions: [EPermission.PAM_GROUPS_UPDATE],
	},
	{
		path: 'identityStores',
		element: IdentityStoreList,
		permissions: [EPermission.IDENTITY_STORES_READ],
	},
	{
		path: 'identityStores/new',
		element: LDAPIdentityStore,
		permissions: [EPermission.IDENTITY_STORES_CREATE, EPermission.IDENTITY_STORES_UPDATE],
	},
	{
		path: 'identityStores/edit/:id',
		element: LDAPIdentityStore,
		permissions: [EPermission.IDENTITY_STORES_UPDATE],
	},
	{
		path: 'saml/idp',
		element: SAMLIdentityProviderConfiguration,
		permissions: [EPermission.ALL],
	},
	{
		path: 'saml/providers',
		element: SAMLServiceProvidersLists,
		permissions: [EPermission.ALL],
	},
	{
		path: 'saml/providers/new',
		element: SAMLAddServiceProvider,
		permissions: [EPermission.ALL],
	},
	{
		path: 'saml/providers/edit',
		element: SAMLEditServiceProvider,
		permissions: [EPermission.ALL],
	},
	{
		path: 'tenants',
		element: TenantList,
		permissions: [EPermission.TENANTS_READ],
	},
	{
		path: 'tenants/new',
		element: TenantAddEdit,
		permissions: [EPermission.TENANTS_CREATE, EPermission.TENANTS_UPDATE],
	},
	{
		path: 'tenants/:id',
		element: TenantDetail,
		permissions: [EPermission.TENANTS_READ],
	},
	{
		path: 'tenants/edit/:id',
		element: TenantAddEdit,
		permissions: [EPermission.TENANTS_UPDATE],
	},
	{
		path: 'geofences',
		element: GeofencesPage,
		permissions: [EPermission.GEOFENCES_READ],
	},
	{
		path: 'geofences/groups',
		element: GeofenceGroupList,
		permissions: [EPermission.GEOFENCE_GROUPS_READ],
	},
	{
		path: 'geofences/groups/edit/:id',
		element: AddEditGeofenceGroup,
		permissions: [EPermission.GEOFENCE_GROUPS_UPDATE],
	},
	{
		path: 'geofences/groups/new',
		element: AddEditGeofenceGroup,
		permissions: [EPermission.GEOFENCE_GROUPS_CREATE],
	},
	{
		path: 'security/oauth',
		element: OAuthClientList,
		permissions: [EPermission.OAUTH_CLIENTS_READ],
	},
	{
		path: 'security/oauth/new',
		element: OAuthClientAddEdit,
		permissions: [EPermission.OAUTH_CLIENTS_CREATE, EPermission.OAUTH_CLIENTS_UPDATE],
	},
	{
		path: 'security/oauth/edit/:id',
		element: OAuthClientAddEdit,
		permissions: [EPermission.OAUTH_CLIENTS_UPDATE],
	},
	{
		path: 'security/oauth/:id',
		element: OAuthClientDetail,
		permissions: [EPermission.OAUTH_CLIENTS_READ],
	},
	{
		path: 'security/oauth/:id/new',
		element: AddWebhook,
		permissions: [EPermission.OAUTH_CLIENTS_UPDATE],
	},
	{
		path: 'security/oauth/:id/edit/:webhookID',
		element: EditWebhook,
		permissions: [EPermission.OAUTH_CLIENTS_UPDATE],
	},
	{
		path: 'security/securityPolicy',
		element: SecurityPolicyList,
		permissions: [EPermission.SECURITY_POLICIES_READ],
	},
	{
		path: 'security/securityPolicy/new',
		element: AddSecurityPolicy,
		permissions: [EPermission.SECURITY_POLICIES_CREATE, EPermission.SECURITY_POLICIES_UPDATE],
	},
	{
		path: 'security/securityPolicy/:id',
		element: SecurityPolicyDetail,
		permissions: [EPermission.SECURITY_POLICIES_READ],
	},
	{
		path: 'security/securityPolicy/edit/:id',
		element: AddSecurityPolicy,
		permissions: [EPermission.SECURITY_POLICIES_CREATE, EPermission.SECURITY_POLICIES_UPDATE],
	},
	{
		path: 'security/ruleSets',
		element: RuleSetList,
		permissions: [EPermission.SECURITY_POLICIES_READ],
	},
	{
		path: 'security/ruleSets/new',
		element: AddEditRuleSet,
		permissions: [EPermission.SECURITY_POLICIES_CREATE],
	},
	{
		path: 'security/ruleSets/edit/:id',
		element: AddEditRuleSet,
		permissions: [EPermission.SECURITY_POLICIES_UPDATE],
	},
	// {
	// 	path: 'security/roles',
	// 	element: RolesPage,
	// 	permissions: [EPermission.ROLES_READ],
	// },
	// {
	// 	path: 'security/roles/new',
	// 	element: AddEditRole,
	// 	permissions: [EPermission.ROLES_CREATE, EPermission.ROLES_UPDATE],
	// },
	// {
	// 	path: 'security/roles/edit/:id',
	// 	element: AddEditRole,
	// 	permissions: [EPermission.ROLES_UPDATE],
	// },
	{
		path: 'settings/general/email/smtp',
		element: SmtpSettingsPage,
	},
	{
		path: 'settings/general/email/expirationTimes',
		element: ExpirationTimePage,
		permissions: [EPermission.SMTP_UPDATE],
	},
	{
		path: 'settings/general/email/smtp/new',
		element: SmtpAddPage,
		permissions: [EPermission.SMTP_CREATE, EPermission.SMTP_UPDATE],
	},
	{
		path: 'settings/general/email/smtp/edit/:id',
		element: SmtpAddPage,
		permissions: [EPermission.SMTP_UPDATE],
	},
	{
		path: 'settings/general/server',
		element: ServerSettingPage,
		permissions: [EPermission.SETTINGS_GENERAL_UPDATE],
	},
	{
		path: 'settings/general/map',
		element: MapSettingPage,
		permissions: [EPermission.SETTINGS_MAP_UPDATE],
	},
	{
		path: 'about',
		element: About,
	},
	{
		path: 'notFound',
		element: NotFoundPage,
	},
	{
		path: 'unauthorized',
		element: UnauthorizedPage,
	},
	{
		path: '*',
		element: NotFoundPage,
	},
];

export const routingConfig: GroupRoute[] = [
	{
		path: '/',
		element: MainLayout,
		index: {
			element: UserDetailPage,
		},
		routes: commonRoutes,
	},
	{
		path: 'login',
		element: AuthLayout,
		index: {
			element: LoginPage,
		},
	},
	{
		path: '/tenant/:tenantID/*',
		element: MainLayout,
		index: {
			element: UserDetailPage,
		},
		routes: commonRoutes,
	},
	{
		path: '/tenant/:tenantID/login',
		element: AuthLayout,
		index: {
			element: LoginPage,
		},
	},
	{
		path: '/tenant/:tenantID/register',
		element: AuthLayout,
		index: {
			element: Registration,
		},
	},
	{
		path: 'setup',
		element: AuthLayout,
		index: {
			element: SetupWizardPage,
		},
	},
	{
		path: 'register',
		element: AuthLayout,
		index: {
			element: Registration,
		},
	},
];
