import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MaterialReactTable,
} from 'material-react-table';
import { Box, Paper, Tooltip, Typography, IconButton, Stack, Grid, useMediaQuery, useTheme } from '@mui/material';
import { SaveAlt as SaveAltIcon, Info as InfoIcon, Workspaces as WorkspacesIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from '../../../components/Link/Link';
import { useNavigate } from '../../../hooks/useNavigate';
import { keepPreviousData } from '@tanstack/react-query';
import { FloatingButtonEdit } from '../../../components/Buttons/FloatingButton/FloatingButtonEdit';
import { ChipArray } from '../../../components/ChipArray/ChipArray';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getSessionTargetLogo } from '../../sessions/utils';
import { useReactQueryClient } from '../../../hooks/useReactQueryClient';
import { EPermission } from '../../../enums/permission/EPermission';
import { PAMTargetDetailModel, PAMTargetGroupModel } from '../../../api/Api';
import { useACL } from '../../../hooks/useACL';
import { Heading } from '../../../components/Heading/Heading';
import { useTableQuery } from '../../../hooks/useTableQuery';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';
import { useFetchPamTargetGroupDetail } from '../../../hooks/query/pamGroups/useFetchPamTargetGroupDetail';
import { useFetchPamTargetDetailsInGroup } from '../../../hooks/query/pamGroups/useFetchPamTargetDetailsInGroup';
import { SectionHeader } from '../../../components/SectionHeader/SectionHeader';

export const PAMTargetGroupDetail: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const { id } = useParams();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const navigate = useNavigate();
	const { MRTLocalization } = useMRTLocalization();

	const [targetGroupsOptions, setTargetGroupsOptions] = React.useState<string[]>([]);

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'hostname', 'port', 'url']);

	const {
		data: pamTargetGroup,
		isLoading: isLoadingPamTargetGroup,
		isSuccess: isLoadedPamTargetGroup,
	} = useFetchPamTargetGroupDetail(id ? Number(id) : undefined);

	const {
		data: targetListData,
		isRefetching: isTargetListRefetching,
		isLoading: isTargetListLoading,
		error: targetListError,
	} = useFetchPamTargetDetailsInGroup({
		pamTargetGroupID: id ? Number(id) : undefined,
		query: {
			limit: swaggerQuery.limit,
			offset: swaggerQuery.offset,
			columns: swaggerQuery.columns,
			filter: swaggerQuery.filter,
			sort: swaggerQuery.sort,
		},
		config: {
			placeholderData: keepPreviousData,
			refetchOnWindowFocus: false,
		},
	});
	const { entities = [], total = 0 } = targetListData ? targetListData : {};

	const handleOnChipClick = React.useCallback(
		(group: PAMTargetGroupModel) => {
			if (!isAllowed([EPermission.PAM_GROUPS_READ])) {
				return;
			}
			if (Number(id) === group.id) {
				return;
			}
			navigate(`/pam/groups/${group.id}`);
		},
		[isAllowed, id],
	);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	React.useEffect(() => {
		const fetchTargetGroups = async () => {
			try {
				const response = await api.pamGroups.getPamTargetGroups();
				const groups = response.data.entities.map((group) => group.name);
				setTargetGroupsOptions(groups);
			} catch (error) {
				console.error('Failed to fetch target groups', error);
			}
		};

		fetchTargetGroups();
	}, []);

	const columns: MRTColumnDef<Partial<PAMTargetDetailModel>>[] = React.useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.pamGroup.edit.table.header.targets'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Tooltip title={row.original.type} placement='bottom' enterDelay={500} arrow>
							<img
								alt='Logo'
								height={42}
								src={getSessionTargetLogo(row.original.type)}
								loading='lazy'
								style={{ borderRadius: 'none' }}
							/>
						</Tooltip>
						<span>{renderedCellValue}</span>
						{row.original.description && (
							<Tooltip title={row.original.description} placement='right' enterDelay={500} arrow>
								<InfoIcon color='info' />
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.hostname}:${row.port}`,
				accessorKey: 'url',
				header: t('page.pamGroup.edit.table.header.host'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.groups}`,
				header: t('page.pamGroup.edit.table.header.groups'),
				enableSorting: false,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={isAllowed([EPermission.PAM_GROUPS_READ]) ? row.original.groups : undefined}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
		],
		[isAllowed, id, targetGroupsOptions],
	);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.pamGroup.detail.title')}
						description={t('page.pamGroup.detail.description')}
						icon={WorkspacesIcon}
					/>
					{pamTargetGroup && (
						<Stack
							sx={{
								width: getStackWidth(),
							}}
						>
							<Heading label={t('page.pamGroup.detail.subtitle.general')} />
							<Stack spacing={1}>
								<Grid container gap={2}>
									<Grid item xs={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4}>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.pamGroup.detail.table.body.name')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8}>
												<Typography px={2}>{pamTargetGroup.name}</Typography>
											</Grid>
										</Grid>
									</Grid>
									{pamTargetGroup.description && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.pamGroup.detail.table.body.description')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography px={2}>{pamTargetGroup.description}</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Stack>
						</Stack>
					)}

					<Stack spacing={2}>
						<SectionHeader
							title={t('page.pamGroup.detail.subtitle.targetList')}
							description={t('page.pamGroup.detail.table.description.targetList')}
						/>
						<MaterialReactTable
							columns={columns}
							data={entities}
							enableStickyHeader={false}
							state={{
								isLoading: isTargetListLoading,
								showAlertBanner: targetListError !== null,
								pagination,
								showProgressBars: isTargetListRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{targetListError}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							renderToolbarInternalActions={({ table }) => (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.pamGroup.detail.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
									enableHiding: true,
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							muiTableBodyRowProps={({ row }) => ({
								onClick:
									isAllowed([EPermission.PAM_TARGETS_READ_ALL]) ?
										handleRedirect(`/pam/${row.original.id}`)
									:	undefined,
								sx: { cursor: 'pointer' },
							})}
							editDisplayMode='modal'
							positionActionsColumn='last'
							localization={MRTLocalization}
						/>
					</Stack>
				</Stack>
			</Paper>

			{isAllowed([EPermission.PAM_GROUPS_UPDATE]) && (
				<Link to={`/pam/groups/edit/${id}`}>
					<FloatingButtonEdit
						ariaLabel={t('page.pamGroup.detail.ariaLabel.editGroup')}
						tooltipTitle={t('page.pamGroup.detail.tooltips.editGroup')}
					/>
				</Link>
			)}
		</Box>
	);
};
