import * as React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { getRoutes } from './constants';
import { useTranslation } from 'react-i18next';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { getTenantFromPath } from '../../utils/Routing';
import { Route } from './types';
import { VARIANT_BODY2_FONT_SIZE, VARIANT_BODY2_LINE_HEIGHT } from '../../theme';

export const CustomBreadcrumbs: React.FC = () => {
	const [breadcrumbRoutes, setBreadcrumbRoutes] = React.useState<Route[]>([]);
	const { tenantID, tenantSlug } = getTenantFromPath();
	const { t } = useTranslation();
	const breadcrumbs = breadcrumbRoutes ? useBreadcrumbs(breadcrumbRoutes) : null;

	const routeList = React.useMemo(() => {
		const ROUTES = getRoutes(t);

		const routesForTenant = [
			{
				breadcrumb: ROUTES[0].breadcrumb,
				path: ROUTES[0].path,
				children: [
					{
						breadcrumb: null,
						path: 'tenant',
						children: [
							{
								breadcrumb: null,
								path: ':id',
								children: ROUTES[0].children,
							},
						],
					},
				],
			},
		];

		return tenantID || tenantSlug ? routesForTenant : ROUTES;
	}, [tenantID, tenantSlug, t]);

	React.useEffect(() => {
		setBreadcrumbRoutes(routeList);
	}, [routeList]);

	return (
		<Breadcrumbs
			aria-label='breadcrumb'
			sx={{
				ml: 2,
				'.MuiBreadcrumbs-separator': {
					paddingTop: 0.5,
					fontSize: VARIANT_BODY2_FONT_SIZE,
					lineHeight: VARIANT_BODY2_LINE_HEIGHT,
				},
				'.MuiBreadcrumbs-li:not(:first-of-type)': {
					paddingTop: 0.5,
					lineHeight: VARIANT_BODY2_LINE_HEIGHT,
				},
			}}
		>
			{breadcrumbs &&
				breadcrumbs.map(({ match, breadcrumb }, index) => {
					if (match.pathname === '/') {
						return (
							<Link
								key='home'
								color='inherit'
								underline='hover'
								component={RouterLink}
								to={
									tenantID ? `/tenant/${tenantID}${match.pathname}`
									: tenantSlug ?
										`/tenant/${tenantSlug}${match.pathname}`
									:	match.pathname
								}
							>
								<HomeIcon fontSize='small' sx={{ verticalAlign: 'middle' }} />
							</Link>
						);
					}

					return (
						<Link
							color='text.secondary'
							underline='hover'
							component={RouterLink}
							to={match.pathname}
							key={`${match.pathname}-${index}`}
							variant='body2'
						>
							{breadcrumb}
						</Link>
					);
				})}
		</Breadcrumbs>
	);
};
