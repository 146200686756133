import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Sidebar, Menu, useProSidebar, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import {
	Apartment as ApartmentIcon,
	Close as CloseIcon,
	Group as GroupIcon,
	GroupAdd as GroupAddIcon,
	Person as PersonIcon,
	Laptop as LaptopIcon,
	Report as ReportIcon,
	Add as AddIcon,
	AddLocation as AddLocationIcon,
	Smartphone as SmartphoneIcon,
	Email as EmailIcon,
	Lock as LockIcon,
	Token as TokenIcon,
	Groups as GroupsIcon,
	AddBox as AddBoxIcon,
	ManageSearch as ManageSearchIcon,
	Settings as SettingsIcon,
	PlaylistAddCheck as PlaylistAddCheckIcon,
	Devices as DevicesIcon,
	Analytics as AnalyticsIcon,
	PlaylistPlay as PlaylistPlayIcon,
	Dns as DnsIcon,
	Dataset as DatasetIcon,
	FolderShared as FolderSharedIcon,
	ConnectedTv as ConnectedTvIcon,
	PlaylistAdd as PlaylistAddIcon,
	Workspaces as WorkspacesIcon,
	Policy as PolicyIcon,
	Security as SecurityIcon,
	AddModerator as AddModeratorIcon,
	List as ListIcon,
	LibraryBooks as LibraryBooksIcon,
	DashboardOutlined as DashboardOutlinedIcon,
	ManageAccounts as ManageAccountsIcon,
	PermIdentity as PermIdentityIcon,
	Rule as RuleIcon,
	Map as MapIcon,
	Help as HelpIcon,
} from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Topbar from '../components/Topbar/Topbar';
import { Link } from '../components/Link/Link';
import { usePathName } from '../hooks/usePathName';
import { useACL } from '../hooks/useACL';
import { EPermission } from '../enums/permission/EPermission';
import { ExcaliburLogo } from '../components/ExcaliburLogo/ExcaliburLogo';
import { useUsername } from '../hooks/useUsername';
import { useSessionContext } from '../hooks/useSessionContext';
import { TenantsSubMenu } from './components/TenantsSubMenu';
import { SidebarFooterProps, SidebarHeaderProps, Theme } from './types';
import { useAuthContext } from '../contexts/AuthContext/AuthContext';
import { MenuItem } from '../components/MenuItem/MenuItem';
import { SubMenu } from '../components/SubMenu/SubMenu';
import { SIDEBAR_WIDTH } from './constants';
import { VARIANT_BODY2_FONT_SIZE, VARIANT_BODY2_LINE_HEIGHT } from '../theme';

const themes = {
	light: {
		sidebar: {
			backgroundColor: '#ffffff',
			color: '#607489',
		},
		menu: {
			menuContent: '#fbfcfd',
			icon: '#0098e5',
			hover: {
				backgroundColor: '#c5e4ff',
				color: '#44596e',
			},
			disabled: {
				color: '#9fb6cf',
			},
			active: {
				backgroundColor: '#c5e4ff',
				color: '#44596e',
			},
		},
	},
	dark: {
		sidebar: {
			backgroundColor: '#233e5a',
			color: '#8ba1b7',
		},
		menu: {
			menuContent: '#183350',
			icon: '#59d0ff',
			hover: {
				backgroundColor: '#0e4277',
				color: '#b6c8d9',
			},
			disabled: {
				color: '#3e5e7e',
			},
			active: {
				backgroundColor: '#0e4277',
				color: '#b6c8d9',
			},
		},
	},
};

const StyledSidebarHeader = styled.div`
	height: 64px;
	min-height: 64px;
	display: flex;
	align-items: center;
	padding: 0 20px;

	> div {
		width: 100%;
		overflow: hidden;
	}
`;

const StyledSidebarFooter = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 20px;
	position: sticky;
	bottom: 0;
	margin: 0;
	background-color: #0e4277;

	> div {
		width: 100%;
		overflow: hidden;
	}
`;

const SidebarContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	overflow: auto;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
	&::-webkit-scrollbar {
		/* For Chrome, Safari and Opera */
		display: none;
	}
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ children, ...rest }) => {
	const { rtl } = useProSidebar();

	return (
		<StyledSidebarHeader {...rest}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExcaliburLogo color='white' width={40} height={40} />
				<Typography variant='subtitle1' fontWeight={500} marginLeft={1}>
					Excalibur Enterprise
				</Typography>
			</div>
		</StyledSidebarHeader>
	);
};

export const SidebarFooter: React.FC<SidebarFooterProps> = ({ children, ...rest }) => {
	const { t } = useTranslation();
	const { isActive, sessionMaxAge, resetCountdown, setResetCountdown } = useSessionContext();

	const [countdown, setCountdown] = React.useState(sessionMaxAge);

	const formattedTime = React.useMemo(() => format(new Date(countdown), 'mm:ss'), [countdown]);

	React.useEffect(() => {
		if (isActive && resetCountdown) {
			setCountdown(sessionMaxAge);
			setResetCountdown(false);
		}
	}, [resetCountdown, sessionMaxAge]);

	React.useEffect(() => {
		if (countdown <= 0) {
			return;
		}

		const timerID = setTimeout(() => {
			setCountdown((prevCountdown) => prevCountdown - 1000);
		}, 1000);

		return () => {
			if (timerID) {
				clearTimeout(timerID);
			}
		};
	}, [countdown]);

	return (
		<StyledSidebarFooter {...rest}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
				<Typography>{t('component.sidebarFooter.body.text')}</Typography>
				<Typography>{formattedTime}</Typography>
			</Box>
		</StyledSidebarFooter>
	);
};

export const MainLayout: React.FC = (): JSX.Element => {
	const { collapsed, toggleSidebar } = useProSidebar();
	const { t } = useTranslation();
	const { isAllowed } = useACL();
	const pathname = usePathName();
	const username = useUsername();
	const location = useLocation();
	const authContext = useAuthContext();

	const [isRTL, setIsRTL] = React.useState<boolean>(false);
	const [theme, setTheme] = React.useState<Theme>('dark');

	const pageContentRef = React.useRef<HTMLDivElement | null>(null);

	const isRedirectedFromTenant = React.useMemo(() => {
		const state = location.state;
		const returnPath = state?.return;

		return typeof returnPath === 'string' && location.state?.return?.startsWith('/tenants');
	}, [location.state]);

	const menuItemStyles: MenuItemStyles = React.useMemo(
		() => ({
			root: {
				fontSize: VARIANT_BODY2_FONT_SIZE,
				fontWeight: 400,
				lineHeight: VARIANT_BODY2_LINE_HEIGHT,
			},
			icon: {
				color: themes[theme].menu.icon,
				[`&.${menuClasses.disabled}`]: {
					color: themes[theme].menu.disabled.color,
				},
			},
			SubMenuExpandIcon: {
				color: '#b6b7b9',
				[`&.${menuClasses.active}`]: {
					backgroundColor: themes[theme].menu.hover.backgroundColor,
				},
			},
			subMenuContent: ({ level }) => ({
				backgroundColor: level === 0 ? themes[theme].menu.menuContent : 'transparent',
			}),
			button: {
				[`&.${menuClasses.disabled}`]: {
					color: themes[theme].menu.disabled.color,
				},
				[`&.${menuClasses.active}`]: {
					backgroundColor: themes[theme].menu.hover.backgroundColor,
					color: themes[theme].menu.hover.color,
				},
				['&:hover']: {
					backgroundColor: themes[theme].menu.hover.backgroundColor,
					color: themes[theme].menu.hover.color,
				},
			},
			label: ({ open }) => ({
				fontWeight: open ? 600 : undefined,
			}),
		}),
		[theme, collapsed],
	);

	const handleOnCloseSidebar = React.useCallback(() => {
		toggleSidebar(false);
	}, []);

	React.useEffect(() => {
		if (pageContentRef.current) {
			pageContentRef.current.scrollTop = 0;
		}
	}, [pathname]);

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100vh',
				direction: isRTL ? 'rtl' : 'ltr',
			}}
		>
			{authContext.user.isAuthenticated && (
				<Sidebar
					//image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
					rtl={isRTL}
					customBreakPoint={'900px'}
					backgroundColor={themes[theme].sidebar.backgroundColor}
					width={SIDEBAR_WIDTH}
					style={{
						zIndex: 1200,
					}}
					rootStyles={{
						color: themes[theme].sidebar.color,
					}}
				>
					<SidebarContainer>
						<SidebarHeader
							style={{
								marginBottom: '24px',
								marginTop: '16px',
							}}
						/>
						<Tooltip
							title={t('menu.tooltip.button.closeSideMenu')}
							placement='right'
							enterDelay={500}
							arrow
						>
							<IconButton
								onClick={handleOnCloseSidebar}
								sx={{
									position: 'absolute',
									top: 0,
									right: 0,
									display: { xs: collapsed ? 'none' : 'block', md: 'none' },
								}}
							>
								<CloseIcon sx={{ color: themes[theme].sidebar.color }} />
							</IconButton>
						</Tooltip>
						<div style={{ flex: 1, marginBottom: '32px' }}>
							<div
								style={{
									padding: '0 24px',
									marginBottom: '8px',
								}}
							>
								<Typography
									fontWeight={600}
									style={{
										opacity: collapsed ? 0 : 0.7,
										letterSpacing: '0.5px',
									}}
								>
									{t('menu.section.management')}
								</Typography>
							</div>
							<Menu menuItemStyles={menuItemStyles}>
								{/* {isAllowed([EPermission.ALL]) && (
								<SubMenu
									label={t('menu.item.dashboard')}
									icon={<DashboardOutlinedIcon />}
									active={pathname.startsWith('/overview)}
									// defaultOpen={pathname.startsWith('/overview)}
								>
									<MenuItem
										icon={<AnalyticsIcon />}
										component={<Link to='/overview/statistics' />}
										active={pathname === '/overview/statistics}
									>
										Statistics
									</MenuItem>
									<MenuItem
										icon={<DevicesIcon />}
										component={<Link to='/overview/devices' />}
										active={pathname === '/overview/devices}
									>
										Devices
									</MenuItem>
									<MenuItem
										icon={<PlaylistAddCheckIcon />}
										component={<Link to='/overview/sessions' />}
 										active={pathname === '/overview/sessions}
									>
										Sessions
									</MenuItem>
									<MenuItem
										icon={<ReportIcon />}
										component={<Link to='/overview/incidents' />}
										active={pathname === '/overview/incidents}
									>
										Incidents
									</MenuItem>
									<MenuItem
										icon={<PlaylistPlayIcon />}
										component={<Link to='/overview/actions' />}
										active={pathname === '/overview/actions}
									>
										Actions
									</MenuItem>
									<MenuItem
										icon={<DnsIcon />}
										component={<Link to='/overview/server' />}
										active={pathname === '/overview/server}
									>
										Server
									</MenuItem>
								</SubMenu>
							)} */}
								{isAllowed([EPermission.ALL]) && (
									<SubMenu
										label={t('menu.item.profile')}
										icon={<PersonIcon />}
										active={pathname.startsWith('/me')}
										defaultOpen={pathname.startsWith('/me')}
										tooltipTitle={t('menu.tooltip.subMenu.profile')}
									>
										<MenuItem
											icon={<PermIdentityIcon />}
											component={<Link to='/me' />}
											active={pathname === '/me'}
											tooltipTitle={t('menu.tooltip.item.profile')}
										>
											{username}
										</MenuItem>
										<MenuItem
											icon={<ManageAccountsIcon />}
											component={<Link to='/me/settings' />}
											active={pathname.startsWith('/me/settings')}
											tooltipTitle={t('menu.tooltip.item.preferences')}
										>
											{t('menu.item.preferences')}
										</MenuItem>
									</SubMenu>
								)}
								{isAllowed(
									[
										EPermission.USERS_READ_ALL,
										EPermission.INVITATIONS_READ,
										EPermission.USERS_READ_SUBORDINATES,
										EPermission.USER_GROUPS_READ,
									],
									false,
								) && (
									<SubMenu
										label={t('menu.item.users')}
										icon={<GroupIcon />}
										active={pathname.startsWith('/users') && !isRedirectedFromTenant}
										defaultOpen={pathname.startsWith('/users') && !isRedirectedFromTenant}
										tooltipTitle={t('menu.tooltip.subMenu.users')}
									>
										{isAllowed(
											[EPermission.USERS_READ_ALL, EPermission.USERS_READ_SUBORDINATES],
											false,
										) && (
											<MenuItem
												icon={<ListIcon />}
												component={<Link to='/users' />}
												active={pathname === '/users'}
												tooltipTitle={t('menu.tooltip.item.users')}
											>
												{t('menu.item.users')}
											</MenuItem>
										)}
										{isAllowed([EPermission.INVITATIONS_READ]) && (
											<MenuItem
												icon={<GroupAddIcon />}
												component={<Link to='/users/invitations' />}
												active={
													pathname.startsWith('/users/invitations') && !isRedirectedFromTenant
												}
												tooltipTitle={t('menu.tooltip.item.invitations')}
											>
												{t('menu.item.invitations')}
											</MenuItem>
										)}
										{isAllowed([EPermission.USER_GROUPS_READ]) && (
											<MenuItem
												icon={<WorkspacesIcon />}
												component={<Link to='/users/userGroups' />}
												active={pathname.startsWith('/users/userGroups')}
												tooltipTitle={t('menu.tooltip.item.userGroups')}
											>
												{t('menu.item.userGroups')}
											</MenuItem>
										)}
									</SubMenu>
								)}
								{isAllowed([EPermission.ACTIONS_READ_ALL, EPermission.ACTIONS_READ_OWN], false) && (
									<MenuItem
										icon={<LibraryBooksIcon />}
										component={<Link to='/actions' />}
										active={pathname.startsWith('/actions')}
										tooltipTitle={t('menu.tooltip.item.actions')}
									>
										{t('menu.item.actions')}
									</MenuItem>
								)}
								{isAllowed([EPermission.TOKENS_READ_ALL, EPermission.TOKENS_READ_OWN], false) && (
									<MenuItem
										icon={<SmartphoneIcon />}
										component={<Link to='/tokens' />}
										active={pathname === '/tokens'}
										tooltipTitle={t('menu.tooltip.item.tokens')}
									>
										{t('menu.item.tokens')}
									</MenuItem>
								)}
								{/* <MenuItem
									icon={<ViewTimelineIcon />}
									component={<Link to='/timeline' />}
									active={pathname === '/timeline'}
									tooltipTitle={t('menu.tooltip.item.timeline')}
								>
									{t('menu.item.timeline')}
								</MenuItem> */}
								{/* {isAllowed([EPermission.READ_CLIENTS]) && (
										<MenuItem
											icon={<LaptopIcon />}
											component={<Link to='/clients' />}
											active={pathname === '/clients'}
											tooltipTitle={t('menu.tooltip.item.clients')}
										>
											{t('menu.item.clients')}
										</MenuItem>
								)} */}

								{isAllowed(
									[
										EPermission.PAM_TARGETS_READ_ALL,
										EPermission.PAM_TARGETS_READ_OWN,
										EPermission.PAM_TARGETS_CREATE,
										EPermission.PAM_GROUPS_READ,
										EPermission.PAM_SESSIONS_READ_ALL,
										EPermission.PAM_SESSIONS_READ_OWN,
									],
									false,
								) && (
									<SubMenu
										label={t('menu.item.pam')}
										icon={<ConnectedTvIcon />}
										active={pathname.startsWith('/pam')}
										defaultOpen={pathname.startsWith('/pam')}
										tooltipTitle={t('menu.tooltip.subMenu.pam')}
									>
										{isAllowed(
											[EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
											false,
										) && (
											<MenuItem
												icon={<ListIcon />}
												component={<Link to='/pam' />}
												active={
													pathname.startsWith('/pam') &&
													!pathname.includes('groups') &&
													!pathname.includes('sessions')
												}
												tooltipTitle={t('menu.tooltip.item.targets')}
											>
												{t('menu.item.targets')}
											</MenuItem>
										)}
										{isAllowed([EPermission.PAM_GROUPS_READ]) && (
											<MenuItem
												icon={<WorkspacesIcon />}
												component={<Link to='/pam/groups' />}
												active={pathname.startsWith('/pam/groups')}
												tooltipTitle={t('menu.tooltip.item.pamGroups')}
											>
												{t('menu.item.pamGroups')}
											</MenuItem>
										)}
										{isAllowed(
											[EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
											false,
										) && (
											<MenuItem
												icon={<PlaylistPlayIcon />}
												component={<Link to='/pam/sessions' />}
												active={
													pathname.startsWith('/pam/sessions') && !pathname.includes('search')
												}
												tooltipTitle={t('menu.tooltip.item.sessions')}
											>
												{t('menu.item.sessions')}
											</MenuItem>
										)}
										{isAllowed(
											[EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
											false,
										) && (
											<MenuItem
												icon={<ManageSearchIcon />}
												component={<Link to='/pam/sessions/search' />}
												active={pathname.startsWith('/pam/sessions/search')}
												tooltipTitle={t('menu.tooltip.item.fullTextSearch')}
											>
												{t('menu.item.fullTextSearch')}
											</MenuItem>
										)}
									</SubMenu>
								)}
								{/* {isAllowed([EPermission.READ_SAMLS]) && (
								<SubMenu
									label={t('menu.item.saml')}
									icon={<LockIcon />}
									active={pathname.startsWith('/saml')}
									defaultOpen={pathname.startsWith('/saml')}
									// tooltipTitle='SAML description.'
								>
									<MenuItem
										component={<Link to='/saml/idp' />}
										active={pathname.startsWith('/saml/idp')}
										tooltipTitle='IdPConfiguration description.'
									>
										IdP configuration
									</MenuItem>
									<MenuItem
										component={<Link to='/saml/providers/' />}
										active={pathname.startsWith('/saml/providers')}
										tooltipTitle='Service Providers descriptions.'
									>
										Service Providers
									</MenuItem>
								</SubMenu>
							)} */}
								{isAllowed([EPermission.IDENTITY_STORES_READ]) && (
									<MenuItem
										icon={<FolderSharedIcon />}
										component={<Link to='/identityStores' />}
										active={pathname.startsWith('/identityStores') && !isRedirectedFromTenant}
										tooltipTitle={t('menu.tooltip.item.identityStores')}
									>
										{t('menu.item.identityStores')}
									</MenuItem>
								)}
								{isAllowed([EPermission.TENANTS_READ]) && <TenantsSubMenu />}
								{/* {isAllowed([EPermission.TENANTS_READ]) && (
									<MenuItem
										icon={<ApartmentIcon />}
										component={<Link to='/tenants' />}
										active={
											pathname.startsWith('/tenants') ||
											(isRedirectedFromTenant && pathname.startsWith('/users/invitations')) ||
											(isRedirectedFromTenant && pathname.startsWith('/identityStores'))
										}
										tooltipTitle={t('menu.tooltip.item.tenantList')}
									>
										{t('menu.item.tenants')}
									</MenuItem>
								)} */}
								{isAllowed([EPermission.GEOFENCES_READ, EPermission.GEOFENCE_GROUPS_READ]) && (
									<SubMenu
										label={t('menu.item.geofences')}
										icon={<AddLocationIcon />}
										active={pathname.startsWith('/geofences')}
										defaultOpen={pathname.startsWith('/geofences')}
										tooltipTitle={t('menu.tooltip.subMenu.geofences')}
									>
										{isAllowed([EPermission.GEOFENCES_READ], false) && (
											<MenuItem
												icon={<AddLocationIcon />}
												component={<Link to='/geofences' />}
												active={pathname === '/geofences'}
												tooltipTitle={t('menu.tooltip.item.geofences')}
											>
												{t('menu.item.geofences')}
											</MenuItem>
										)}
										{isAllowed([EPermission.GEOFENCE_GROUPS_READ]) && (
											<MenuItem
												icon={<WorkspacesIcon />}
												component={<Link to='/geofences/groups' />}
												active={pathname.startsWith('/geofences/groups')}
												tooltipTitle={t('menu.tooltip.item.geofenceGroups')}
											>
												{t('menu.item.geofenceGroups')}
											</MenuItem>
										)}
									</SubMenu>
								)}
							</Menu>
							{isAllowed(
								[
									EPermission.OAUTH_CLIENTS_READ,
									EPermission.ROLES_READ,
									EPermission.SECURITY_POLICIES_READ,
								],
								false,
							) && (
								<div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '32px' }}>
									<Typography
										fontWeight={600}
										style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
									>
										{t('menu.section.security')}
									</Typography>
								</div>
							)}
							{isAllowed([EPermission.OAUTH_CLIENTS_READ]) && (
								<Menu menuItemStyles={menuItemStyles}>
									<MenuItem
										icon={<TokenIcon />}
										component={<Link to='/security/oauth' />}
										active={pathname.startsWith('/security/oauth')}
										tooltipTitle={t('menu.tooltip.item.oAuthClients')}
									>
										{t('menu.item.oAuthClients')}
									</MenuItem>
								</Menu>
							)}
							{/* {isAllowed([EPermission.ROLES_READ]) && (
								<Menu menuItemStyles={menuItemStyles}>
									<MenuItem
										icon={<GroupsIcon />}
										component={<Link to='/security/roles' />}
										active={pathname.startsWith('/security/roles')}
										tooltipTitle={t('menu.tooltip.item.roles')}
									>
										{t('menu.item.roles')}
									</MenuItem>
								</Menu>
							)} */}
							{isAllowed([EPermission.SECURITY_POLICIES_READ]) && (
								<Menu menuItemStyles={menuItemStyles}>
									<SubMenu
										label={t('menu.item.securityPolicy')}
										icon={<SecurityIcon />}
										active={
											pathname.startsWith('/security/securityPolicy') ||
											pathname.startsWith('/security/ruleSets')
										}
										defaultOpen={
											pathname.startsWith('/security/securityPolicy') ||
											pathname.startsWith('/security/ruleSets')
										}
										tooltipTitle={t('menu.tooltip.subMenu.securityPolicies')}
									>
										{isAllowed([EPermission.SECURITY_POLICIES_READ]) && (
											<MenuItem
												component={<Link to='/security/securityPolicy' />}
												icon={<ListIcon />}
												active={pathname.startsWith('/security/securityPolicy')}
												tooltipTitle={t('menu.tooltip.item.securityPolicies')}
											>
												{t('menu.item.securityPolicy')}
											</MenuItem>
										)}
										{isAllowed([EPermission.SECURITY_POLICIES_CREATE]) && (
											<MenuItem
												component={<Link to='/security/ruleSets' />}
												icon={<RuleIcon />}
												active={pathname.startsWith('/security/ruleSets')}
												tooltipTitle={t('menu.tooltip.item.ruleSets')}
											>
												{t('menu.item.ruleSets')}
											</MenuItem>
										)}
									</SubMenu>
								</Menu>
							)}
							{isAllowed([EPermission.SMTP_READ]) && (
								<div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '32px' }}>
									<Typography
										fontWeight={600}
										style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
									>
										{t('menu.section.others')}
									</Typography>
								</div>
							)}
							{isAllowed(
								[
									EPermission.SMTP_READ,
									EPermission.SETTINGS_GENERAL_UPDATE,
									EPermission.SETTINGS_MAP_UPDATE,
								],
								false,
							) && (
								<Menu menuItemStyles={menuItemStyles}>
									<SubMenu
										label={t('menu.item.settings')}
										icon={<SettingsIcon />}
										active={pathname.startsWith('/settings/general')}
										defaultOpen={pathname.startsWith('/settings/general')}
										tooltipTitle={t('menu.tooltip.subMenu.settingsGeneral')}
									>
										{isAllowed([EPermission.SMTP_READ]) && (
											<SubMenu
												label={t('menu.item.email')}
												icon={<EmailIcon />}
												defaultOpen={pathname.startsWith('/settings/general/email')}
												tooltipTitle={t('menu.tooltip.subMenu.email')}
											>
												<MenuItem
													component={<Link to='/settings/general/email/smtp' />}
													active={pathname.startsWith('/settings/general/email/smtp')}
													tooltipTitle={t('menu.tooltip.item.smtp')}
												>
													{t('menu.item.smtp')}
												</MenuItem>
												{isAllowed([EPermission.SMTP_UPDATE]) && (
													<MenuItem
														component={
															<Link to='/settings/general/email/expirationTimes' />
														}
														active={pathname.startsWith(
															'/settings/general/email/expirationTimes',
														)}
														tooltipTitle={t('menu.tooltip.item.expirationTimes')}
													>
														{t('menu.item.expirationTimes')}
													</MenuItem>
												)}
											</SubMenu>
										)}
										{isAllowed([EPermission.SETTINGS_GENERAL_UPDATE]) && (
											<MenuItem
												component={<Link to='/settings/general/server' />}
												icon={<DnsIcon />}
												active={pathname.startsWith('/settings/general/server')}
												tooltipTitle={t('menu.tooltip.item.system')}
											>
												{t('menu.item.system')}
											</MenuItem>
										)}
										{isAllowed([EPermission.SETTINGS_MAP_UPDATE]) && (
											<MenuItem
												component={<Link to='/settings/general/map' />}
												icon={<MapIcon />}
												active={pathname.startsWith('/settings/general/map')}
												tooltipTitle={t('menu.tooltip.item.map')}
											>
												{t('menu.item.map')}
											</MenuItem>
										)}
									</SubMenu>
								</Menu>
							)}
							<Menu menuItemStyles={menuItemStyles}>
								<MenuItem
									icon={<HelpIcon />}
									component={<Link to='/about' />}
									active={pathname === '/about'}
									tooltipTitle={t('menu.tooltip.item.about')}
								>
									{t('menu.item.about')}
								</MenuItem>
							</Menu>
						</div>
						<SidebarFooter />
					</SidebarContainer>
				</Sidebar>
			)}

			<Box
				ref={pageContentRef}
				component='main'
				sx={{
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
					backgroundColor: 'rgb(240 242 245)',
				}}
			>
				{authContext.user.isAuthenticated && <Topbar />}

				<Box
					sx={{
						p: 2,
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</Box>
	);
};
