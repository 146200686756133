import { zodResolver } from '@hookform/resolvers/zod';
import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Info as InfoIcon,
	Workspaces as WorkspacesIcon,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	FormGroup,
	IconButton,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_Row,
	MRT_TableInstance,
	MaterialReactTable,
	type MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from '../../../hooks/useNavigate';
import {
	CreatePAMTargetGroupDto,
	GetPAMTargetDetailsResponseDto,
	PAMTargetDetailModel,
	PAMTargetGroupModel,
	PAMTargetSummaryModel,
} from '../../../api/Api';
import { ChipArray } from '../../../components/ChipArray/ChipArray';
import { Preloader } from '../../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { EPermission } from '../../../enums/permission/EPermission';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { useACL } from '../../../hooks/useACL';
import { useReactQueryClient } from '../../../hooks/useReactQueryClient';
import { getSessionTargetLogo } from '../../sessions/utils';
import { getTargetGroupSchema, targetListSchema } from './schema';
import { FormValues, SubmitFormState } from './types';
import { ColorPicker } from '../../../components/FormFields/ColorPicker/ColorPicker';
import { getContrastColor } from '../../../utils/ColorPickerHelper';
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { useTableQuery } from '../../../hooks/useTableQuery';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { Heading } from '../../../components/Heading/Heading';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';
import { useFetchPamTargetGroupDetail } from '../../../hooks/query/pamGroups/useFetchPamTargetGroupDetail';
import { useFetchPamTargetDetailsInGroup } from '../../../hooks/query/pamGroups/useFetchPamTargetDetailsInGroup';
import { SectionHeader } from '../../../components/SectionHeader/SectionHeader';

export const PAMAddTargetGroup: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const { id } = useParams();
	const location = useLocation();
	const copyFromId = location.state?.copyFromId;
	const navigate = useNavigate();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { MRTLocalization } = useMRTLocalization();

	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [selectedTargetList, setSelectedTargetList] = React.useState<PAMTargetSummaryModel[]>([]);
	const [allTargetList, setAllTargetList] = React.useState<PAMTargetDetailModel[]>([]);
	const [targetsToUpdate, setTargetsToUpdate] = React.useState<{ add: number[]; delete: number[] }>({
		add: [],
		delete: [],
	});
	const [rowSelectionSelected, setRowSelectionSelected] = React.useState<MRTRowSelectionState>({});
	const [rowSelectionAll, setRowSelectionAll] = React.useState<MRTRowSelectionState>({});

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'hostname', 'port', 'url', 'groups']);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getTargetGroupSchema(t)),
		defaultValues: {
			name: '',
			description: '',
			bgColor: '#dadada',
			textColor: getContrastColor('#dadada'),
			borderColor: getContrastColor('#dadada'),
		},
	});

	const bgColor = useWatch({ control: control, name: 'bgColor' });
	const textColor = useWatch({ control: control, name: 'textColor' });
	const borderColor = useWatch({ control: control, name: 'borderColor' });

	const previousBgColor = usePreviousValue(bgColor);

	React.useEffect(() => {
		if (previousBgColor !== bgColor) {
			const contastColor = getContrastColor(bgColor);
			if (contastColor) {
				setValue('textColor', contastColor);
				setValue('borderColor', contastColor);
				setValue('bgColor', bgColor);
			}
		}
	}, [bgColor, previousBgColor]);

	const {
		data: targetList,
		isRefetching: isTargetListRefetching,
		isLoading: isTargetListLoading,
		error: targetListError,
	} = useQuery<GetPAMTargetDetailsResponseDto>({
		queryKey: [EQueryKey.PAM_DETAILED_TARGET_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.pam.getTargetDetails(query);
				response.data.entities.forEach((target) => {
					targetListSchema.parse(target);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = targetList ? targetList : {};

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData: FormValues): Promise<void> => {
			const requestBodyGroup: CreatePAMTargetGroupDto = {
				name: formData.name,
				description: formData.description,
				textColor: formData.textColor,
				bgColor: formData.bgColor,
				borderColor: formData.borderColor,
			};
			const requestBodyTargets: number[] = selectedTargetList.map((target) => target.id);

			let createdGroupID: number | undefined = undefined;

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (id) {
					await api.pamGroups.updatePamTargetGroup(Number(id), requestBodyGroup);
				} else {
					const response = await api.pamGroups.createPamTargetGroup(requestBodyGroup);
					createdGroupID = response.data.id;
				}
			} catch (error: unknown) {
				console.error(error);
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
			}

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (id) {
					if (targetsToUpdate.delete.length === 1) {
						await api.pamGroups.deletePamTargetFromGroup(Number(id), targetsToUpdate.delete[0]);
					} else if (targetsToUpdate.delete.length > 1) {
						await api.pamGroups.deletePamTargetsFromGroup(Number(id), {
							targetIDs: targetsToUpdate.delete,
						});
					}

					if (targetsToUpdate.add.length === 1) {
						await api.pamGroups.addPamTargetToGroup(Number(id), targetsToUpdate.add[0]);
					} else if (targetsToUpdate.add.length > 1) {
						await api.pamGroups.addPamTargetsToGroup(Number(id), {
							targetIDs: targetsToUpdate.add,
						});
					}
					enqueueSnackbar(t('page.pamGroup.edit.actionMessages.groupSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
				} else {
					if (requestBodyTargets.length === 1) {
						await api.pamGroups.addPamTargetToGroup(Number(createdGroupID), requestBodyTargets[0]);
					} else if (requestBodyTargets.length > 1) {
						await api.pamGroups.addPamTargetsToGroup(Number(createdGroupID), {
							targetIDs: requestBodyTargets,
						});
					}
					enqueueSnackbar(t('page.pamGroup.edit.actionMessages.groupSuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
				}
				navigate('/pam/groups');

				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				console.error(error);
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
			}
		},
		[id, selectedTargetList, setSubmitFormState, enqueueSnackbar, targetsToUpdate],
	);

	const {
		data: pamTargetGroup,
		isLoading: isLoadingPamTargetGroup,
		isSuccess: isLoadedPamTargetGroup,
	} = useFetchPamTargetGroupDetail(
		id ? Number(id)
		: copyFromId ? Number(copyFromId)
		: undefined,
	);

	React.useEffect(() => {
		if (pamTargetGroup) {
			const formValues: Partial<FormValues> = {
				name: pamTargetGroup.name,
				description: pamTargetGroup.description,
				textColor:
					pamTargetGroup.textColor ? pamTargetGroup.textColor : getContrastColor(pamTargetGroup.bgColor),
				bgColor: pamTargetGroup.bgColor,
				borderColor:
					pamTargetGroup.borderColor ? pamTargetGroup.borderColor : getContrastColor(pamTargetGroup.bgColor),
			};

			if (copyFromId) {
				reset({ ...formValues, name: `copy of ${formValues.name}` });
			} else {
				reset(formValues);
			}
		}
	}, [pamTargetGroup]);

	const {
		data: pamTargetDetailsInGroup,
		isLoading: isLoadingPamTargetDetailsInGroup,
		isSuccess: isLoadedPamTargetDetailsInGroup,
	} = useFetchPamTargetDetailsInGroup({ pamTargetGroupID: id ? Number(id) : undefined });

	React.useEffect(() => {
		if (pamTargetDetailsInGroup) {
			const { entities: targets } = pamTargetDetailsInGroup;
			setSelectedTargetList(targets);
		}
	}, [pamTargetDetailsInGroup]);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const updateRowSelectionAll = React.useCallback(
		(addedIds: number[]) => {
			if (Object.keys(rowSelectionAll).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelectionAll };
			addedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelectionAll(newRowSelection);
		},
		[rowSelectionAll],
	);

	const updateRowSelectionSelected = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelectionSelected).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelectionSelected };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelectionSelected(newRowSelection);
		},
		[rowSelectionSelected],
	);

	const handleOnAddEntity = React.useCallback(
		(targetId: number | undefined) => () => {
			if (!targetId) {
				return;
			}

			const targetToAdd = allTargetList.find((target) => target.id === targetId);
			if (targetToAdd) {
				setAllTargetList((prevList) =>
					prevList.map((target) => (target.id === targetId ? { ...target, disabled: true } : target)),
				);
				setSelectedTargetList((prevList) => [...prevList, targetToAdd]);
				updateRowSelectionAll([targetId]);
			}
		},
		[allTargetList, rowSelectionAll],
	);

	const handleOnRemoveEntity = React.useCallback(
		(targetId: number | undefined) => () => {
			if (!targetId) {
				return;
			}

			const targetToRemove = selectedTargetList.find((target) => target.id === targetId);
			if (targetToRemove) {
				setAllTargetList((prevList) =>
					prevList.map((target) => (target.id === targetId ? { ...target, disabled: false } : target)),
				);
				setSelectedTargetList((prevList) => prevList.filter((target) => target.id !== targetToRemove.id));
				updateRowSelectionSelected([targetId]);
			}
		},
		[selectedTargetList, rowSelectionSelected],
	);

	const handleOnAddEntities = React.useCallback(
		(table: MRT_TableInstance<Partial<Record<string, any>>>) => () => {
			if (!rowSelectionAll) {
				return;
			}

			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			const targetsToAdd = allTargetList.filter((target) => selectedRowsOnActivePageIds.includes(target.id));
			if (targetsToAdd) {
				setSelectedTargetList((prevList) => [...prevList, ...targetsToAdd]);
				updateRowSelectionAll(selectedRowsOnActivePageIds);
			}
		},
		[allTargetList, rowSelectionAll],
	);

	const handleOnRemoveEntities = React.useCallback(
		(table: MRT_TableInstance<Partial<Record<string, any>>>) => () => {
			if (!rowSelectionSelected) {
				return;
			}

			const rowsOnPage = table.getRowModel().rows.map((row) => Number(row.original.id));
			const allSelectedRows = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));
			const selectedRowsOnActivePageIds = allSelectedRows.filter((id) => rowsOnPage.includes(id));

			const targetsToRemove = selectedTargetList.filter((target) =>
				selectedRowsOnActivePageIds.includes(target.id),
			);
			if (targetsToRemove) {
				setSelectedTargetList((prevList) => prevList.filter((target) => !targetsToRemove.includes(target)));
				updateRowSelectionSelected(selectedRowsOnActivePageIds);
			}
		},
		[selectedTargetList, rowSelectionSelected],
	);

	const handleOnChipClick = React.useCallback(
		(group: PAMTargetGroupModel) => {
			if (!isAllowed([EPermission.PAM_GROUPS_READ])) {
				return;
			}
			navigate(`/pam/groups/${group.id}`);
		},
		[isAllowed],
	);

	React.useEffect(() => {
		if (
			shouldDisableForm &&
			!submitFormState.submitting &&
			!isLoadingPamTargetGroup &&
			!isLoadingPamTargetDetailsInGroup
		) {
			setShouldDisableForm(false);
		} else if (
			!shouldDisableForm &&
			(submitFormState.submitting || isLoadingPamTargetGroup || isLoadingPamTargetDetailsInGroup)
		) {
			setShouldDisableForm(true);
		}
	}, [isLoadingPamTargetGroup, isLoadingPamTargetDetailsInGroup, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (!id || !isLoadedPamTargetGroup || !isLoadedPamTargetDetailsInGroup) {
			return;
		}

		const targetsToAdd = selectedTargetList.filter((target) => !pamTargetGroup?.targets.includes(target));

		setTargetsToUpdate((prevUpdateTargets) => {
			return { ...prevUpdateTargets, add: targetsToAdd.map((target) => target.id) };
		});

		if (!pamTargetGroup || pamTargetGroup.targets.length === 0) {
			return;
		}

		const targetsToDelete = pamTargetGroup.targets.filter((target) => !selectedTargetList.includes(target));

		setTargetsToUpdate((prevUpdateTargets) => {
			return { ...prevUpdateTargets, delete: targetsToDelete.map((target) => target.id) };
		});
	}, [isLoadedPamTargetGroup, isLoadedPamTargetDetailsInGroup, pamTargetGroup, selectedTargetList]);

	React.useEffect(() => {
		if (!selectedTargetList || isTargetListLoading) {
			return;
		}

		setAllTargetList(entities);
	}, [selectedTargetList, entities]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns: MRTColumnDef<Partial<PAMTargetDetailModel>>[] = React.useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				grow: 1,
				header: t('page.pamGroup.edit.table.header.targets'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Tooltip title={row.original.type} placement='left' enterDelay={500} arrow>
							<img
								alt='Logo'
								height={42}
								src={getSessionTargetLogo(row.original.type)}
								loading='lazy'
								style={{ borderRadius: 'none' }}
							/>
						</Tooltip>
						<span>{renderedCellValue}</span>
						{row.original.description && (
							<Tooltip title={row.original.description} placement='right' enterDelay={500} arrow>
								<InfoIcon color='info' />
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.hostname}:${row.port}`,
				accessorKey: 'url',
				grow: 1,
				header: t('page.pamGroup.edit.table.header.host'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.groups}`,
				accessorKey: 'groups',
				header: t('page.pamGroup.edit.table.header.groups'),
				enableColumnFilter: false,
				enableSorting: false,
				enableGlobalFilter: false,
				grow: 3,
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<ChipArray
							wrap
							chipList={isAllowed([EPermission.PAM_GROUPS_READ]) ? row.original.groups : undefined}
							limitTags={3}
							onChipClick={handleOnChipClick}
						/>
					</Box>
				),
			},
		],
		[isAllowed],
	);

	return (
		<Box component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
			{(
				id &&
				(isLoadingPamTargetGroup ||
					!isLoadedPamTargetGroup ||
					isLoadingPamTargetDetailsInGroup ||
					!isLoadedPamTargetDetailsInGroup)
			) ?
				<Preloader />
			:	<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={id ? t('page.pamGroup.edit.title') : t('page.pamGroup.add.title')}
							description={id ? t('page.pamGroup.edit.description') : t('page.pamGroup.add.description')}
							icon={WorkspacesIcon}
						/>
					</Box>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Heading label={t('page.pamGroup.edit.subtitle.general')} />
						<Stack
							marginTop={1}
							spacing={2}
							sx={{
								width: getStackWidth(),
							}}
						>
							<TextField
								name={'name'}
								register={register}
								label={t('page.pamGroup.edit.form.name.label')}
								error={errors.name}
								disabled={shouldDisableForm}
								helperText={t('page.pamGroup.edit.form.name.helperText')}
								InputLabelProps={{ shrink: true }}
							/>
							<Textarea
								name={'description'}
								register={register}
								label={t('page.pamGroup.edit.form.description.label')}
								error={errors.description}
								disabled={shouldDisableForm}
								helperText={t('page.pamGroup.edit.form.description.helperText')}
								rows={4}
							/>
						</Stack>
					</FormGroup>

					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Stack
							spacing={2}
							sx={{
								width: getStackWidth(),
							}}
						>
							<SectionHeader
								title={t('page.pamGroup.edit.subtitle.tagColorConfiguration')}
								description={t('page.pamGroup.edit.body.description.tagColorConfiguration')}
							/>
							<Box sx={{ paddingBottom: 1 }}>
								<Chip
									label={t('page.pamGroup.edit.body.tagPreview')}
									color='default'
									variant='outlined'
									sx={{
										color: textColor ?? '#000000',
										background: bgColor ?? '#dadada',
										borderColor: borderColor ?? '#dadada',
										padding: 1,
									}}
								/>
							</Box>
							<Stack spacing={2}>
								<ColorPicker
									error={errors.bgColor}
									name={'bgColor'}
									label={t('page.pamGroup.edit.form.backgroundColor.label')}
									helperText={t('page.pamGroup.edit.form.backgroundColor.helperText')}
									disabled={shouldDisableForm}
									control={control}
								/>
								<ColorPicker
									error={errors.textColor}
									name={'textColor'}
									label={t('page.pamGroup.edit.form.textColor.label')}
									helperText={t('page.pamGroup.edit.form.textColor.helperText')}
									disabled={shouldDisableForm}
									control={control}
								/>
								<ColorPicker
									error={errors.borderColor}
									name={'borderColor'}
									label={t('page.pamGroup.edit.form.borderColor.label')}
									helperText={t('page.pamGroup.edit.form.borderColor.helperText')}
									disabled={shouldDisableForm}
									control={control}
								/>
							</Stack>
						</Stack>
					</FormGroup>

					<FormGroup sx={{ padding: 2 }}>
						<Box maxWidth={'100%'}>
							<Stack spacing={2}>
								<SectionHeader
									title={t('page.pamGroup.edit.subtitle.targetsSelected')}
									description={t('page.pamGroup.edit.table.description.selectedTargets')}
								/>
								<MaterialReactTable
									columns={columns}
									data={selectedTargetList}
									enableStickyHeader={false}
									state={{
										rowSelection: rowSelectionSelected,
									}}
									initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
									enableRowActions
									enableRowSelection={isAllowed(
										[EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE],
										false,
									)}
									onRowSelectionChange={setRowSelectionSelected}
									getRowId={(originalRow) => originalRow.id?.toString() || ''}
									renderRowActions={({ row }) => (
										<Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
											{isAllowed(
												[EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE],
												false,
											) && (
												<Tooltip
													title={t('page.pamGroup.edit.tooltips.remove')}
													placement='left'
													enterDelay={500}
													arrow
												>
													<IconButton
														color='error'
														onClick={handleOnRemoveEntity(row.original.id)}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											)}
										</Box>
									)}
									renderToolbarInternalActions={({ table }) => (
										<Box sx={{ display: 'flex', gap: '1rem' }}>
											<MRTToggleGlobalFilterButton table={table} />
											<MRTShowHideColumnsButton table={table} />
											{isAllowed(
												[EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE],
												false,
											) && (
												<Tooltip
													title={t('page.pamGroup.edit.tooltips.removeSelected')}
													placement='top'
													enterDelay={500}
													arrow
												>
													<span>
														<Button
															color='error'
															variant='contained'
															disabled={table.getSelectedRowModel().rows.length === 0}
															onClick={handleOnRemoveEntities(table)}
															sx={{
																display: 'flex',
																alignItems: 'flex-start',
																gap: '0.5rem',
																width: 130,
															}}
														>
															<DeleteIcon />
															{t('page.pamGroup.edit.table.button.remove')}
														</Button>
													</span>
												</Tooltip>
											)}
										</Box>
									)}
									displayColumnDefOptions={{
										'mrt-row-actions': {
											header: t('page.pamGroup.edit.table.header.actions'),
											size: 80,
										},
										'mrt-row-select': {
											enableHiding: true,
											visibleInShowHideMenu: false,
										},
									}}
									muiTablePaperProps={({ table }) => ({
										style: {
											zIndex: table.getState().isFullScreen ? 1100 : undefined,
											boxShadow: 'none',
											outline: '1px solid #e0e0e0',
										},
									})}
									muiTableHeadCellProps={() => ({
										sx: {
											paddingLeft: 2,
											paddingBottom: 2,
										},
									})}
									muiTableBodyCellProps={() => ({
										sx: {
											paddingLeft: 2,
										},
									})}
									editDisplayMode='modal'
									positionActionsColumn='last'
									localization={MRTLocalization}
									layoutMode='grid-no-grow'
								/>
								<Stack spacing={2}>
									<SectionHeader
										title={t('page.pamGroup.edit.subtitle.targetsAll')}
										description={t('page.pamGroup.edit.table.description.allTargets')}
									/>
									<MaterialReactTable
										columns={columns}
										data={allTargetList}
										enableStickyHeader={false}
										enableRowActions
										enableRowSelection={(row: MRT_Row<Partial<PAMTargetDetailModel>>) => {
											const found = selectedTargetList.find(
												(target) => target.id === row.original.id,
											);

											return (
													isAllowed(
														[EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE],
														false,
													)
												) ?
													!found
												:	false;
										}}
										state={{
											isLoading: isTargetListLoading,
											showAlertBanner: targetListError !== null,
											pagination,
											rowSelection: rowSelectionAll,
											showProgressBars: isTargetListRefetching,
											columnFilters,
											globalFilter,
											sorting,
											columnVisibility,
										}}
										muiToolbarAlertBannerProps={{
											color: 'error',
											children: <>{targetListError}</>,
										}}
										initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
										rowCount={total}
										manualPagination
										manualFiltering
										manualSorting
										onSortingChange={setSorting}
										onGlobalFilterChange={setGlobalFilter}
										onColumnFiltersChange={setColumnFilters}
										onPaginationChange={setPagination}
										onColumnVisibilityChange={setColumnVisibility}
										onRowSelectionChange={setRowSelectionAll}
										getRowId={(originalRow) => originalRow.id?.toString() || ''}
										renderRowActions={({ row }) => {
											const found = selectedTargetList.find(
												(target) => target.id === row.original.id,
											);

											return found ? null : (
													<Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
														{isAllowed(
															[
																EPermission.PAM_GROUPS_CREATE,
																EPermission.PAM_GROUPS_UPDATE,
															],
															false,
														) && (
															<Tooltip
																title={t('page.pamGroup.edit.tooltips.add')}
																placement='left'
																enterDelay={500}
																arrow
															>
																<IconButton
																	color='success'
																	onClick={handleOnAddEntity(row.original.id)}
																>
																	<AddIcon />
																</IconButton>
															</Tooltip>
														)}
													</Box>
												);
										}}
										renderToolbarInternalActions={({ table }) => (
											<Box sx={{ display: 'flex', gap: '1rem' }}>
												<MRTToggleGlobalFilterButton table={table} />
												<MRTToggleFiltersButton table={table} />
												<MRTShowHideColumnsButton table={table} />
												{isAllowed(
													[EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE],
													false,
												) && (
													<Tooltip
														title={t('page.pamGroup.edit.tooltips.addSelected')}
														placement='top'
														enterDelay={500}
														arrow
													>
														<span>
															<Button
																color='success'
																variant='contained'
																disabled={table.getSelectedRowModel().rows.length === 0}
																onClick={handleOnAddEntities(table)}
																sx={{
																	display: 'flex',
																	alignItems: 'flex-start',
																	gap: '0.5rem',
																	width: 130,
																}}
															>
																<AddIcon />
																{t('page.pamGroup.edit.table.button.add')}
															</Button>
														</span>
													</Tooltip>
												)}
											</Box>
										)}
										displayColumnDefOptions={{
											'mrt-row-actions': {
												header: t('page.pamGroup.edit.table.header.actions'),
												size: 80,
											},
											'mrt-row-select': {
												enableHiding: true,
												visibleInShowHideMenu: false,
											},
										}}
										muiTablePaperProps={({ table }) => ({
											style: {
												zIndex: table.getState().isFullScreen ? 1100 : undefined,
												boxShadow: 'none',
												outline: '1px solid #e0e0e0',
											},
										})}
										muiTableHeadCellProps={() => ({
											sx: {
												paddingLeft: 2,
												paddingBottom: 2,
											},
										})}
										muiTableBodyCellProps={() => ({
											sx: {
												paddingLeft: 2,
											},
										})}
										editDisplayMode='modal'
										positionActionsColumn='last'
										localization={MRTLocalization}
										layoutMode='grid-no-grow'
									/>
								</Stack>
							</Stack>
						</Box>
					</FormGroup>

					{isAllowed([EPermission.PAM_GROUPS_CREATE, EPermission.PAM_GROUPS_UPDATE], false) && (
						<FloatingButtonSave
							type='submit'
							disabled={shouldDisableForm}
							ariaLabel={t('page.pamGroup.edit.ariaLabel.save')}
							tooltipTitle={
								id ? t('page.pamGroup.edit.tooltips.save') : t('page.pamGroup.edit.tooltips.create')
							}
						/>
					)}
				</Paper>
			}
		</Box>
	);
};
